import {Component, OnInit} from '@angular/core';
import {AppService} from '@services/app.service';

@Component({
    selector: 'app-menu-sidebar',
    templateUrl: './menu-sidebar.component.html',
    styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit {
    public user;
    public menu = MENU;

    constructor(public appService: AppService) {}

    ngOnInit() {
        this.user = this.appService.user;   
        // console.log(this.user);        
    }
}

export const MENU = [
    { name: 'Operaciones',path: ['dashboard/'], icon: 'fa-tachometer-alt',perfil: '15kwqgk3123'},
    { name: 'Empresas',path: ['dashboard/empresas'], icon: 'fa-city', perfil: '15kwqgk3123'},
    // ADMIN
    { name: 'Operaciones',path: ['dashboard/'], icon: 'fa-tachometer-alt',perfil: '2fk6i2o3143'},    
    { name: 'Solicitudes',path: ['dashboard/solicitudes'], icon: 'fa-receipt',perfil: '2fk6i2o3143'},    
    { name: 'Productos',path: ['dashboard/productos'], icon: 'fa-boxes',perfil: '2fk6i2o3143'},    
   { name: 'Usuarios',path: ['dashboard/usuarios'], icon: 'fa-users',  perfil: '2fk6i2o3143'},
    // LOGISTICA 
    { name: 'Operaciones',path: ['dashboard/'], icon: 'fa-tachometer-alt', perfil: '3af3s12f153'},
    { name: 'Clientes',path: ['dashboard/clientes'], icon: 'fa-user-friends',  perfil: '3af3s12f153'},
    // EMPRESA INSITUTO
    { name: 'Operaciones',path: ['dashboard/'], icon: 'fa-tachometer-alt', perfil: '4af4s34f354'},
    // { name: 'Main Menu', icon: 'fa-list-ul',
    //     children: [
    //         {
    //             name: 'Sub Menu',
    //             path: ['/sub-menu-1']
    //         },

    //         {
    //             name: 'Blank',
    //             path: ['/sub-menu-2']
    //         }
    //     ]
    // }
];
