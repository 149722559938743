import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '@services/app.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

import { jsPDF } from "jspdf";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import moment from 'moment';

declare var $: any;

@Component({
  selector: 'app-ver-curso',
  templateUrl: './ver-curso.component.html',
  styleUrls: ['./ver-curso.component.scss']
})
export class VerCursoComponent implements OnInit {
  public nuevoForm: FormGroup;
  public editForm: FormGroup;
  id: any;
  public user: any = null;
  public isAuthLoading = false;
  obj: any = [];
  objTipoCurso: any = [];
  objInstructor: any = [];
  objClientes: any = [];
  objCertificados: any = [];
  findInput: any;
  clientes: any = [];
  selectItem: any = [];
  public cargando = false;
  public cargandoExport = false;
  reporteExport: any;
  urlSafe: SafeResourceUrl;

  objPlantillaOrder: any = [];
  orientacionPlantilla: any;


  constructor(public router: Router, private toastr: ToastrService, private appService: AppService, private route: ActivatedRoute, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.user = this.appService.user;
    // console.log(this.user);


    if (this.id) {
      this.getCurso();
      this.getCertificados();
    } else {
      this.router.navigate(['/cursos/']);
    }

    this.nuevoForm = new FormGroup({
      id: new FormControl(null, Validators.required),
      instructor_id: new FormControl(null, Validators.required),
      instructor_nombre: new FormControl(null, Validators.nullValidator),
      licencia: new FormControl(null, Validators.nullValidator),

      tipo_curso_id: new FormControl(null, Validators.required),
      tipo_curso_nombre: new FormControl(null, Validators.nullValidator),
      categoria_nombre: new FormControl(null, Validators.nullValidator),
      tiempo: new FormControl(null, Validators.nullValidator),
      duracion: new FormControl(null, Validators.nullValidator),
      vigencia: new FormControl(null, Validators.nullValidator),

      fecha_inicio: new FormControl(null, Validators.nullValidator),
      fecha_fin: new FormControl(null, Validators.nullValidator),
      fecha_certificacion: new FormControl(null, Validators.nullValidator),
      ciudad: new FormControl(null, Validators.nullValidator),

      // fecha_inicio: new FormControl({value:"", disabled: true}),
      // fecha_fin: new FormControl({value:"", disabled: true}),
      // fecha_certificacion: new FormControl({value:"", disabled: true}),
      // ciudad: new FormControl({value:"", disabled: true}),

    });

    this.editForm = new FormGroup({
      instructor_id: new FormControl(null, Validators.required),
      instructor_nombre: new FormControl(null, Validators.nullValidator),
      licencia: new FormControl(null, Validators.nullValidator),

      tipo_curso_id: new FormControl(null, Validators.required),
      tipo_curso_nombre: new FormControl(null, Validators.nullValidator),
      categoria_nombre: new FormControl(null, Validators.nullValidator),
      tiempo: new FormControl(null, Validators.nullValidator),
      duracion: new FormControl(null, Validators.nullValidator),
      vigencia: new FormControl(null, Validators.nullValidator),

      fecha_inicio: new FormControl(null, Validators.required),
      fecha_fin: new FormControl(null, Validators.required),
      fecha_certificacion: new FormControl(null, Validators.required),
      ciudad: new FormControl(null, Validators.nullValidator),
    });

    this.getListTipoCuros();
    this.getListInstructores();

  }

  async new() {
    if (this.nuevoForm.valid) {
      this.isAuthLoading = true;
      let params = {
        nuevo: true,
        et: this.user.et,
        ut: this.user.token,
        campos: this.nuevoForm.value,
        estado: 1,
      };
      this.appService.postCurso(params)
        .subscribe((data) => {
          // console.log(data);
          if (data["logger"] == true) {
            this.nuevoForm.reset()
            // console.log(data["data"]);              
            // console.log(data['data'].id);              
            this.router.navigate(['/cursos/ver-curso/'] + data['data'].id);
            this.toastr.success(data["msg"]);
          } else {
            this.toastr.error(data["msg"]);
          }
        });
      this.isAuthLoading = false;
    } else {
      this.toastr.error('El formulario no es válido!');
    }
  }

  getCurso() {
    let params = {
      get: true,
      et: this.user.et,
      id: this.id
    };
    // console.log(params);
    this.appService.postCurso(params)
      .subscribe((data) => {
        if (data["logger"] == true) {
          this.obj = data['obj'];
        } else {
          this.toastr.error(data["msg"]);
        }
      });
  }

  getCertificados() {
    this.cargando = true;
    let params = {
      get: true,
      et: this.user.et,
      id: this.id
    };
    // console.log(params);
    this.appService.postCertificado(params)
      .subscribe((data) => {
        if (data["logger"] == true) {
          this.objCertificados = data['obj'];
          // console.log(this.objCertificados);
        } else {
          this.toastr.error(data["msg"]);
        }
        this.cargando = false;
      });
    this.cargando = false;

  }

  getListTipoCuros(){
    let params = {
        get:true,
        et: this.user.et
      };
      // console.log(params);
      this.appService.postTipoCurso(params)
    .subscribe( (data) => {
        if (data["logger"] == true){
          this.objTipoCurso = data['obj'];
        }else{
          this.toastr.error(data["msg"]);
        }
    });    
  }  

  getListInstructores() {
    let params = {
      get: true,
      et: this.user.et
    };
    // console.log(params);
    this.appService.postInstructor(params)
      .subscribe((data) => {
        if (data["logger"] == true) {
          this.objInstructor = data['obj'];
          // console.log(this.objInstructor);          
        } else {
          this.toastr.error(data["msg"]);
        }
      });
  }

  selectTipoCurso(item) {
    this.editForm.get('tipo_curso_id').setValue(item.id);
    this.editForm.get('tipo_curso_nombre').setValue(item.nombre);
    this.editForm.get('categoria_nombre').setValue(item.categoria_nombre);
    this.editForm.get('tiempo').setValue(item.tiempo);
    this.editForm.get('duracion').setValue(item.duracion);
    this.editForm.get('vigencia').setValue(item.vigencia);

    this.obj.tipo_curso_id = item.id;
    this.obj.tipo_curso_nombre = item.nombre;
    this.obj.categoria_nombre = item.categoria_nombre;
    this.obj.tiempo = item.tiempo;
    this.obj.duracion = item.duracion;
    this.obj.vigencia = item.vigencia;

    $('#tipocursoModal').modal('hide');
    $('#editCursoModal').modal('show');
  }

  selectInstructor(item) {
    this.editForm.get('instructor_id').setValue(item.id);
    this.editForm.get('instructor_nombre').setValue(item.nombre);
    this.editForm.get('licencia').setValue(item.licencia);

    this.obj.instructor_id = item.id;
    this.obj.instructor_nombre = item.nombre;
    this.obj.licencia = item.licencia;

    $('#instructorModal').modal('hide');
    $('#editCursoModal').modal('show');
  }



  async findCliente() {
    let params = {
      buscar: true,
      buscar_input: this.findInput,
      et: this.user.et,
      page: 1,
    };
    this.appService.postClientes(params)
      .subscribe((data) => {
        if (data["logger"] == true) {
          this.objClientes = data['clientes'];
          // console.log(this.clientes);              
        } else {
          this.toastr.error(data["msg"]);
        }
      });
  }

  certificarCliente(item) {
    Swal.fire({
      title: '¿Está seguro?',
      text: `Desea Certificar a ` + item.primer_nombre + ` ` + item.primer_apellido + `?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, estoy seguro!',
    }).then((result) => {
      if (result.value) {
        let params = {
          nuevo: true,
          id: this.id,
          cliente_id: item.id,
          et: this.user.et,
          token: this.user.token,
          campos: this.obj,
        }
        this.appService.postCertificado(params)
          .subscribe((data) => {
            if (data["logger"] == true) {
              this.sendPushNotificacion();
              this.getCertificados();
              this.findInput = null;
              this.objClientes = [];
              $('#clienteModal').modal('hide');
              this.toastr.success(data["msg"]);
            } else {
              this.toastr.error(data["msg"]);
            }
          });

      }
    });

  }

  eliminarCertificadoCliente(item) {
    Swal.fire({
      title: '¿Está seguro?',
      text: 'Desea Eliminar el certificado de ' + item.primer_nombre + ' ' + item.primer_apellido + '?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, estoy seguro!',
    }).then((result) => {
      if (result.value) {
        // console.log('dentro true');

        let params = {
          eliminar: true,
          id: item.id,
          et: this.user.et,
          token: this.user.token,
        }
        this.appService.postCertificado(params)
          .subscribe((data) => {
            if (data["logger"] == true) {
              this.getCertificados();
              this.toastr.success(data["msg"]);
            } else {
              this.toastr.error(data["msg"]);
            }
          });

      }
    });

  }

  cerrarCurso(item, estado) {
    let title = ''
    let text = ''
    if (estado == '1') {
      title = 'Cerrar Curso?'
      text = 'No podrá certificar a mas clientes en el!'
    }
    if (estado == '3') {
      title = 'Abrir Curso?'
      text = 'Desea abrir este curso?'
    }
    Swal.fire({
      title: title,
      text: text,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, estoy seguro!',
    }).then((result) => {
      if (result.value) {
        // console.log('dentro true');

        let params = {
          editarCierre: true,
          id: item.id,
          et: this.user.et,
          estado: estado,
          token: this.user.token,
        }
        // console.log(params);             
        this.appService.postCurso(params)
          .subscribe((data) => {
            if (data["logger"] == true) {
              this.getCurso();
              this.toastr.success(data["msg"]);
            } else {
              this.toastr.error(data["msg"]);
            }
          });

      }
    });
  }

  sincronizarCurso() {
    let title = 'Sincronizar Curso'
    let text = 'Desea sicronizar la informacion del curso'

    Swal.fire({
      title: title,
      text: text,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, estoy seguro!',
    }).then((result) => {
      if (result.value) {
        // console.log('dentro true');

        let params = {
          sincronizar: true,
          id: this.id,
          et: this.user.et,
          token: this.user.token,
        }
        console.log(params);             
        this.appService.postCertificado(params)
        .subscribe( (data) => {
            if (data["logger"] == true){
              this.getCertificados(); 
              this.toastr.success(data["msg"]);
            }else{
                this.toastr.error(data["msg"]);
            }
        });         

      }
    });
  }

  async exportCertificadoRelacion() {
    let params = {
      export: true,
      id: this.obj.id,
      et: this.user.et,
    };
    this.appService.postCertificado(params)
      .subscribe((data) => {
        // console.log(data['clientes']);          
        if (data["logger"] == true) {
          this.reporteExport = data['obj'];
          console.log(data['obj']);
          

          const fileName = 'curso_' + this.obj.fecha_inicio + '.xlsx';
          // const fileName = 'curso_' + this.obj.fecha_inicio + '.csv';
          const myHeader = ["tipo_documento", "documento", "primer_nombre", "segundo_nombre", "primer_apellido", "segundo_apellido", "genero", "pais_nacimiento", "fecha_nacimiento", "nivel_educativo", "area_trabajo", "cargo_actual", "sector", "instituto_nombre", "arl"];

          const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.reporteExport);

          // ANCHO COLUMNAS
          ws['!cols'] = [{ width: 35 }, { width: 35 }, { width: 35 }, { width: 35 }, { width: 35 }, { width: 35 }, { width: 35 }, { width: 35 }, { width: 35 }, { width: 35 }, { width: 35 }, { width: 35 }, { width: 35 }, { width: 35 }, { width: 35 }];
          // ELIMINA COLUMNA1
          // ws['!cols'][0] = { hidden: true };
          // ws['!cols'][1] = { hidden: true };
          // ws['!cols'][16] = { hidden: true };
          // ws['!cols'][17] = { hidden: true };
          // ws['!cols'][18] = { hidden: true };

          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          // CAMBIA CABECERA
          XLSX.utils.sheet_add_aoa(ws, [["TIPO DOCUMENTO", "DOCUMENTO", "PRIMER NOMBRE", "SEGUNDO NOMBRE", "PRIMER APELLIDO", "SEGUNDO APELLIDO", "GENERO", "PAIS NACIMIENTO", "FECHA NACIMIENTO", "NIVEL EDUCACION", "AREA DE TRABAJO", "CARGO ACTUAL", "SECTOR", "EMPLEADOR", "ARL"]]);
          XLSX.utils.sheet_add_json(ws, this.reporteExport, { origin: 'A2', header: myHeader, skipHeader: true });


          XLSX.utils.book_append_sheet(wb, ws, 'reporte');
          XLSX.writeFile(wb, fileName);

        } else {
          this.toastr.error(data["msg"]);
        }
      });
  }

  selectPrint(item) {
    this.selectItem = item;
    this.urlSafe = null;
    // console.log(this.selectItem);
    $('#seleccionModal').modal('show');
  }

  onCargaPdf(tipo, item) {
    let timerInterval
    Swal.fire({
      title: 'Generando Archivo!',
      html: 'Por favor espere<b></b>.',
      timer: 1000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading()
        const b = Swal.getHtmlContainer().querySelector('b')
        timerInterval = setInterval(() => {
          // b.textContent = Swal.getTimerLeft()
        }, 100)
      },
      willClose: () => {
        clearInterval(timerInterval)

// GENERARDOR DE PDF/////////////////////
        if (tipo == 'certificado') {
          this.objPlantillaOrder = item.plantilla_json;
          this.orientacionPlantilla = item.plantilla_orientacion;
        }
        if (tipo == 'carnet') {
          this.objPlantillaOrder = item.carnet_json;
          this.orientacionPlantilla = item.carnet_orientacion;
        }
        // console.log(this.selectItem);
        // console.log(this.objPlantillaOrder);
        if(!this.orientacionPlantilla){ this.orientacionPlantilla = 'l';}

        // const doc = new jsPDF(this.orientacionPlantilla, 'mm', 'letter');
        const doc = new jsPDF(this.orientacionPlantilla, 'mm', 'A4');
        const width = doc.internal.pageSize.getWidth()
        const options = { background: 'white', scale: 3, };
    
        this.objPlantillaOrder.forEach(item => {
          // console.log(item.img_url);
    
          if (item.modulo == 'img') {
            let img = item.img_url;
            let newImg = img
              .replace("{certificado}", this.selectItem.plantilla_url)
              .replace("{carnet}", this.selectItem.carnet_url)
              .replace("{instructor_firma}", this.selectItem.instructor_firma_url)
              .replace("{representante_firma}", this.selectItem.representante_firma_url);
            // console.log(newImg);
            if (newImg !== 'null') {
              doc.addImage(newImg, "JPG", item.img_x, item.img_y, item.img_w, item.img_h);
            }
          }
    
          if (item.modulo == 'text') {
            let text = item.text_data;
            // const reg = /\d.*\d/;
            let nombre_cliente = "";
            if(this.selectItem.primer_nombre){ nombre_cliente = this.selectItem.primer_nombre }
            if(this.selectItem.segundo_nombre){ nombre_cliente = nombre_cliente+' '+this.selectItem.segundo_nombre }
            if(this.selectItem.primer_apellido){ nombre_cliente = nombre_cliente+' '+this.selectItem.primer_apellido }
            if(this.selectItem.segundo_apellido){ nombre_cliente = nombre_cliente+' '+this.selectItem.segundo_apellido }
    
    
            let newtext = text
              // .replace("{nombre}", this.selectItem.primer_nombre.toUpperCase() + ' ' + this.selectItem.segundo_nombre.toUpperCase()+ ' ' + this.selectItem.primer_apellido.toUpperCase()+ ' ' + this.selectItem.segundo_apellido.toUpperCase())
              .replace("{a}", "á")
              .replace("{e}", "é")
              .replace("{i}", "í")
              .replaceAll("{o}", "ó")
              .replace("{u}", 'ú')
              .replace("{nombre}", nombre_cliente.toUpperCase())
              .replace("{docu}", this.selectItem.tipo_documento.toUpperCase() + ' ' + this.selectItem.documento.toUpperCase())
              .replace("{curso}", this.selectItem.tipo_curso_nombre.toUpperCase())
              .replace("{categoria}", this.selectItem.categoria_nombre.toUpperCase())
              .replace("{ciudad}", this.selectItem.ciudad)
              .replace("{duracion}", this.selectItem.tiempo + ' ' + this.selectItem.duracion)
              .replace("{empresa}", this.selectItem.instituto_nombre.toUpperCase())
              .replace("{empresa_nit}", this.selectItem.instituto_nit)
              .replace("{empresa_representante}", this.selectItem.instituto_representante)
              .replace("{arl}", this.selectItem.arl.toUpperCase())
              .replace("{sector}", this.selectItem.sector)
              .replace("{fecha_inicio}", moment(this.selectItem.fecha_inicio).locale('es').format('DD [de] MMMM'))
              .replace("{fecha_fin}", moment(this.selectItem.fecha_fin).locale('es').format('DD [de] MMMM [de] YYYY'))
              .replace("{fecha_certificacion}", moment(this.selectItem.fecha_certificacion).locale('es').format('LL'))
              .replace("{fecha_vencimiento}", moment(this.selectItem.fecha_vencimiento).locale('es').format('LL'))
              .replace("{instructor}", this.selectItem.instructor_nombre.toUpperCase())
              .replace("{instructor_licencia}", this.selectItem.instructor_licencia)
              .replace("{representante}", this.selectItem.representante_nombre)
              .replace("{representante_docu}", this.selectItem.representante_documento)
              .replace("{id}", this.selectItem.referencia + '' + this.selectItem.certificado);
    
            if (item.text_color) { doc.setTextColor(item.text_color); }
            if (item.text_size) { doc.setFontSize(item.text_size); }
            if (item.text_weight) { doc.setFont(undefined, item.text_weight); }
            if (item.text_align == 'center') {
              doc.text(newtext, width / 2 + +item.text_x, item.text_y, null, item.text_align);
            } else {
              doc.text(newtext, item.text_x, item.text_y, null, item.text_align);
            }
          }
    
          if (item.modulo == 'line') {
            doc.line(item.line_x1, item.line_y1, item.line_x2, item.line_y2);
          }
        });
        var out = doc.output();
        var url = 'data:application/pdf;base64,' + btoa(out);
        // this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        this.cargandoExport = false;
        return doc.save(this.selectItem.categoria_nombre + '-' + this.selectItem.instituto_nombre+'-'+this.selectItem.primer_nombre + '-' + this.selectItem.primer_apellido + '.pdf');

// FIN GENERARDOR DE PDF/////////////////////

      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log('temporizador cerrado')
      }
    })
        


  }

  selectEditCurso() {
    this.editForm.get('instructor_id').setValue(this.obj.instructor_id);
    this.editForm.get('instructor_nombre').setValue(this.obj.instructor_nombre);
    this.editForm.get('licencia').setValue(this.obj.licencia);
    this.editForm.get('tipo_curso_id').setValue(this.obj.tipo_curso_id);
    this.editForm.get('tipo_curso_nombre').setValue(this.obj.tipo_curso_nombre);
    this.editForm.get('categoria_nombre').setValue(this.obj.categoria_nombre);
    this.editForm.get('tiempo').setValue(this.obj.tiempo);
    this.editForm.get('duracion').setValue(this.obj.duracion);
    this.editForm.get('vigencia').setValue(this.obj.vigencia);
    this.editForm.get('fecha_inicio').setValue(this.obj.fecha_inicio);
    this.editForm.get('fecha_fin').setValue(this.obj.fecha_fin);
    this.editForm.get('fecha_certificacion').setValue(this.obj.fecha_certificacion);
    this.editForm.get('ciudad').setValue(this.obj.ciudad);

    $('#editCursoModal').modal('show');
  }

  async edit() {
    if (this.editForm.valid) {
      this.isAuthLoading = true;
      let params = {
        editar: true,
        id: this.id,
        et: this.user.et,
        ut: this.user.token,
        campos: this.editForm.value,
        estado: 1,
      };
      this.appService.postCurso(params)
        .subscribe((data) => {
          // console.log(data);
          if (data["logger"] == true) {
            this.editForm.reset()
            $('#editCursoModal').modal('hide');
            this.getCurso();
            this.toastr.success(data["msg"]);
          } else {
            this.toastr.error(data["msg"]);
          }
        });
      this.isAuthLoading = false;
    } else {
      this.toastr.error('El formulario no es válido!');
    }
  }


  sendPushNotificacion() {
    this.appService.getPush('aprobar' + '-' + this.user.perfil + '-' + this.user.ecu + '-' + this.id + '-' + this.user.eid)
      .subscribe((data) => {
        // console.log(data);
      },
        error => console.log(error),
      );
  }



}
