
<div class="row">
  <div class="col-12 mt-5">
    <div class="card m-auto" style="width: 48rem;">
      <div class="card-body">
        <h3 class="text-center">Proceso de pago</h3>
        <h2 class="text-center text-bold">Transacción {{statusText}}</h2>
        <h5 class="text-center" *ngIf="status =='APPROVED'">Estamos trabajando en el proceso de radicación de su solicitud, uno de nuestro asesores proximamente se pondrá en contacto.</h5>
        <h5 class="text-center" *ngIf="status =='DECLINED'">Su solicitud ha sido rechazada.</h5>
        <!-- <div class="text-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div> -->
        <h6 class="text-center mt-3"><a href="/cotizador" target="_parent">Ir al menu Principal</a></h6>

      </div>
    </div>
  </div>
</div>