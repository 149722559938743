<section *ngIf="obj.token" class="content-header" [ngStyle]="{'background': obj.color_principal}">
  <div class="row">
    <div class="col-md-4 offset-md-4  text-center">
      <div *ngIf="obj.logo_url" class="mb-2">
        <img class="mt-3 img-fluid" width="200px" [src]="obj.logo_url" />
      </div>
      <div class="input-group input-group-lg mb-3">
        <input type="text" class="form-control" [(ngModel)]="findInput" placeholder="Documento de indentificación">
        <button class="btn  btn-secondary" type="button"  id="button-addon2" (click)="findGuia()"><i class="fa fa-search"></i></button>
      </div>
    </div>
  </div>
  </section>

  <section  *ngIf="obj.token && objCliente.cliente_id && !msg" class="container">
    <div class="row">
      <div class="col-md-5  mt-5">
        <h3 class="text-left">Informacion Personal</h3>
        <div class="card">
          <div class="card-body">
            <div class="row mt-2">
              <div class="col-12">
                <h3>
                  <div><small>Nombre</small></div>
                  {{objCliente.primer_nombre | titlecase}} {{objCliente.segundo_nombre | titlecase}} {{objCliente.primer_apellido | titlecase}} {{objCliente.segundo_apellido | titlecase}}
                </h3>
              </div>
              <div class="col-12">
                <h3>
                  <div><small>Identificacion</small></div>
                  {{objCliente.tipo_documento}} {{objCliente.documento}}
                </h3>
              </div>
            </div>

          </div>
        </div>
        <small class="text-muted">Si tiene una alguna inquietud sobre la expedicion de los certificados pongase en contacto con nosotros <b>{{obj.telefono}}</b>.</small>
      </div>
      <div class="col-md-7">
        <h3 class="text-center mt-5">Cursos generados</h3>
        <div class="list-group">
          <div *ngFor="let item of objCursos" class="list-group-item list-group-item-action" aria-current="true">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">{{item.tipo_curso_nombre | titlecase}}</h5>
              <small><i class="fa fa-certificate" style="color:#ffc107;font-size: 2em;"></i></small>
            </div>
            <p class="mb-1"><span class="text-muted">ID: </span> {{item.referencia}} {{item.certificado}}</p>
            <p class="mb-1" *ngIf="item.categoria_nombre"><span class="text-muted">Categoria: </span> {{item.categoria_nombre | titlecase}}</p>
            <p class="mb-1"><span class="text-muted">Instructor: </span> {{item.instructor_nombre | titlecase}}</p>
            <div class="d-flex w-100 justify-content-between">
              <small><span class="text-muted">Duracion: </span> {{item.tiempo}} {{item.duracion | titlecase}}</small>
              <small><span class="text-muted">Fecha Certificacion: </span> {{item.fecha_certificacion}}</small>
              <small><span class="text-muted">Fecha Vencimiento: </span> {{item.fecha_vencimiento}}</small>
            </div>
          </div>
        </div>
        <h4 class="text-center mt-4 text-muted" *ngIf="!objCursos.length">
          <div><i class="fa fa-exclamation-circle" style="font-size: 3em;"></i></div>
          No hay cursos generados
        </h4>
      </div>
    </div>
  </section>
  
  <section class="pt-5" *ngIf="msg && !objCliente.cliente_id">
    <h1 class="text-center">
      <i class="fa fa-exclamation-triangle"></i>
      {{msg}}
    </h1>
  </section>

  <section class="pt-5" *ngIf="!obj.token">
    <h1 class="text-center">
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    </h1>
  </section>