import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-descargar',
  templateUrl: './descargar.component.html',
  styleUrls: ['./descargar.component.scss']
})
export class DescargarComponent implements OnInit {
  tipo: any;
  token: any;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.tipo = this.route.snapshot.paramMap.get('tipo');
    this.token = this.route.snapshot.paramMap.get('token');
    console.log(this.tipo);
    console.log(this.token);
    
  }

}
