<script src="assets/png_support.js"></script>

<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <div class="row">
          <div class="col-10">
            <h1>
              <div style="float: left;">Tipo Curso </div>
              <div class="btn-group btn-sm mb-3" role="group">
                <button class="btn btn-sm btn-primary" type="button" data-bs-toggle="modal" data-bs-toggle="modal"
                  data-bs-target="#nuevo"><i class="fa fa-plus"></i></button>
                <button type="button" (click)="getList()" *ngIf="!cargando" class="btn btn-primary"><i
                    class="fa fa-sync"></i> </button>
                <button type="button" *ngIf="cargando" class="btn btn-primary"><span
                    class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> </button>
                <button class="btn btn-sm btn-secondary" type="button" data-bs-toggle="modal" data-bs-toggle="modal"
                  data-bs-target="#categorias"><i class="fa fa-project-diagram"></i> Categorias</button>
                <button class="btn btn-sm btn-secondary" type="button" data-bs-toggle="modal" data-bs-toggle="modal"
                  data-bs-target="#plantillas"><i class="fa fa-th-list"></i> Plantillas</button>
              </div>
            </h1>
          </div>
          <div class="col-2">
            <div class="input-group mb-0">
            </div>
          </div>
        </div>

      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">

          <li class="breadcrumb-item"><a href="#">Inicio</a></li>
          <li class="breadcrumb-item active">Tipo Curso</li>
        </ol>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content m-2">
  <div class="card">
    <div class="card-body table-responsive p-0">
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">Nombre</th>
            <th scope="col">Duración</th>
            <th scope="col">Vigencia</th>
            <th scope="col">Categoria</th>
            <th scope="col">Valor</th>
            <th scope="col">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of obj.items">
            <td style="cursor: pointer;" (click)="ver(item)">{{item.nombre | titlecase}}</td>
            <td>{{item.tiempo}} {{item.duracion | titlecase}}</td>
            <td>{{item.vigencia}} <span *ngIf="item.vigencia">Meses</span></td>
            <td>{{item.categoria_nombre | titlecase}}</td>
            <td>${{item.valor | decimal}}</td>
            <td>

              <div class="dropdown">
                <button class="btn btn-block btn-light dropdown-toggle" type="button" id="dropdownMenuButton1"
                  data-bs-toggle="dropdown" aria-expanded="false"></button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li><span class="dropdown-item" style="cursor: pointer;" (click)="ver(item)">Ver Tipo Curso</span>
                  </li>
                  <li *ngIf="user.perfil == '2fk6i2o3143'"><span class="dropdown-item text-danger"
                      style="cursor: pointer;" (click)="eliminarUsuario(item)">Eliminar</span></li>
                </ul>
              </div>

            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- paginador -->
  <div class="card-footer clearfix">
    <ul class="pagination pagination-sm m-0 float-right">
      <li class="page-item" *ngIf="obj.current > 1">
        <button type="button" class="page-link" (click)="changePage(obj.current - 1)">
          <span>Atras</span>
        </button>
      </li>

      <li class="page-item" *ngFor="let page of pagesNumber" [ngClass]="{'active': obj.current == page}">
        <button type="button" class="page-link" (click)="changePage(page)">
          {{ page }}
        </button>
      </li>

      <li class="page-item" *ngIf="obj.current < obj.total_page">
        <button type="button" class="page-link" (click)="changePage(obj.next)">
          <span>Siguiente</span>
        </button>
      </li>
    </ul>
    <div class="pagination" style="height: auto !important; margin: 10px;margin-top:0px;">
      <p>Total registros encontrados: <b>{{obj.size}}</b></p>
    </div>
  </div>
  <!-- Fin paginador -->

</section>




<!-- Modal Ver  -->
<div class="modal fade" id="ver" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          <h3>Tipo Curso</h3>
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body mt-0">
        <form [formGroup]="editForm" (ngSubmit)="edit()">
          <div class="row">
            <div class="col-8 mt-0">
              <div class="form-floating">
                <input formControlName="nombre" id="floatingNombre" placeholder="Nombre" type="text"
                  class="form-control" />
                <label for="floatingNombre">Nombre <span class="text-danger">*</span></label>
              </div>

              <div class="row">
                <div class="col-4">
                  <div class="form-floating mt-3">
                    <input formControlName="vigencia" placeholder="Vigencia" type="number" class="form-control" />
                    <label for="">Vigencia Meses</label>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-floating mt-3">
                    <input formControlName="tiempo" placeholder="Tiempo" type="number" class="form-control" />
                    <label for="">Tiempo {{nuevoForm.value.duracion}} <span class="text-danger">*</span></label>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-floating mt-3">
                    <select formControlName="duracion" class="form-select" placeholder="Duracion">
                      <option value="horas">Horas</option>
                      <option value="días">Días</option>
                      <option value="semanas">Semanas</option>
                      <option value="meses">Meses</option>
                    </select>
                    <label for="">Duracion <span class="text-danger">*</span></label>
                  </div>
                </div>
              </div>


              <div class="form-floating mt-3">
                <select formControlName="categoria_id" class="form-select" placeholder="Categoria curso">
                  <option *ngFor="let item of objCate" [value]="item.id">{{item.nombre}}</option>
                </select>
                <label for="">Categoria curso</label>
              </div>

              <div class="row">
                <div class="col-8">
                  <div class="form-floating mt-3">
                    <input formControlName="referencia" placeholder="Referencia" type="text" class="form-control" />
                    <label for="">Referencia <small class="text-muted">contador de certificados</small></label>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-floating mt-3">
                    <input formControlName="consecutivo" placeholder="Consecutivo" type="number" class="form-control" />
                    <label for="">Consecutivo</label>
                  </div>
                </div>
              </div>

              <div class="form-floating mt-3">
                <input formControlName="descripcion" placeholder="Descripcion" type="text" class="form-control" />
                <label for="">Descripcion</label>
              </div>

              <div class="form-floating mt-3  mb-3">
                <input formControlName="valor" placeholder="Valor" currencyMask
                  [options]="{ prefix: '$ ', thousands: '.', decimal: ',', precision: 0}" style="font-size: 2.2em"
                  class="form-control" />
                <label for="">Valor <span class="text-danger">*</span></label>
              </div>

              <app-button [type]="'submit'" [block]="true" [loading]="isAuthLoading">Actualizar</app-button>
            </div>
            <div class="col-4 mt-0 text-center">
              <div class="mt-0">
                <small class="text-muted">Certificado</small>
                <div *ngIf="loadingImg" class="spinner-border text-secondary"
                  style="width: 3rem; height: 3rem;position: absolute;left: 40%;top: 10%;" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <img *ngIf="!editForm.value.plantilla_url" src="assets/img/plantilla-default.png"
                  (click)="fileEditaPlantilla.click()" class="img-fluid img-thumbnail" style="cursor: pointer;" />
                <img *ngIf="editForm.value.plantilla_url" [src]="editForm.value.plantilla_url"
                  (click)="fileEditaPlantilla.click()" class="img-fluid img-thumbnail" style="cursor: pointer;" />
                <input #fileEditaPlantilla type="file" accept='image/*'
                  (change)="uploadImg(fileEditaPlantilla.files,'editar','plantilla')" style="display: none" />
              </div>

              <div class="form-floating mt-3">
                <select formControlName="plantilla_json_id" class="form-select" placeholder="Estructura Certificado">
                  <option *ngFor="let item of objPlantilla.items" [value]="item.id">{{item.nombre}}</option>
                </select>
                <label for="">Estructura Certificado</label>
              </div>

              <div class="mt-3">
                <small class="text-muted">Carnet</small>
                <div *ngIf="loadingImg" class="spinner-border text-secondary"
                  style="width: 3rem; height: 3rem;position: absolute;left: 40%;top: 10%;" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <img *ngIf="!editForm.value.carnet_url" src="assets/img/carnet-default.png"
                  (click)="fileEditaCarnet.click()" class="img-fluid img-thumbnail" style="cursor: pointer;" />
                <img *ngIf="editForm.value.carnet_url" [src]="editForm.value.carnet_url"
                  (click)="fileEditaCarnet.click()" class="img-fluid img-thumbnail" style="cursor: pointer;" />
                <input #fileEditaCarnet type="file" accept='image/*'
                  (change)="uploadImg(fileEditaCarnet.files,'editar','carnet')" style="display: none" />
                <small class="text-muted">Cargar carnet</small>
              </div>

              <div class="form-floating mt-3">
                <select formControlName="carnet_json_id" class="form-select" placeholder="Estructura Carnet">
                  <option *ngFor="let item of objPlantilla.items" [value]="item.id">{{item.nombre}}</option>
                </select>
                <label for="">Estructura Carnet</label>
              </div>

            </div>
          </div>

        </form>
      </div>
    </div>
  </div>
</div>


<!-- Modal Nueva  -->
<div class="modal fade" id="nuevo" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          <h3>Nuevo Tipo Curso</h3>
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body mt-0">
        <form class="row" [formGroup]="nuevoForm" (ngSubmit)="new()">
          <div class="row">
            <div class="col-8 mt-0">
              <div class="form-floating">
                <input formControlName="nombre" id="floatingNombre" placeholder="Nombre" type="text"
                  class="form-control" />
                <label for="floatingNombre">Nombre <span class="text-danger">*</span></label>
              </div>


              <div class="row">
                <div class="col-4">
                  <div class="form-floating mt-3">
                    <input formControlName="vigencia" placeholder="Vigencia" type="number" class="form-control" />
                    <label for="">Vigencia Mes</label>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-floating mt-3">
                    <input formControlName="tiempo" placeholder="Tiempo" type="number" class="form-control" />
                    <label for="">Tiempo {{nuevoForm.value.duracion}} <span class="text-danger">*</span></label>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-floating mt-3">
                    <select formControlName="duracion" class="form-select" placeholder="Duracion">
                      <option value="horas">Horas</option>
                      <option value="días">Días</option>
                      <option value="semanas">Semanas</option>
                      <option value="meses">Meses</option>
                    </select>
                    <label for="">Duracion <span class="text-danger">*</span></label>
                  </div>
                </div>
              </div>

              <div class="form-floating mt-3">
                <select formControlName="categoria_id" class="form-select" placeholder="Categoria curso">
                  <option *ngFor="let item of objCate" [value]="item.id">{{item.nombre}}</option>
                </select>
                <label for="">Categoria curso</label>
              </div>

              <div class="row">
                <div class="col-8">
                  <div class="form-floating mt-3">
                    <input formControlName="referencia" placeholder="Referencia" type="text" class="form-control" />
                    <label for="">Referencia <small class="text-muted">contador de certificados</small></label>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-floating mt-3">
                    <input formControlName="consecutivo" placeholder="Consecutivo" type="number" class="form-control" />
                    <label for="">Consecutivo</label>
                  </div>
                </div>
              </div>

              <div class="form-floating mt-3">
                <input formControlName="descripcion" placeholder="Descripcion" type="text" class="form-control" />
                <label for="">Descripcion</label>
              </div>

              <div class="form-floating mt-3  mb-3">
                <input formControlName="valor" placeholder="Valor" type="text" currencyMask
                  [options]="{ prefix: '$ ', thousands: '.', decimal: ',', precision: 0}" class="form-control"
                  style="font-size: 2em;" />
                <label for="">Valor <span class="text-danger">*</span></label>
              </div>

              <app-button [type]="'submit'" [block]="true" [loading]="isAuthLoading">Actualizar</app-button>
            </div>
            <div class="col-4 mt-0 text-center">
              <div class="mt-0">
                <small class="text-muted">Certificado</small>
                <div *ngIf="loadingImg" class="spinner-border text-secondary"
                  style="width: 3rem; height: 3rem;position: absolute;left: 40%;top: 10%;" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <img *ngIf="!nuevoForm.value.plantilla_url" src="assets/img/plantilla-default.png"
                  (click)="fileNuevoPlantilla.click()" class="img-fluid img-thumbnail" style="cursor: pointer;" />
                <img *ngIf="nuevoForm.value.plantilla_url" [src]="nuevoForm.value.plantilla_url"
                  (click)="fileNuevoPlantilla.click()" class="img-fluid img-thumbnail" style="cursor: pointer;" />
                <input #fileNuevoPlantilla type="file" accept='image/*'
                  (change)="uploadImg(fileNuevoPlantilla.files,'nuevo','plantilla')" style="display: none" />
              </div>

              <div class="form-floating mt-3">
                <select formControlName="plantilla_json_id" class="form-select" placeholder="Estructura Certificado">
                  <option *ngFor="let item of objPlantilla.items" [value]="item.id">{{item.nombre}}</option>
                </select>
                <label for="">Estructura Certificado</label>
              </div>

              <div class="mt-3">
                <small class="text-muted">Carnet</small>
                <div *ngIf="loadingImg" class="spinner-border text-secondary"
                  style="width: 3rem; height: 3rem;position: absolute;left: 40%;top: 10%;" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <img *ngIf="!nuevoForm.value.carnet_url" src="assets/img/carnet-default.png"
                  (click)="fileNuevoCarnet.click()" class="img-fluid img-thumbnail" style="cursor: pointer;" />
                <img *ngIf="nuevoForm.value.carnet_url" [src]="nuevoForm.value.carnet_url"
                  (click)="fileNuevoCarnet.click()" class="img-fluid img-thumbnail" style="cursor: pointer;" />
                <input #fileNuevoCarnet type="file" accept='image/*'
                  (change)="uploadImg(fileNuevoCarnet.files,'nuevo','carnet')" style="display: none" />
                <small class="text-muted">Cargar Plantilla</small>
              </div>

              <div class="form-floating mt-3">
                <select formControlName="carnet_json_id" class="form-select" placeholder="Estructura Carnet">
                  <option *ngFor="let item of objPlantilla.items" [value]="item.id">{{item.nombre}}</option>
                </select>
                <label for="">Estructura Carnet</label>
              </div>

            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>



<!-- Modal Categorias  -->
<div class="modal fade" id="categorias" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          <h3>Categorias

            <div class="btn-group" role="group" aria-label="Basic example">
              <button class="btn btn-sm btn-primary" type="button" (click)="showNewCategoria()"><i
                  class="fa fa-plus"></i></button>
              <button class="btn btn-sm btn-secondary" type="button" (click)="getListCate()" *ngIf="!cargando"><i
                  class="fa fa-sync"></i>
              </button>
              <button class="btn btn-sm btn-secondary" type="button" *ngIf="cargando"><span
                  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> </button>
            </div>

          </h3>
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body mt-0">
        <div id="categoriaNuevoForm" style="display: none;">
          <form class="row" [formGroup]="categoriaNuevoForm" (ngSubmit)="newCategoria()">
            <div class="input-group mb-3">
              <input formControlName="nombre" placeholder="Nombre" type="text" class="form-control" />
              <button class="btn btn-primary" type="submit" id="inputGroupFileAddon04">Crear</button>
            </div>
          </form>
        </div>

        <div id="categoriaEditForm" style="display: none;">
          <form class="row" [formGroup]="categoriaEditForm" (ngSubmit)="editCategoria()">
            <div class="input-group mb-3">
              <input formControlName="nombre" placeholder="Nombre" type="text" class="form-control" />
              <button class="btn btn-primary" type="submit" id="inputGroupFileAddon04">Editar</button>
            </div>
          </form>
        </div>
        <div class="card">
          <div class="card-body table-responsive p-0">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Nombre</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of objCate">
                  <td>{{item.nombre | titlecase}}</td>
                  <td class="text-right">
                    <div class="btn-group" role="group" aria-label="Basic example">
                      <button type="button" class="btn btn-sm btn-secondary"
                        (click)="showEditCategoria(item)">Editar</button>
                      <button type="button" class="btn btn-sm btn-danger"
                        (click)="eliminarUsuario(item)">Eliminar</button>
                    </div>

                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<!-- Modal Plantillas List  -->
<div class="modal fade" id="plantillas" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          <h3>Plantillas
            <div class="btn-group" role="group">
              <button class="btn btn-sm btn-primary" type="button" (click)="plantillaEditar =false"
                data-bs-toggle="modal" data-bs-toggle="modal" data-bs-target="#nuevaPlantilla"><i
                  class="fa fa-plus"></i></button>
              <button type="button" (click)="getListPlantilla()" *ngIf="!cargando" class="btn btn-sm btn-secondary"><i
                  class="fa fa-sync"></i> </button>
              <button type="button" *ngIf="cargando" class="btn btn-sm btn-secondary"><span
                  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> </button>
            </div>
          </h3>
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body mt-0">
        <div class="card">
          <div class="card-body table-responsive p-0">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Nombre</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of objPlantilla.items">
                  <td style="cursor: pointer;" (click)="showEditPlantilla(item)">{{item.nombre | titlecase}}</td>
                  <td class="text-right">
                    <div class="btn-group" role="group" aria-label="Basic example">
                      <button type="button" class="btn btn-sm btn-secondary"
                        (click)="showEditPlantilla(item)">Editar</button>
                      <button *ngIf="user.perfil == '2fk6i2o3143'" type="button" class="btn btn-sm btn-danger"
                        (click)="eliminarPlantilla(item)">Eliminar</button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Modal Plantillas   -->
<div class="modal fade" id="nuevaPlantilla" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <!-- <div class="modal fade" id="nuevaPlantilla" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"> -->
  <div class="modal-dialog modal-fullscreen">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          <h3 *ngIf="!plantillaEditar">Nueva Plantilla </h3>
          <h3 *ngIf="plantillaEditar">Actualizar Plantilla <button type="button" [disabled]="!nombrePlantilla"
              class="btn btn-sm btn-outline-secondary ml-2" (click)="onCargaPdf()"><i class="fa fa-sync"></i></button>
          </h3>

        </h5>
        <button type="button" class="btn-close" data-bs-toggle="modal" data-bs-toggle="modal"
          data-bs-target="#plantillas" aria-label="Close"></button>
      </div>
      <div class="modal-body mt-0">
        <div class="row">
          <div class="col-6">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-8">
                    <div class="form-floating mb-3">
                      <input [(ngModel)]="nombrePlantilla" placeholder="Nombre Plantilla" type="text"
                        class="form-control" />
                      <label>Nombre Plantilla</label>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-floating mb-3">
                      <select [(ngModel)]="orientacionPlantilla" class="form-select" placeholder="Orientación">
                        <option value="l">Horizontal</option>
                        <option value="p">Vertical</option>
                      </select>
                      <label for="">Orientación</label>
                    </div>

                  </div>
                  <div class="col-1 p-0">
                    <button type="button" class="btn btn-lg btn-block btn-secondary" (click)="newPlantilla()"><i
                        class="fa fa-save"></i></button>
                  </div>
                </div>
                <button type="button" [disabled]="!nombrePlantilla" class="btn btn-outline-primary ml-1"
                  (click)="elementNuevo('img')"><i class="fa fa-image"></i> IMG</button>
                <button type="button" [disabled]="!nombrePlantilla" class="btn btn-outline-primary ml-1"
                  (click)="elementNuevo('text')"><i class="fa fa-font"></i> TEXT</button>
                <button type="button" [disabled]="!nombrePlantilla" class="btn btn-outline-primary ml-1"
                  (click)="elementNuevo('line')"><i class="fa fa-grip-lines"></i> Line</button>
                <button type="button" [disabled]="!nombrePlantilla" class="btn btn-outline-secondary ml-2"
                  (click)="onCargaPdf()"><i class="fa fa-sync"></i></button>
              </div>
            </div>
            <div>
              <form class="" [formGroup]="plantillaNuevaForm" (ngSubmit)="addElement()">
                <!-- ELEMENTO IMAGEN -->
                <div class="card" *ngIf="nuevoElement =='img'">
                  <div class="card-body row">
                    <div class="col-12 text-right"><i class="fa fa-image" style="font-size: 1.5em;"></i></div>
                    <div class="col-12">
                      <div class="form-floating mt-3">
                        <input formControlName="img_url" placeholder="URL" type="text" class="form-control" />
                        <label>URL</label>
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="form-floating mt-3">
                        <select formControlName="img_tipo" class="form-select" placeholder="Tipo">
                          <option value="JPG">JPG</option>
                          <option value="PNG">PNG</option>
                        </select>
                        <label for="">Tipo</label>
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="form-floating mt-3">
                        <input formControlName="img_x" placeholder="X" type="number" class="form-control" />
                        <label>X</label>
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="form-floating mt-3">
                        <input formControlName="img_y" placeholder="Y" type="number" class="form-control" />
                        <label>Y</label>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="form-floating mt-3">
                        <input formControlName="img_w" placeholder="Ancho" type="number" class="form-control" />
                        <label>Ancho</label>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="form-floating mt-3">
                        <input formControlName="img_h" placeholder="Alto" type="number" class="form-control" />
                        <label>Alto</label>
                      </div>
                    </div>
                    <div class="col-12 mt-3">
                      <button class="btn btn-sm btn-block btn-primary" type="submit"><i class="fa fa-plus"></i></button>
                    </div>
                  </div>
                </div>

                <!-- ELEMENTO TEXTO -->
                <div class="card" *ngIf="nuevoElement =='text'">
                  <div class="card-body row">
                    <div class="col-12 text-right"><i class="fa fa-font" style="font-size: 1.5em;"></i></div>
                    <div class="col-12">
                      <div class="form-floating mt-3">
                        <input formControlName="text_data" placeholder="data" type="text" class="form-control" />
                        <label>Texto</label>
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="form-floating mt-3">
                        <input formControlName="text_x" placeholder="X" type="number" class="form-control" />
                        <label>X</label>
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="form-floating mt-3">
                        <input formControlName="text_y" placeholder="Y" type="number" class="form-control" />
                        <label>Y</label>
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="form-floating mt-3">
                        <select formControlName="text_align" class="form-select" placeholder="Align">
                          <option value="center">Center</option>
                          <option value="left">Left</option>
                          <option value="right">Right</option>
                        </select>
                        <label for="">Align</label>
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="form-floating mt-3">
                        <select formControlName="text_color" class="form-select" placeholder="Color">
                          <option value="000">Negro</option>
                          <option value="FF0000">Rojo</option>
                        </select>
                        <label for="">Color</label>
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="form-floating mt-3">
                        <input formControlName="text_size" placeholder="Tamaño" type="number" class="form-control" />
                        <label>Tamaño</label>
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="form-floating mt-3">
                        <select formControlName="text_weight" class="form-select" placeholder="Grosor">
                          <option value="normal">Normal</option>
                          <option value="bold">Bold</option>
                        </select>
                        <label for="">Grosor</label>
                      </div>
                    </div>
                    <div class="col-12 mt-3">
                      <button class="btn btn-sm btn-block btn-primary" type="submit"><i class="fa fa-plus"></i></button>
                    </div>
                  </div>
                </div>

                <!-- ELEMENTO LINE -->
                <div class="card" *ngIf="nuevoElement =='line'">
                  <div class="card-body row">
                    <div class="col-12 text-right"><i class="fa fa-grip-lines" style="font-size: 1.5em;"></i></div>
                    <div class="col-3">
                      <div class="form-floating mt-3">
                        <input formControlName="line_x1" placeholder="X1" type="number" class="form-control" />
                        <label>X1</label>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="form-floating mt-3">
                        <input formControlName="line_y1" placeholder="Y2" type="number" class="form-control" />
                        <label>Y1</label>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="form-floating mt-3">
                        <input formControlName="line_x2" placeholder="X2" type="number" class="form-control" />
                        <label>X2</label>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="form-floating mt-3">
                        <input formControlName="line_y2" placeholder="Y2" type="number" class="form-control" />
                        <label>Y2</label>
                      </div>
                    </div>
                    <div class="col-12 mt-3">
                      <button class="btn btn-sm btn-block btn-primary" type="submit"><i class="fa fa-plus"></i></button>
                    </div>
                  </div>
                </div>

              </form>
            </div>

            <!-- <code>{{objPlantillaOrder | json}}</code> -->
            <span *ngIf="objPlantillaOrder.length >= 1">
              <h3>Modulos plantilla</h3>
            </span>
            <div class="" *ngFor="let item of objPlantillaOrder; let i = index">
              <div class="">
                <!-- ELEMENTO IMAGEN -->
                <div class="card" *ngIf="item.modulo =='img'">
                  <div class="card-body row">
                    <div class="col-10">
                      <div class="form-floating mt-3">
                        <input [(ngModel)]="item.img_url" placeholder="URL" type="text" class="form-control" />
                        <label>URL</label>
                      </div>
                    </div>
                    <div class="col-2 mt-3">
                      <div class="d-flex w-100 justify-content-between" aria-label="Basic example">
                        <div><i class="fa fa-image" style="font-size: 1.5em;"></i></div>
                        <button class="btn btn-sm btn-danger" type="button" (click)="deleteElement(i)"><i
                            class="fa fa-times-circle"></i></button>
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="form-floating mt-3">
                        <select [(ngModel)]="item.img_tipo" class="form-select" placeholder="Tipo">
                          <option value="JPG">JPG</option>
                          <option value="PNG">PNG</option>
                        </select>
                        <label for="">Tipo</label>
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="form-floating mt-3">
                        <input [(ngModel)]="item.img_x" placeholder="X" type="text" class="form-control" />
                        <label>X</label>
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="form-floating mt-3">
                        <input [(ngModel)]="item.img_y" placeholder="Y" type="text" class="form-control" />
                        <label>Y</label>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="form-floating mt-3">
                        <input [(ngModel)]="item.img_w" placeholder="Ancho" type="text" class="form-control" />
                        <label>Ancho</label>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="form-floating mt-3">
                        <input [(ngModel)]="item.img_h" placeholder="Alto" type="text" class="form-control" />
                        <label>Alto</label>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- ELEMENTO TEXTO -->
                <div class="card" *ngIf="item.modulo =='text'">
                  <div class="card-body row">
                    <div class="col-10">
                      <div class="form-floating mt-3">
                        <input [(ngModel)]="item.text_data" placeholder="data" type="text" class="form-control" />
                        <label>Texto</label>
                      </div>
                    </div>
                    <div class="col-2 mt-3">
                      <div class="d-flex w-100 justify-content-between" aria-label="Basic example">
                        <div><i class="fa fa-font" style="font-size: 1.5em;"></i></div>
                        <button class="btn btn-sm btn-danger" type="button" (click)="deleteElement(i)"><i
                            class="fa fa-times-circle"></i></button>
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="form-floating mt-3">
                        <input [(ngModel)]="item.text_x" placeholder="X" type="text" class="form-control" />
                        <label>X</label>
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="form-floating mt-3">
                        <input [(ngModel)]="item.text_y" placeholder="Y" type="text" class="form-control" />
                        <label>Y</label>
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="form-floating mt-3">
                        <select [(ngModel)]="item.text_align" class="form-select" placeholder="Align">
                          <option value="center">Center</option>
                          <option value="left">Left</option>
                          <option value="right">Right</option>
                        </select>
                        <label for="">Align</label>
                      </div>
                    </div>

                    <div class="col-2">
                      <div class="form-floating mt-3">
                        <select [(ngModel)]="item.text_color" class="form-select" placeholder="Color">
                          <option value="000">Negro</option>
                          <option value="FF0000">Rojo</option>
                        </select>
                        <label for="">Color</label>
                      </div>
                    </div>

                    <div class="col-2">
                      <div class="form-floating mt-3">
                        <input [(ngModel)]="item.text_size" placeholder="Tamaño" type="text" class="form-control" />
                        <label>Tamaño</label>
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="form-floating mt-3">
                        <select [(ngModel)]="item.text_weight" class="form-select" placeholder="Grosor">
                          <option value="normal">Normal</option>
                          <option value="bold">Bold</option>
                        </select>
                        <label for="">Grosor</label>
                      </div>
                    </div>

                  </div>
                </div>

                <!-- ELEMENTO LINE -->
                <div class="card" *ngIf="item.modulo =='line'">
                  <div class="card-body row">
                    <div class="col-12 text-right">
                      <div class="d-flex w-100 justify-content-between" aria-label="Basic example">
                        <div><i class="fa fa-grip-lines" style="font-size: 1.5em;"></i></div>
                        <button class="btn btn-sm btn-danger" type="button" (click)="deleteElement(i)"><i
                            class="fa fa-times-circle"></i></button>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="form-floating mt-3">
                        <input [(ngModel)]="item.line_x1" placeholder="X1" type="number" class="form-control" />
                        <label>X1</label>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="form-floating mt-3">
                        <input [(ngModel)]="item.line_y1" placeholder="Y2" type="number" class="form-control" />
                        <label>Y1</label>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="form-floating mt-3">
                        <input [(ngModel)]="item.line_x2" placeholder="X2" type="number" class="form-control" />
                        <label>X2</label>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="form-floating mt-3">
                        <input [(ngModel)]="item.line_y2" placeholder="Y2" type="number" class="form-control" />
                        <label>Y2</label>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="col-6 sticky-top">
            <div class="card">
              <div class="card-body">

                <ul class="nav nav-tabs mb-4" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#general"
                      type="button" role="tab" aria-controls="general" aria-selected="true">Plantilla</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link" id="guia-tab" data-bs-toggle="tab" data-bs-target="#guia" type="button"
                      role="tab" aria-controls="guia" aria-selected="false">Guia</button>
                  </li>
                </ul>

                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade show active  bg-body" id="general" role="tabpanel"
                    aria-labelledby="general-tab">

                    <!-- PDF DINAMIC -->
                    <div class="embed-responsive embed-responsive-1by1">
                      <iframe class="embed-responsive-item" *ngIf="urlSafe" class="thumbnail" [src]="urlSafe"
                        frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="guia" role="tabpanel" aria-labelledby="guia-tab">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Llave</th>
                          <th scope="col">Detalle</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td><b>IMG</b></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>certificado</td>
                          <td>Img certificado</td>
                        </tr>
                        <tr>
                          <td>carnet</td>
                          <td>Img carnet</td>
                        </tr>
                        <tr>
                          <td>instructor_firma</td>
                          <td>Firma instructor seleccionado</td>
                        </tr>
                        <tr>
                          <td>representante_firma</td>
                          <td>Firma Representante legal</td>
                        </tr>

                        <tr>
                          <td><b>TEXT</b></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>id</td>
                          <td>ID del certificado del cliente</td>
                        </tr>
                        <tr>
                          <td>nombre</td>
                          <td>Nombre completo cliente</td>
                        </tr>
                        <tr>
                          <td>docu</td>
                          <td>Documento de identificación cliente</td>
                        </tr>
                        <tr>
                          <td>curso</td>
                          <td>Nombre del curso</td>
                        </tr>
                        <tr>
                          <td>categoria</td>
                          <td>Nombre de la categoria del curso</td>
                        </tr>
                        <tr>
                          <td>ciudad</td>
                          <td>Ciudad del curso</td>
                        </tr>
                        <tr>
                          <td>duracion</td>
                          <td>Duracion del curso</td>
                        </tr>
                        <tr>
                          <td>empresa</td>
                          <td>Nombre de la empresa seleccionada</td>
                        </tr>
                        <tr>
                          <td>empresa_nit</td>
                          <td>Nit de la empresa seleccionada</td>
                        </tr>
                        <tr>
                          <td>empresa_representante</td>
                          <td>Nombre del representante legal de la empresa seleccionada</td>
                        </tr>
                        <tr>
                          <td>arl</td>
                          <td>Arl del cliente</td>
                        </tr>
                        <tr>
                          <td>sector</td>
                          <td>Sector del cliente</td>
                        </tr>
                        <tr>
                          <td>fecha_inicio</td>
                          <td>Fecha inicio del curso</td>
                        </tr>
                        <tr>
                          <td>fecha_fin</td>
                          <td>Fecha Finalización del curso</td>
                        </tr>
                        <tr>
                          <td>fecha_certificacion</td>
                          <td>Fecha Certificacion del curso</td>
                        </tr>
                        <tr>
                          <td>fecha_vencimiento</td>
                          <td>Fecha Vencimiento del curso</td>
                        </tr>
                        <tr>
                          <td>instructor</td>
                          <td>Nombre del instructor del curso</td>
                        </tr>
                        <tr>
                          <td>instructor_licencia</td>
                          <td>Licencia del instructor del curso</td>
                        </tr>
                        <tr>
                          <td>representante</td>
                          <td>Nombre del representante legal</td>
                        </tr>
                        <tr>
                          <td>representante_docu</td>
                          <td>Documento del representante legal</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>