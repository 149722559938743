import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';

import {AppRoutingModule} from '@/app-routing.module';
import {AppComponent} from './app.component';
import {MainComponent} from '@modules/main/main.component';
import {LoginComponent} from '@modules/login/login.component';
import {HeaderComponent} from '@modules/main/header/header.component';
import {FooterComponent} from '@modules/main/footer/footer.component';
import {MenuSidebarComponent} from '@modules/main/menu-sidebar/menu-sidebar.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ProfileComponent} from '@pages/profile/profile.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RegisterComponent} from '@modules/register/register.component';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {ToastrModule} from 'ngx-toastr';
import {MessagesComponent} from '@modules/main/header/messages/messages.component';
import {NotificationsComponent} from '@modules/main/header/notifications/notifications.component';
import {ButtonComponent} from './components/button/button.component';
import { CurrencyMaskModule } from "ng2-currency-mask";

import {registerLocaleData} from '@angular/common';
import localeEn from '@angular/common/locales/en';
import {UserComponent} from '@modules/main/header/user/user.component';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {LanguageComponent} from '@modules/main/header/language/language.component';
import {PrivacyPolicyComponent} from './modules/privacy-policy/privacy-policy.component';
import {MainMenuComponent} from './pages/main-menu/main-menu.component';
import {SubMenuComponent} from './pages/main-menu/sub-menu/sub-menu.component';
import {MenuItemComponent} from './components/menu-item/menu-item.component';
import {DropdownComponent} from './components/dropdown/dropdown.component';
import {DropdownMenuComponent} from './components/dropdown/dropdown-menu/dropdown-menu.component';
import { EmpresasComponent } from './pages/empresas/empresas.component';
import { UsuariosComponent } from './pages/usuarios/usuarios.component';
import { FiltroClientePipe } from './pipes/filtro-cliente.pipe';
import { FormSolicitudComponent } from './modules/form-solicitud/form-solicitud.component';
import { ClientesComponent } from './pages/clientes/clientes.component';
import { FiltroIataPipe } from './pipes/filtro-iata.pipe';
import { FiltroProveedorActividadPipe } from './pipes/filtro-proveedor-actividad.pipe';
import { FiltroUsuarioPipe } from './pipes/filtro-usuario.pipe';
import { DecimalPipe } from './pipes/decimal.pipe';
import { InstructoresComponent } from './pages/instructores/instructores.component';
import { CursosComponent } from './pages/cursos/cursos.component';
import { TipoCursoComponent } from './pages/tipo-curso/tipo-curso.component';
import { InstitutoComponent } from './pages/instituto/instituto.component';


import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

//locale 
import localeEsCo from '@angular/common/locales/es-CO';
import { NuevoCursoComponent } from './pages/cursos/nuevo-curso/nuevo-curso.component';
import { VerCursoComponent } from './pages/cursos/ver-curso/ver-curso.component';
import { SeguimientoComponent } from './pages/seguimiento/seguimiento.component';
import { PendientesComponent } from './pages/certificados/pendientes/pendientes.component';
import { ListadoInformeComponent } from './pages/informes/listado-informe/listado-informe.component';
import { EmpresaCursoComponent } from './pages/cursos/empresa-curso/empresa-curso.component';
import { FechaMoment1Pipe } from './pipes/fecha-moment1.pipe';
import { DescargarComponent } from './pages/certificados/descargar/descargar.component';
import { ProductosComponent } from './pages/productos/productos.component';
import { SolicitudesComponent } from './pages/solicitudes/solicitudes.component';
import { CotizadorComponent } from './pages/cotizador/cotizador.component';
import { LetterByLetterPipe } from './pipes/letter-by-letter.pipe';
import { NgxTypedJsModule } from 'ngx-typed-js';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { WompiComponent } from './pages/pay/wompi/wompi.component';
registerLocaleData(localeEsCo);


// registerLocaleData(localeEn, 'en-EN');
// registerLocaleData(localeEn, 'es-ES');

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        LoginComponent,
        HeaderComponent,
        FooterComponent,
        MenuSidebarComponent,
        BlankComponent,
        ProfileComponent,
        RegisterComponent,
        DashboardComponent,
        MessagesComponent,
        NotificationsComponent,
        ButtonComponent,
        UserComponent,
        ForgotPasswordComponent,
        RecoverPasswordComponent,
        LanguageComponent,
        PrivacyPolicyComponent,
        MainMenuComponent,
        SubMenuComponent,
        MenuItemComponent,
        DropdownComponent,
        DropdownMenuComponent,
        EmpresasComponent,
        UsuariosComponent,
        FiltroClientePipe,
        FormSolicitudComponent,
        ClientesComponent,
        FiltroIataPipe,
        FiltroProveedorActividadPipe,
        FiltroUsuarioPipe,
        DecimalPipe,
        LetterByLetterPipe,
        InstructoresComponent,
        CursosComponent,
        TipoCursoComponent,
        InstitutoComponent,
        NuevoCursoComponent,
        VerCursoComponent,
        SeguimientoComponent,
        PendientesComponent,
        ListadoInformeComponent,
        EmpresaCursoComponent,
        FechaMoment1Pipe,
        DescargarComponent,
        ProductosComponent,
        SolicitudesComponent,
        CotizadorComponent,
        WompiComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AngularEditorModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        CurrencyMaskModule,
        NgxTypedJsModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
          }),
        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: 'toast-top-right',
            preventDuplicates: true
        })
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {}
