<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <div class="row">
          <div class="col-5">
            <h1>
              <div style="float: left;">Solicitudes </div>
              <div class="input-group mb-3 col-3">
                <div class="input-group-prepend" id="button-addon3">
                  <!-- <button class="btn btn-sm btn-primary" type="button" data-bs-toggle="modal" data-bs-toggle="modal" data-bs-target="#nuevo"><i class="fa fa-plus"></i></button> -->
                  <button type="button" (click)="getList()" *ngIf="!cargando" class="btn btn-primary"><i
                      class="fa fa-sync"></i> </button>
                  <button type="button" *ngIf="cargando" class="btn btn-primary"><span
                      class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> </button>
                  <!-- <button type="button" data-bs-toggle="modal" data-bs-toggle="modal" data-bs-target="#exportar"
                    class="btn btn-success"><i class="fa fa-file-excel"></i> </button> -->
                </div>
              </div>
            </h1>
          </div>
          <!-- <div class="col-7">
                <div class="input-group mb-0">
                  <input type="text" class="form-control" [(ngModel)]="findInput" placeholder="Buscador">
                  <button class="btn btn-secondary" type="button"  id="button-addon2" (click)="findCliente()"><i class="fa fa-search"></i></button>
                </div>                  
              </div> -->
        </div>

      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">

          <li class="breadcrumb-item"><a href="#">Inicio</a></li>
          <li class="breadcrumb-item active">Solicitudes</li>
        </ol>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content m-2">
  <div class="card">
    <div class="card-body table-responsive p-0">
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Nombre</th>
            <th scope="col">Producto</th>
            <th scope="col"></th>
            <th scope="col">Estado</th>
            <th scope="col">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of obj.items">
            <td (click)="ver(item)" style="cursor: pointer;">#{{item.id | titlecase}}</td>
            <td (click)="ver(item)" style="cursor: pointer;">{{item.nombre | titlecase}}</td>
            <td>{{item.producto_nombre | uppercase}}</td>
            <td>{{item.solicitud_at}}</td>
            <td><span *ngIf="item.estado ==1" class="badge bg-warning">En proceso</span> <span *ngIf="item.estado ==2"
                class="badge bg-success">Procesada</span></td>
            <td>

              <div class="dropdown">
                <button class="btn btn-block btn-light dropdown-toggle" type="button" id="dropdownMenuButton1"
                  data-bs-toggle="dropdown" aria-expanded="false"></button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <!-- <li><span class="dropdown-item"  style="cursor: pointer;" (click)="ver(item)">Ver Solicitud</span></li> -->
                  <li *ngIf="user.perfil == '2fk6i2o3143'"><span class="dropdown-item text-danger"
                      style="cursor: pointer;" (click)="eliminarUsuario(item)">Eliminar</span></li>
                </ul>
              </div>

            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- paginador -->
  <div class="card-footer clearfix">
    <ul class="pagination pagination-sm m-0 float-right">
      <li class="page-item" *ngIf="obj.current > 1">
        <button type="button" class="page-link" (click)="changePage(obj.current - 1)">
          <span>Atras</span>
        </button>
      </li>

      <li class="page-item" *ngFor="let page of pagesNumber" [ngClass]="{'active': obj.current == page}">
        <button type="button" class="page-link" (click)="changePage(page)">
          {{ page }}
        </button>
      </li>

      <li class="page-item" *ngIf="obj.current < obj.total_page">
        <button type="button" class="page-link" (click)="changePage(obj.next)">
          <span>Siguiente</span>
        </button>
      </li>
    </ul>
    <div class="pagination" style="height: auto !important; margin: 10px;margin-top:0px;">
      <p>Total registros encontrados: <b>{{obj.size}}</b></p>
    </div>
  </div>
  <!-- Fin paginador -->

</section>




<!-- Modal Ver  -->
<div class="modal fade" id="ver" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          <h3><span *ngIf="editForm.value.estado ==1" class="badge bg-warning">En proceso</span> <span
              *ngIf="editForm.value.estado ==2" class="badge bg-success">Procesada</span> Solicitud
            {{editForm.value.id}}</h3>
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body mt-0">
        <form [formGroup]="editForm" (ngSubmit)="edit()">
          <div class="row">
            <div class="col-6">
              <div class="col-12 mb-1">
                <button type="button" (click)="exportCliente()" class="btn btn-sm btn-success"><i class="fa fa-file-excel"></i> Exportar Solicitud</button>    
              </div>

              <div class="col-12 mt-1">
                <div class="form-floating">
                  <input formControlName="nombre" id="floatingNombre" placeholder="Nombre" type="text"
                    class="form-control" readonly />
                  <label for="floatingNombre">Nombre</label>
                </div>
              </div>
              <div class="col-12 mt-1">
                <div class="form-floating">
                  <input formControlName="fecha_nacimiento" id="floatingNombre" placeholder="fecha nacimiento" type="text"
                    class="form-control" readonly />
                  <label for="floatingNombre">Fecha nacimiento</label>
                </div>
              </div>
  
              <div class="col-12 mt-1">
                <div class="form-floating">
                  <input formControlName="documento" id="floatingNombre" placeholder="documento" type="text"
                    class="form-control" readonly />
                  <label for="floatingNombre">Documento</label>
                </div>
              </div>

              <div class="col-12 mt-1">
                <div class="form-floating">
                  <input formControlName="ciudad" id="floatingNombre" placeholder="ciudad" type="text"
                    class="form-control" readonly />
                  <label for="floatingNombre">Ciudad</label>
                </div>
              </div>
              <div class="col-12 mt-1">
                <div class="form-floating">
                  <input formControlName="contacto" id="floatingNombre" placeholder="contacto" type="text"
                    class="form-control" readonly />
                  <label for="floatingNombre">Contacto</label>
                </div>
              </div>
              <div class="col-12 mt-1">
                <div class="form-floating">
                  <input formControlName="email" id="floatingNombre" placeholder="email" type="text" class="form-control"
                    readonly />
                  <label for="floatingNombre">Email</label>
                </div>
              </div>

  
              <!-- <div class="col-12 mt-1">
                <div class="form-floating">
                  <input formControlName="producto_nombre" id="floatingNombre" placeholder="producto_nombre" type="text"
                    class="form-control" readonly />
                  <label for="floatingNombre">Producto </label>
                </div>
              </div>               -->
            </div>
            <div class="col-6 receiptWrapper  pb-5">
                <div class="receipt zig-zag zig-zag-top zig-zag-bottom">
                  <div class="receiptHeader">
                    <!-- <img class="img-fluid border mb-3" :src="{{editItem.image}}"> -->
                  </div>
                  <div class="receiptBody">
                    <div class="receiptItem">
                      <h5>{{producto_nombre}}</h5>
                      <h6>Estado transacción</h6>
                      <h2> {{pay_status}}</h2>
                      <h6>Transacción #</h6>
                      <h4>{{referencia_pago}}</h4>
                      <h6>Metodo Pago</h6>
                      <h4><b>{{metodo_pago}}</b></h4>
                      <div class="col-12 text-center">
                        <h3>${{valorTotal | decimal}}</h3>
                        <small>{{fechaSolicitud}}</small>
                      </div>
                    </div>
                  </div>
                  <div class="receiptFooter">
                    loby&seguros.com
                  </div>
              </div>              
            </div>
          </div>

          <div class="row">
            <!-- <app-button [type]="'submit'" [block]="true" [loading]="isAuthLoading">Actualizar</app-button>                        -->
            <div class="col-12 mt-2">
              <div class="card">
                <div class="card-header">
                  <h6>Beneficiarios principales</h6>
                </div>
                <div class="card-body">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Nombre</th>
                        <th scope="col">Documento</th>
                        <th scope="col">Genero</th>
                        <th scope="col">Fecha nacimiento</th>
                        <th scope="col">Edad</th>
                        <th scope="col">Parentesco</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of beneficiariosPrincipales">
                        <th scope="row"></th>
                        <td>{{item.nombre | titlecase}}</td>
                        <td>{{item.documento}}</td>
                        <td>{{item.genero | titlecase}}</td>
                        <td>{{item.fecha_nacimiento}}</td>
                        <td>{{item.edad}}</td>
                        <td>{{item.parentesco | titlecase}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-12 mt-2">
              <div class="card">
                <div class="card-header">
                  <h6>Beneficiarios Adicionales</h6>
                </div>
                <div class="card-body">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Nombre</th>
                        <th scope="col">Documento</th>
                        <th scope="col">Genero</th>
                        <th scope="col">Fecha nacimiento</th>
                        <th scope="col">Edad</th>
                        <th scope="col">Parentesco</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of beneficiariosAdicionales">
                        <th scope="row"></th>
                        <td>{{item.nombre | titlecase}}</td>
                        <td>{{item.documento}}</td>
                        <td>{{item.genero | titlecase}}</td>
                        <td>{{item.fecha_nacimiento}}</td>
                        <td>{{item.edad}}</td>
                        <td>{{item.parentesco | titlecase}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            

          </div>
        </form>
        <div class="btn-group mt-3 text-center" role="group" aria-label="Basic example">
          <button type="button" class="btn btn-warning" (click)="actualizarProceso(editForm.value.id,1)"
            data-bs-dismiss="modal">En proceso</button>
          <button type="button" class="btn btn-success" (click)="actualizarProceso(editForm.value.id,2)"
            data-bs-dismiss="modal">Procesado</button>
        </div>

      </div>
    </div>
  </div>
</div>



<!-- Modal Nueva  -->
<!-- <div class="modal fade" id="nuevo" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel"><h3>Nuevo </h3> </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body mt-0">
            <form class="row" [formGroup]="nuevoForm" (ngSubmit)="new()">
              <div class="row">
                <div class="col-12 mt-0">
                    <div class="form-floating">
                      <input formControlName="nombre" id="floatingNombre" placeholder="Nombre" type="text" class="form-control"/>
                      <label for="floatingNombre">Nombre <span class="text-danger">*</span></label>
                    </div>
          
                    <div class="form-floating mt-3">
                      <select formControlName="tipo_documento" class="form-select"  placeholder="Tipo Documento">
                        <option value="c.c">C.C</option>
                        <option value="c.e">C.E</option>
                        <option value="n.d">N.D</option>
                        <option value="p.e.p">P.E.P</option>
                        <option value="p.p.t">P.P.T</option>
                        <option value="pas">PAS</option>
                        <option value="t.i">T.I</option>
                      </select>                 
                      <label for="">Tipo Documento <span class="text-danger">*</span></label>
                    </div>
          
                    <div class="form-floating mt-3">
                      <input formControlName="documento" placeholder="Documento" type="text" class="form-control"/>
                      <label for="">Documento <span class="text-danger">*</span></label>
                    </div>              
                        
                                  
                    <div class="form-floating mt-3 mb-3">
                      <textarea class="form-control" formControlName="licencia" placeholder="Licencia" style="height: 200px"></textarea>
                      <label for="">Licencia <span class="text-danger">*</span></label>
                    </div>   

                           
                    <app-button [type]="'submit'" [block]="true" [loading]="isAuthLoading">Actualizar</app-button>                       
                </div> 
              </div>

            </form>                            
        </div>
      </div>
    </div>
  </div> -->