import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Gatekeeper } from 'gatekeeper-client-sdk';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class AppService {
    public user: any = null;
    // private soapEndpoint = 'http://10.115.25.17:8080/WSConsultaListas/consultaListas?wsdl';


    // public static get baseURL(): string { return "https://api.miscertificados.net/" };
    public static get baseURL(): string { return "https://api.lobyseguros.com/" };

    constructor(private router: Router, private toastr: ToastrService, private http: HttpClient) { }


    // Método para realizar una solicitud SOAP
    //    sendSoapRequest(xmlBody: string){
    //     // Establecer las cabeceras necesarias para una solicitud SOAP
    //     const headers = new HttpHeaders({
    //       'Content-Type': 'text/xml',
    //       'Accept': 'text/xml',
    //     });

    //     // Realizar la solicitud POST al servicio SOAP
    //     return this.http.post(this.soapEndpoint, xmlBody, { headers, responseType: 'text' });
    //   }    


    // ESTADISTICAS    
    getEstadisticas(params) {
        return this.http.get(AppService.baseURL + 'status/' + params);
    }


    // EMPRESAS    
    getEmpresas(params) {
        return this.http.get(AppService.baseURL + 'empresa/' + params);
    }

    postEmpresa(params) {
        return this.http.post(AppService.baseURL + 'empresa/', params);
    }




    // CLIENTES    
    getClientes(params) {
        return this.http.get(AppService.baseURL + 'cliente/' + params);
    }

    postClientes(params) {
        return this.http.post(AppService.baseURL + 'cliente/', params);
    }


    // INSTRUCTORES
    getInstructor(params) {
        return this.http.get(AppService.baseURL + 'instructor/' + params);
    }

    postInstructor(params) {
        return this.http.post(AppService.baseURL + 'instructor/', params);
    }

    // TIPO CURSO
    getTipoCurso(params) {
        return this.http.get(AppService.baseURL + 'tipo_curso/' + params);
    }

    postTipoCurso(params) {
        return this.http.post(AppService.baseURL + 'tipo_curso/', params);
    }


    // CATEGORIAS
    getCategoria(params) {
        return this.http.get(AppService.baseURL + 'categoria/' + params);
    }

    postCategoria(params) {
        return this.http.post(AppService.baseURL + 'categoria/', params);
    }

    // TIPO CURSO PLANTILLA
    getTipoCursoPlantilla(params) {
        return this.http.get(AppService.baseURL + 'tipo_curso_plantilla/' + params);
    }

    postTipoCursoPlantilla(params) {
        return this.http.post(AppService.baseURL + 'tipo_curso_plantilla/', params);
    }


    // INSTITUTO
    getInstituto(params) {
        return this.http.get(AppService.baseURL + 'instituto/' + params);
    }

    postInstituto(params) {
        return this.http.post(AppService.baseURL + 'instituto/', params);
    }

    // INSTITUTO CONTRATO
    getInstitutoContrato(params) {
        return this.http.get(AppService.baseURL + 'instituto_contrato/' + params);
    }

    postInstitutoContrato(params) {
        return this.http.post(AppService.baseURL + 'instituto_contrato/', params);
    }

    //  CURSOS
    getCurso(params) {
        return this.http.get(AppService.baseURL + 'curso/' + params);
    }

    postCurso(params) {
        return this.http.post(AppService.baseURL + 'curso/', params);
    }

    //  CERTIFICADOS
    getCertificado(params) {
        return this.http.get(AppService.baseURL + 'certificado/' + params);
    }

    postCertificado(params) {
        return this.http.post(AppService.baseURL + 'certificado/', params);
    }

    //  CERTIFICADOS PENDIENTES
    getCertificadoPendiente(params) {
        return this.http.get(AppService.baseURL + 'certificado_pendiente/' + params);
    }

    postCertificadoPendiente(params) {
        return this.http.post(AppService.baseURL + 'certificado_pendiente/', params);
    }

    //  CERTIFICADOS EMPRESA
    getCertificadoEmpresa(params) {
        return this.http.get(AppService.baseURL + 'certificado_empresa/' + params);
    }

    postCertificadoEmpresa(params) {
        return this.http.post(AppService.baseURL + 'certificado_empresa/', params);
    }



    // // mensajeria
    // getMensajeria(params) {
    //     return this.http.get(AppService.baseURL + 'mensajeria/' + params);
    // }

    // setMensajeria(params) {
    //     return this.http.post(AppService.baseURL + 'mensajeria/', params);
    // }


    // //Departamentos
    // getDepartamentos() {
    //     return this.http.get(AppService.baseURL + 'departamento/');
    // }
    // getDepartamentoMunicipios(params) {
    //     return this.http.get(AppService.baseURL + 'departamento/' + params);
    // }

    postRegistraduria(params) {
        return this.http.get(AppService.baseURL + 'registraduria/'+ params);
    }

    postListasRestrictivas(params) {
        return this.http.get(AppService.baseURL + 'registraduria_lista_restringida/'+ params);
    }

    // USUARIOS    
    getUsuarios(params) {
        return this.http.get(AppService.baseURL + 'usuario/' + params);
    }

    postUsuario(params) {
        return this.http.post(AppService.baseURL + 'usuario/', params);
    }

    // PRODUCTOS    
    getProductos(params) {
        return this.http.get(AppService.baseURL + 'producto/' + params);
    }

    postProductos(params) {
        return this.http.post(AppService.baseURL + 'producto/', params);
    }

    // SOLICITUDES    
    getSolicitudes(params) {
        return this.http.get(AppService.baseURL + 'solicitud/' + params);
    }

    postSolicitudes(params) {
        return this.http.post(AppService.baseURL + 'solicitud/', params);
    }



    // UPLOAD IMG
    uploadImg(params) {
        return this.http.post(AppService.baseURL + 'upload_img/', params);
    }

    // ENVIO PUSH FIREBASE

    getPush(params) {
        return this.http.get(AppService.baseURL + 'notificacion_push/' + params);
    }

    postPush(params) {
        return this.http.post(AppService.baseURL + 'notificacion_push/', params);
    }

    //  OTROS
    async loginByAuth({ email, password }) {
        try {
            const token = await Gatekeeper.loginByAuth(email, password);
            // console.log(token);            
            // const token = "fasdfasdfadfafdafasf";
            sessionStorage.setItem('token', token);
            // await this.getProfile();
            this.router.navigate(['dashboard/']);
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async registerByAuth({ email, password }) {
        try {
            const token = await Gatekeeper.registerByAuth(email, password);
            sessionStorage.setItem('token', token);
            // await this.getProfile();
            this.router.navigate(['dashboard/']);
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async Login({ email, password }) {
        let params = {
            lg: true,
            email: email,
            password: password,
        };
        //   console.log(params);
        this.http.post(AppService.baseURL + 'login', params)
            .subscribe((data) => {
                if (data["logger"] == true) {
                    // console.log(data);
                    const token = data["token"];
                    sessionStorage.setItem('token', token);
                    this.user = data;
                    this.router.navigate(['dashboard/']);
                    this.toastr.success(data["msg"]);
                } else {
                    this.toastr.error(data["msg"]);

                }
            });
        // return this.http.post(AppService.baseURL+'login',params);
    }


    logout() {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('gatekeeper_token');
        this.user = null;
        this.router.navigate(['/cotizador']);
        // this.toastr.success('Sesión Cerrada');
    }

    // API WOMPI BANCOLOMBIA
    getTransactionsSandbox(params) {
        return this.http.get('https://sandbox.wompi.co/v1/transactions/' + params);
    }

    getTransactions(params) {
        return this.http.get('https://production.wompi.co/v1/transactions/' + params);
    }
}
