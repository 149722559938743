import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppService } from '@services/app.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import { jsPDF } from "jspdf";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import moment from 'moment';

declare var $: any;

@Component({
  selector: 'app-tipo-curso',
  templateUrl: './tipo-curso.component.html',
  styleUrls: ['./tipo-curso.component.scss']
})
export class TipoCursoComponent implements OnInit {
  public nuevoForm: FormGroup;
  public editForm: FormGroup;
  public categoriaNuevoForm: FormGroup;
  public categoriaEditForm: FormGroup;
  public plantillaNuevaForm: FormGroup;

  public plantillaEditar = false;

  public isAuthLoading = false;

  public user: any = null;
  reporteExport: any;
  findInput: any;
  obj: any = [];
  objCate: any = [];
  pagesNumber: any;
  offset = 3;
  page = 1;
  public cargando = false;
  public loadingImg = false;

  urlSafe: SafeResourceUrl;
  nombrePlantilla: any;
  orientacionPlantilla: any;
  plantillaId: any;
  objPlantilla: any = [];
  objPlantillaOrder: any = [];
  nuevoElement: any;
  objPlantillaDemo: any = {
    "id": "1",
    "certificado": "1",
    "curso_id": "1",
    "cliente_id": "1",
    "usuario_id": "2",
    "estado": "1",
    "empresa_id": "1",
    "certificado_at": "2022-10-14 21:14:41",
    "nombre": "andres ",
    "apellido": "avila andrade",
    "tipo_documento": "c.c",
    "documento": "1075221976",
    "genero": "M",
    "pais_nacimiento": "colombia",
    "fecha_nacimiento": "1987-07-17",
    "nivel_educativo": "",
    "cargo_actual": "",
    "area_trabajo": "",
    "sector": "agropecuario",
    "arl": "sura",
    "instituto_id": "3",
    "instituto_nombre": "trabajadores & Asociados Ldta",
    "instituto_nit": "987654321",
    "instituto_representante": "mario perez sanchez",
    "fecha_inicio": "2022-10-15",
    "fecha_fin": "2022-10-17",
    "fecha_certificacion": "2023-01-14",
    "fecha_vencimiento": "2024-10-14",
    "ciudad": "Neiva (H)",
    "tipo_curso_id": "4",
    "tipo_curso_nombre": "manejo defensivo",
    "tipo_formacion": "",
    "vigencia": "12",
    "tiempo": "80",
    "duracion": "horas",
    "categoria_nombre": "Categoria 1",
    "referencia": "MD-",
    "plantilla": "bce3147a1b4ca37dae96b27993e5b822.jpg",
    "carnet": "baa737d0efd7b32431e3d7b219a83a28.jpg",
    "carnet_url": "",
    "instructor_id": "2",
    "instructor_nombre": "guadalupe martinez romero",
    "instructor_licencia": "especialista en AAA con instruccion  en BBB",
    "instructor_firma": "12c624ef89541b97006bfffeea0638fb.png",
    "plantilla_url": "",
    "instructor_firma_url": "https://api.miscertificados.net/img/upload/firmas/833e95cac7819c6f50a5591108376eb5.jpg",
    "representante_nombre": "Pedro Perez Ramirez",
    "representante_documento": "C.C. 122312312343",
    "representante_firma_url": "https://api.miscertificados.net/img/upload/firmas/0e1a900e4a39054c313e1b887dba9a70.jpg",
  };
  // "representante_firma_url": null,
  // "carnet_url": null,


  constructor(public router: Router, private toastr: ToastrService, private appService: AppService, private http: HttpClient, private sanitizer: DomSanitizer, private renderer: Renderer2, @Inject(DOCUMENT) private document: Document) { }
  ngAfterViewInit() {
    // this.loadJsScript();
  }
  ngOnInit(): void {
    this.user = this.appService.user;
    // console.log(this.user);
    this.getList();
    this.getListCate();
    this.getListPlantilla();

    this.nuevoForm = new FormGroup({
      nombre: new FormControl(null, Validators.required),
      tipo_formacion: new FormControl(null, Validators.nullValidator),
      vigencia: new FormControl(null, Validators.nullValidator),
      tiempo: new FormControl(null, Validators.required),
      duracion: new FormControl(null, Validators.required),
      descripcion: new FormControl(null, Validators.nullValidator),
      valor: new FormControl(null, Validators.required),
      categoria_id: new FormControl(null, Validators.nullValidator),
      referencia: new FormControl(null, Validators.nullValidator),
      consecutivo: new FormControl(null, Validators.nullValidator),
      plantilla: new FormControl(null, Validators.nullValidator),
      plantilla_url: new FormControl(null, Validators.nullValidator),
      plantilla_json: new FormControl(null, Validators.nullValidator),
      plantilla_json_id: new FormControl(null, Validators.nullValidator),
      carnet: new FormControl(null, Validators.nullValidator),
      carnet_url: new FormControl(null, Validators.nullValidator),
      carnet_json: new FormControl(null, Validators.nullValidator),
      carnet_json_id: new FormControl(null, Validators.nullValidator),
    });

    this.editForm = new FormGroup({
      id: new FormControl(null, Validators.required),
      nombre: new FormControl(null, Validators.required),
      tipo_formacion: new FormControl(null, Validators.nullValidator),
      vigencia: new FormControl(null, Validators.nullValidator),
      tiempo: new FormControl(null, Validators.required),
      duracion: new FormControl(null, Validators.required),
      descripcion: new FormControl(null, Validators.nullValidator),
      valor: new FormControl(null, Validators.required),
      categoria_id: new FormControl(null, Validators.nullValidator),
      referencia: new FormControl(null, Validators.nullValidator),
      consecutivo: new FormControl(null, Validators.nullValidator),
      // consecutivo: new FormControl({value:"", disabled: true}, Validators.nullValidator),
      plantilla: new FormControl(null, Validators.nullValidator),
      plantilla_url: new FormControl(null, Validators.nullValidator),
      plantilla_json_id: new FormControl(null, Validators.nullValidator),
      plantilla_json: new FormControl(null, Validators.nullValidator),
      carnet: new FormControl(null, Validators.nullValidator),
      carnet_url: new FormControl(null, Validators.nullValidator),
      carnet_json_id: new FormControl(null, Validators.nullValidator),
      carnet_json: new FormControl(null, Validators.nullValidator),
    });

    this.categoriaNuevoForm = new FormGroup({
      nombre: new FormControl(null, Validators.required),
    });

    this.categoriaEditForm = new FormGroup({
      id: new FormControl(null, Validators.required),
      nombre: new FormControl(null, Validators.required),
    });

    this.plantillaNuevaForm = new FormGroup({
      modulo: new FormControl(null, Validators.nullValidator),

      img_url: new FormControl(null, Validators.nullValidator),
      img_tipo: new FormControl(null, Validators.nullValidator),
      img_x: new FormControl(null, Validators.nullValidator),
      img_y: new FormControl(null, Validators.nullValidator),
      img_w: new FormControl(null, Validators.nullValidator),
      img_h: new FormControl(null, Validators.nullValidator),


      text_data: new FormControl(null, Validators.nullValidator),
      text_x: new FormControl(null, Validators.nullValidator),
      text_y: new FormControl(null, Validators.nullValidator),
      text_align: new FormControl(null, Validators.nullValidator),
      text_color: new FormControl(null, Validators.nullValidator),
      text_size: new FormControl(null, Validators.nullValidator),
      text_weight: new FormControl(null, Validators.nullValidator),

      line_x1: new FormControl(null, Validators.nullValidator),
      line_y1: new FormControl(null, Validators.nullValidator),
      line_x2: new FormControl(null, Validators.nullValidator),
      line_y2: new FormControl(null, Validators.nullValidator),
    });
  }


  loadJsScript(): HTMLScriptElement {
    // console.log('aca');
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = 'assets/png_support.js';
    this.renderer.appendChild(this.document.body, script);
    return script;
  }


  getList() {
    this.cargando = true;
    this.appService.getTipoCurso(this.user.et + '-' + this.page).subscribe((data) => {
      this.obj = data['obj'];
      this.ForpagesNumber();
      this.cargando = false;
    });
  }

  getListCate() {
    this.cargando = true;
    this.appService.getCategoria(this.user.et + '-' + this.page).subscribe((data) => {
      this.objCate = data['obj'];
      // this.ForpagesNumber();
      this.cargando = false;
    });
  }
  getListPlantilla() {
    this.cargando = true;
    this.appService.getTipoCursoPlantilla(this.user.et + '-' + this.page).subscribe((data) => {
      this.objPlantilla = data['obj'];
      this.cargando = false;
    });
  }

  changePage(page) {
    this.page = page; //para el filtro
    this.obj.current = page;
    this.getList();
  }

  ForpagesNumber() {
    let from = this.obj.current - this.offset;
    if (from < 1) {
      from = 1;
    }

    let to = from + (this.offset * 2);
    if (to >= this.obj.total_page) {
      to = this.obj.total_page;
    }

    let pagesArray = [];
    while (from <= to) {
      pagesArray.push(from);
      from++;
    }
    this.pagesNumber = pagesArray;
    // return pagesArray;
  }

  async new() {
    // console.log(this.nuevoForm);
    if (this.nuevoForm.valid) {
      this.isAuthLoading = true;
      let params = {
        nuevo: true,
        et: this.user.et,
        campos: this.nuevoForm.value,
        estado: 1,
      };
      // console.log(params);
      this.appService.postTipoCurso(params)
        .subscribe((data) => {
          // console.log(data);
          if (data["logger"] == true) {
            this.nuevoForm.reset()
            $('#nuevo').modal('hide');
            // $('#offcanvasNuevaUsuario').offcanvas('hide');
            this.toastr.success(data["msg"]);
            this.getList();
          } else {
            this.toastr.error(data["msg"]);
          }
        });
      this.isAuthLoading = false;
    } else {
      this.toastr.error('El formulario no es válido!');
    }
  }

  ver(item) {
    // console.log(item);
    this.editForm.get('id').setValue(item.id);
    this.editForm.get('nombre').setValue(item.nombre);
    this.editForm.get('tipo_formacion').setValue(item.tipo_formacion);
    this.editForm.get('vigencia').setValue(item.vigencia);
    this.editForm.get('tiempo').setValue(item.tiempo);
    this.editForm.get('duracion').setValue(item.duracion);
    this.editForm.get('descripcion').setValue(item.descripcion);
    this.editForm.get('valor').setValue(item.valor);
    this.editForm.get('categoria_id').setValue(item.categoria_id);
    this.editForm.get('referencia').setValue(item.referencia);
    this.editForm.get('consecutivo').setValue(item.consecutivo);
    this.editForm.get('plantilla').setValue(item.plantilla);
    this.editForm.get('plantilla_url').setValue(item.plantilla_url);
    this.editForm.get('plantilla_json_id').setValue(item.plantilla_json_id);
    this.editForm.get('carnet').setValue(item.carnet);
    this.editForm.get('carnet_url').setValue(item.carnet_url);
    this.editForm.get('carnet_json_id').setValue(item.carnet_json_id);
    $('#ver').modal('show');
  }

  async edit() {
    if (this.editForm.valid) {
      this.isAuthLoading = true;
      let params = {
        editar: true,
        id: this.editForm.value.id,
        campos: this.editForm.value,
      };
      // console.log(params);
      this.appService.postTipoCurso(params)
        .subscribe((data) => {
          if (data["logger"] == true) {
            this.toastr.success(data["msg"]);
            this.editForm.reset()
            $('#ver').modal('hide');
            this.getList();
          } else {
            this.toastr.error(data["msg"]);
          }
        });
      this.isAuthLoading = false;
    } else {
      this.toastr.error('El formulario no es válido!');
    }
  }

  showNewCategoria() {
    $('#categoriaNuevoForm').show();
  }

  async newCategoria() {
    // console.log(this.categoriaNuevoForm);
    if (this.categoriaNuevoForm.valid) {
      this.isAuthLoading = true;
      let params = {
        nuevo: true,
        et: this.user.et,
        campos: this.categoriaNuevoForm.value,
        estado: 1,
      };
      // console.log(params);
      this.appService.postCategoria(params)
        .subscribe((data) => {
          // console.log(data);
          if (data["logger"] == true) {
            this.categoriaNuevoForm.reset()
            $('#categoriaNuevoForm').hide();
            // $('#offcanvasNuevaUsuario').offcanvas('hide');
            this.toastr.success(data["msg"]);
            this.getListCate();
          } else {
            this.toastr.error(data["msg"]);
          }
        });
      this.isAuthLoading = false;
    } else {
      this.toastr.error('El formulario no es válido!');
    }

  }

  showEditCategoria(item) {
    $('#categoriaEditForm').show();
    this.categoriaEditForm.get('id').setValue(item.id);
    this.categoriaEditForm.get('nombre').setValue(item.nombre);
  }

  async editCategoria() {
    if (this.categoriaEditForm.valid) {
      this.isAuthLoading = true;
      let params = {
        editar: true,
        id: this.categoriaEditForm.value.id,
        campos: this.categoriaEditForm.value,
      };
      // console.log(params);
      this.appService.postCategoria(params)
        .subscribe((data) => {
          if (data["logger"] == true) {
            this.toastr.success(data["msg"]);
            this.categoriaEditForm.reset()
            $('#categoriaEditForm').hide();
            this.getListCate();
          } else {
            this.toastr.error(data["msg"]);
          }
        });
      this.isAuthLoading = false;
    } else {
      this.toastr.error('El formulario no es válido!');
    }
  }

  async eliminarUsuario(item) {
    Swal.fire({
      title: '¿Está seguro?',
      text: `Desea eliminar al Instructor?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, estoy seguro!',
    }).then((result) => {
      if (result.value) {
        // console.log('dentro true');

        let params = {
          eliminar: true,
          id: item.id,
          empresa_id: this.user.eid
        }
        this.appService.postTipoCurso(params)
          .subscribe((data) => {
            if (data["logger"] == true) {
              this.getList();
              this.toastr.success(data["msg"]);
            } else {
              this.toastr.error(data["msg"]);
            }
          });

      }
    });
  }

  async newPlantilla() {
    if (this.nombrePlantilla) {
      this.isAuthLoading = true;
      if (!this.plantillaEditar) {
        let params = {
          nuevo: true,
          et: this.user.et,
          nombre: this.nombrePlantilla,
          oritentacion: this.orientacionPlantilla,
          campos: this.objPlantillaOrder,
        };
        // console.log(params);
        this.appService.postTipoCursoPlantilla(params)
          .subscribe((data) => {
            if (data["logger"] == true) {
              this.toastr.success(data["msg"]);
              this.objPlantillaOrder = [];
              $('#nuevaPlantilla').modal('hide');
              this.getListPlantilla();
            } else {
              this.toastr.error(data["msg"]);
            }
          });
        this.plantillaEditar = false;
      } else {
        let params = {
          editar: true,
          id: this.plantillaId,
          et: this.user.et,
          nombre: this.nombrePlantilla,
          orientacion: this.orientacionPlantilla,
          campos: this.objPlantillaOrder,
        };
        // console.log(params);
        this.appService.postTipoCursoPlantilla(params)
          .subscribe((data) => {
            if (data["logger"] == true) {
              this.toastr.success(data["msg"]);
              this.objPlantillaOrder = [];
              $('#nuevaPlantilla').modal('hide');
              this.getListPlantilla();
            } else {
              this.toastr.error(data["msg"]);
            }
          });
        this.plantillaEditar = false;
      }
      this.isAuthLoading = false;
    } else {
      this.toastr.error('El formulario no es válido!');
    }
  }

  showEditPlantilla(item) {
    this.plantillaEditar = true;
    $('#nuevaPlantilla').modal('show');
    this.plantillaId = item.id;
    this.nombrePlantilla = item.nombre;
    this.orientacionPlantilla = item.orientacion;
    this.objPlantillaOrder = item.data_json;
    this.onCargaPdf();
    // console.log(this.objPlantillaOrder); 
  }

  async editPlantilla() {
    if (this.categoriaEditForm.valid) {
      this.isAuthLoading = true;
      let params = {
        editar: true,
        id: this.categoriaEditForm.value.id,
        campos: this.categoriaEditForm.value,
      };
      // console.log(params);
      this.appService.postCategoria(params)
        .subscribe((data) => {
          if (data["logger"] == true) {
            this.toastr.success(data["msg"]);
            this.categoriaEditForm.reset()
            $('#categoriaEditForm').hide();
            this.getListCate();
          } else {
            this.toastr.error(data["msg"]);
          }
        });
      this.isAuthLoading = false;
    } else {
      this.toastr.error('El formulario no es válido!');
    }
  }

  async eliminarPlantilla(item) {
    Swal.fire({
      title: '¿Está seguro?',
      text: `Desea eliminar la plantilla?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, estoy seguro!',
    }).then((result) => {
      if (result.value) {
        // console.log('dentro true');

        let params = {
          eliminar: true,
          id: item.id,
          et: this.user.et
        }
        this.appService.postTipoCursoPlantilla(params)
          .subscribe((data) => {
            if (data["logger"] == true) {
              this.getListPlantilla();
              this.toastr.success(data["msg"]);
            } else {
              this.toastr.error(data["msg"]);
            }
          });

      }
    });

  }

  async findCliente() {
    let params = {
      buscar: true,
      buscar_input: this.findInput,
      page: this.page,
      et: this.user.et,
    };
    this.appService.postTipoCurso(params)
      .subscribe((data) => {
        if (data["logger"] == true) {
          this.obj = data['obj'];
        } else {
          this.toastr.error(data["msg"]);
        }
      });
  }

  uploadImg(files, componente, tipo) {
    if (files.length === 0)
      return;
    this.loadingImg = true;
    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      const imgURL = reader.result;
      //   console.log("esta es la imagen"+imgURL);

      if (!imgURL) {
        console.log('error');
        return;
      } else {
        let params = {
          folder: tipo,
          archivo: imgURL
        };
        // this.cargandoImg = true;
        this.appService.uploadImg(params)
          .subscribe((data) => {
            if (data['logger'] == true) {
              if (componente == 'nuevo') {
                console.log('nuevo');
                if (tipo == 'plantilla') {
                  this.nuevoForm.get('plantilla').setValue(data['filename']);
                  this.nuevoForm.get('plantilla_url').setValue(data['fileUrl']);
                }
                if (tipo == 'carnet') {
                  this.nuevoForm.get('carnet').setValue(data['filename']);
                  this.nuevoForm.get('carnet_url').setValue(data['fileUrl']);
                }

              }
              if (componente == 'editar') {
                console.log('edit');

                if (tipo == 'plantilla') {
                  this.editForm.get('plantilla').setValue(data['filename']);
                  this.editForm.get('plantilla_url').setValue(data['fileUrl']);
                }
                if (tipo == 'carnet') {
                  this.editForm.get('carnet').setValue(data['filename']);
                  this.editForm.get('carnet_url').setValue(data['fileUrl']);
                }
              }

            }
            this.loadingImg = false;
          });
      }
    }

  }

  onCargaPdf() {
    // console.log(this.orientacionPlantilla);
    if(!this.orientacionPlantilla){ this.orientacionPlantilla = 'l';}
    
    const doc = new jsPDF(this.orientacionPlantilla, 'mm', 'A4');
    const width = doc.internal.pageSize.getWidth()
    const options = { background: 'white', scale: 3, };

    this.objPlantillaOrder.forEach(item => {
      if (item.modulo == 'img') {
        let img = item.img_url;
        let newImg = img
          .replace("{certificado}", this.objPlantillaDemo.plantilla_url)
          .replace("{carnet}", this.objPlantillaDemo.carnet_url)
          .replace("{instructor_firma}", this.objPlantillaDemo.instructor_firma_url)
          .replace("{representante_firma}", this.objPlantillaDemo.representante_firma_url);
        // console.log(newImg);
        // if(!newImg || newImg !== 'undefined' || newImg !== null  || newImg !== ''){
        if(newImg !== 'null'){
          doc.addImage(newImg, "JPG", item.img_x, item.img_y, item.img_w, item.img_h);
        }
      }

      if (item.modulo == 'text') {
        let text = item.text_data;
        moment.locale()
        // const reg = /\d.*\d/;
        let newtext = text
          .replace("{a}", "á")
          .replace("{e}", "é")
          .replace("{i}", "í")
          .replaceAll('{o}', "ó")
          .replace("{u}", 'ú')
          .replace("{nombre}", this.objPlantillaDemo.nombre.toUpperCase() + ' ' + this.objPlantillaDemo.apellido.toUpperCase())
          .replace("{docu}", this.objPlantillaDemo.tipo_documento.toUpperCase() + ' ' + this.objPlantillaDemo.documento.toUpperCase())
          .replace("{curso}", this.objPlantillaDemo.tipo_curso_nombre.toUpperCase())
          .replace("{categoria}", this.objPlantillaDemo.categoria_nombre.toUpperCase())
          .replace("{ciudad}", this.objPlantillaDemo.ciudad.toUpperCase())
          .replace("{duracion}", this.objPlantillaDemo.tiempo + ' ' + this.objPlantillaDemo.duracion)
          .replace("{empresa}", this.objPlantillaDemo.instituto_nombre.toUpperCase())
          .replace("{empresa_nit}", this.objPlantillaDemo.instituto_nit)
          .replace("{empresa_representante}", this.objPlantillaDemo.instituto_representante)
          .replace("{arl}", this.objPlantillaDemo.arl.toUpperCase())
          .replace("{sector}", this.objPlantillaDemo.sector)
          .replace("{fecha_inicio}", moment(this.objPlantillaDemo.fecha_inicio).locale('es').format('LL'))
          .replace("{fecha_fin}", moment(this.objPlantillaDemo.fecha_fin).locale('es').format('DD [de] MMMM [de] YYYY'))
          .replace("{fecha_certificacion}", moment(this.objPlantillaDemo.fecha_certificacion).locale('es').format('LL'))
          .replace("{fecha_vencimiento}", moment(this.objPlantillaDemo.fecha_vencimiento).locale('es').format('LL'))
          .replace("{instructor}", this.objPlantillaDemo.instructor_nombre.toUpperCase())
          .replace("{instructor_licencia}", this.objPlantillaDemo.instructor_licencia)
          .replace("{representante}", this.objPlantillaDemo.representante_nombre)
          .replace("{representante_docu}", this.objPlantillaDemo.representante_documento)
          .replace("{id}", this.objPlantillaDemo.referencia + '' + this.objPlantillaDemo.certificado);

        if (item.text_color) { doc.setTextColor(item.text_color); }
        if (item.text_size) { doc.setFontSize(item.text_size); }
        if (item.text_weight) { doc.setFont(undefined, item.text_weight); }
        if (item.text_align == 'center') {
          doc.text(newtext, width / 2 + +item.text_x, item.text_y, null, item.text_align);
        } else {
          doc.text(newtext, item.text_x, item.text_y, null, item.text_align);
        }
      }

      if (item.modulo == 'line') {
        doc.line(item.line_x1, item.line_y1, item.line_x2, item.line_y2);
      }
    });


    var out = doc.output();
    var url = 'data:application/pdf;base64,' + btoa(out);
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  elementNuevo(elemento) {
    this.nuevoElement = elemento;
    this.plantillaNuevaForm.get('modulo').setValue(elemento);
    // console.log(this.nuevoElement);
  }

  addElement() {
    this.objPlantillaOrder.push(this.plantillaNuevaForm.value);
    // console.log(this.objPlantillaOrder);
    this.plantillaNuevaForm.reset()
    this.nuevoElement = null;
    this.onCargaPdf();
  }

  updateElement(item) {
    // console.log(item);
  }

  deleteElement(index) {
    this.objPlantillaOrder.splice(index, 1);
  }

}