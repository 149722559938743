import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from '@modules/main/main.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {LoginComponent} from '@modules/login/login.component';
import {ProfileComponent} from '@pages/profile/profile.component';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {AuthGuard} from '@guards/auth.guard';
import {NonAuthGuard} from '@guards/non-auth.guard';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {PrivacyPolicyComponent} from '@modules/privacy-policy/privacy-policy.component';
import {MainMenuComponent} from '@pages/main-menu/main-menu.component';
import {SubMenuComponent} from '@pages/main-menu/sub-menu/sub-menu.component';
import { EmpresasComponent } from '@pages/empresas/empresas.component';
import { UsuariosComponent } from '@pages/usuarios/usuarios.component';
import { InstructoresComponent } from '@pages/instructores/instructores.component';
import { CursosComponent } from '@pages/cursos/cursos.component';
import { TipoCursoComponent } from '@pages/tipo-curso/tipo-curso.component';
import { NuevoCursoComponent } from '@pages/cursos/nuevo-curso/nuevo-curso.component';
import { VerCursoComponent } from '@pages/cursos/ver-curso/ver-curso.component';
import { PendientesComponent } from '@pages/certificados/pendientes/pendientes.component';
import { ListadoInformeComponent } from '@pages/informes/listado-informe/listado-informe.component';
import { EmpresaCursoComponent } from '@pages/cursos/empresa-curso/empresa-curso.component';
import { ProductosComponent } from '@pages/productos/productos.component';
import { SolicitudesComponent } from '@pages/solicitudes/solicitudes.component';
import { CotizadorComponent } from '@pages/cotizador/cotizador.component';
import { WompiComponent } from '@pages/pay/wompi/wompi.component';

const routes: Routes = [
    // {
    //     path: '', component: CotizadorComponent, canActivate: [NonAuthGuard] // Ruta raíz para no autenticados
    // },
    {
        path: '', component: MainComponent, canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            { path: 'dashboard', component: DashboardComponent},
            { path: 'dashboard/empresas', component: EmpresasComponent },
            { path: 'dashboard/instructores', component: InstructoresComponent },
            { path: 'dashboard/certificados/pendientes', component: PendientesComponent },
            { path: 'dashboard/informes', component: ListadoInformeComponent },
            { path: 'dashboard/solicitudes', component: SolicitudesComponent },
            { path: 'dashboard/productos', component: ProductosComponent },
            { path: 'profile', component: ProfileComponent },
            { path: 'blank', component: BlankComponent },
            { path: 'dashboard/usuarios',component: UsuariosComponent},
        ]
    },
    
    { path: 'login',component: LoginComponent,canActivate: [NonAuthGuard]},
    { path: 'forgot-password',component: ForgotPasswordComponent,canActivate: [NonAuthGuard]},
    { path: 'recover-password',component: RecoverPasswordComponent,canActivate: [NonAuthGuard]},
    { path: 'privacy-policy',component: PrivacyPolicyComponent,canActivate: [NonAuthGuard]},
    { path: 'cotizador',component: CotizadorComponent,},
    { path: 'pay/wompi/:referencia', component: WompiComponent },

    {path: '**', redirectTo: ''}
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
    exports: [RouterModule]
})
export class AppRoutingModule {}
