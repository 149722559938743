import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decimal'
})
export class DecimalPipe implements PipeTransform {

  transform(value: any, ...args: any[]): number {
    // let num = value.replace(/\./g,'');
    let num = value; 
    if(!isNaN(num)){
    num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
    num = num.split('').reverse().join('').replace(/^[\.]/,'');
    // console.log(num);
    return num;
    }else{
      return null;
    }   
  }

}
