<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>Productos
          <div class="btn-group" role="group" aria-label="Basic example">
            <button class="btn btn-sm btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#nuevaModal"><i
                class="fa fa-plus"></i></button>
            <button class="btn btn-sm btn-secondary" type="button" (click)="getList()" *ngIf="!cargando"><i class="fa fa-sync"></i>
            </button>
            <button class="btn btn-sm btn-secondary" type="button" *ngIf="cargando"><span class="spinner-border spinner-border-sm"
                role="status" aria-hidden="true"></span> </button>
          </div>
        </h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="#">Inicio</a></li>
          <li class="breadcrumb-item active">Productos</li>
        </ol>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content m-2">
  <div class="row">
    <div *ngFor="let item of productos.items" class="col-12 col-sm-6 col-md-4 d-flex align-items-stretch flex-column">
      <div class="card d-flex flex-fill">
        <div class="card-header bg-white text-muted border-bottom-0">
          <b>
            <!-- <span *ngIf="item.perfil_id ==4">Logistico</span>  -->
            <span *ngIf="item.perfil_id ==3">Logistico</span> <span *ngIf="item.perfil_id ==2">Administrador</span>
          </b>
        </div>
        <div class="card-body bg-white pt-0">
          <div class="row">
            <div class="col-12">
              <h2 class="lead"><b>{{item.nombre | titlecase}}</b></h2>
              <h6 class=""><b>{{item.email}}</b></h6>
              <h6 class=""><b>{{item.celular}}</b></h6>
            </div>
            <!-- <div class="col-2 text-center">
              <img *ngIf="item.fotografia_url" width="62px" [src]="item.fotografia_url"
                class="user-image img-circle elevation-2" alt="User Image" />
            </div> -->
          </div>
        </div>
        <div class="card-footer bg-white">
          <div class="text-right">
            <button type="button" class="btn btn-sm btn-primary mr-1" data-bs-toggle="modal"
              data-bs-target="#ver" (click)="ver(item)"><i class="fas fa-user"></i> Ver</button>
            <button type="button" class="btn btn-sm btn-secondary" (click)="eliminar(item)"><i
                class="fa fa-trash"></i></button>

          </div>
        </div>
      </div>
    </div>
  </div>



  <!-- paginador -->
  <div class="card-footer clearfix">
    <ul class="pagination pagination-sm m-0 float-right">
      <li class="page-item" *ngIf="productos.current > 1">
        <button type="button" class="page-link" (click)="changePage(productos.current - 1)">
          <span>Atras</span>
        </button>
      </li>

      <li class="page-item" *ngFor="let page of pagesNumber" [ngClass]="{'active': productos.current == page}">
        <button type="button" class="page-link" (click)="changePage(page)">
          {{ page }}
        </button>
      </li>

      <li class="page-item" *ngIf="productos.current < productos.total_page">
        <button type="button" class="page-link" (click)="changePage(productos.next)">
          <span>Siguiente</span>
        </button>
      </li>
    </ul>
    <div class="pagination" style="height: auto !important; margin: 10px;margin-top:0px;">
      <p>Total registros encontrados: <b>{{productos.size}}</b></p>
    </div>
  </div>
  <!-- Fin paginador -->

</section>


<!-- Modal Nueva  -->
<div class="modal fade" id="nuevaModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Nuevo Producto</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row">

          <form [formGroup]="nuevoForm" (ngSubmit)="new()">
            <div class="col-12 mt-3" id="crearCliente">

              <div class="form-floating mt-3">
                <input formControlName="nombre" id="floatingNombre" placeholder="Nombre" type="text"
                  class="form-control" />
                <label for="floatingNombre">Nombre <span class="text-danger">*</span></label>
              </div>

              <div class="form-floating mt-3">
                <textarea formControlName="detalle" placeholder="detalle" type="text" class="form-control" rows="8"></textarea>
                <label for="">detalle <span class="text-danger">*</span></label>
              </div>

              <div class="form-floating mt-3">
                <input formControlName="valor" id="floatingNombre" placeholder="valor" type="text"
                  class="form-control" />
                <label for="floatingNombre">Valor <span class="text-danger">*</span></label>
              </div>              


              <app-button [type]="'submit'" [block]="true" [loading]="isAuthLoading">Crear Producto</app-button>

            </div>

          </form>

        </div>

      </div>
    </div>
  </div>
</div>



<!-- Modal Ver  -->
<div class="modal fade" id="ver" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Producto</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body pt-0">
        <div class="row">

          <form [formGroup]="editForm" (ngSubmit)="edit()">
            <div class="row">

              <div class="col-12 mt-3">
                <div class="form-floating mt-3">
                  <input formControlName="nombre" placeholder="Nombre" type="text" class="form-control" />
                  <label for="floatingNombre">Nombre</label>
                </div>

                <div class="form-floating mt-3">
                  <!-- <angular-editor [placeholder]="'Enter text here...'" [(ngModel)]="htmlContent"></angular-editor> -->
                  <angular-editor formControlName="detalle"></angular-editor>

                  <!-- <textarea  formControlName="detalle" class="form-control" placeholder="" id="floatingTextarea2" style="height: 400px"></textarea>
                  <label for="floatingTextarea2">Detalle</label> -->
                </div>

              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-3">
                    <div class="form-floating mt-3">
                      <input formControlName="plan_esencial" placeholder="valor" type="text" class="form-control" />
                      <label for="floatingNombre">Plan Esencial $</label>
                    </div>
                    <div class="form-floating mt-3">
                      <input formControlName="plan_esencial_menor_40_ad" placeholder="valor" type="text" class="form-control" />
                      <label for="floatingNombre">menor a 40 $</label>
                    </div>
                    <div class="form-floating mt-3">
                      <input formControlName="plan_esencial_40_60_ad" placeholder="valor" type="text" class="form-control" />
                      <label for="floatingNombre">40 a 60 $</label>
                    </div>
                    <div class="form-floating mt-3">
                      <input formControlName="plan_esencial_60_70_ad" placeholder="valor" type="text" class="form-control" />
                      <label for="floatingNombre">60 a 70 $</label>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-floating mt-3">
                      <input formControlName="plan_plus" placeholder="valor" type="text" class="form-control" />
                      <label for="floatingNombre">Plan Plus $</label>
                    </div> 
                    <div class="form-floating mt-3">
                      <input formControlName="plan_plus_menor_40_ad" placeholder="valor" type="text" class="form-control" />
                      <label for="floatingNombre">menor a 40 $</label>
                    </div>
                    <div class="form-floating mt-3">
                      <input formControlName="plan_plus_40_60_ad" placeholder="valor" type="text" class="form-control" />
                      <label for="floatingNombre">40 a 60 $</label>
                    </div>
                    <div class="form-floating mt-3">
                      <input formControlName="plan_plus_60_70_ad" placeholder="valor" type="text" class="form-control" />
                      <label for="floatingNombre">60 a 70 $</label>
                    </div>                                       
                  </div>
                  <div class="col-3">
                    <div class="form-floating mt-3">
                      <input formControlName="plan_mas" placeholder="valor" type="text" class="form-control" />
                      <label for="floatingNombre">Plan Mas $</label>
                    </div> 
                    <div class="form-floating mt-3">
                      <input formControlName="plan_mas_menor_40_ad" placeholder="valor" type="text" class="form-control" />
                      <label for="floatingNombre">menor a 40 $</label>
                    </div>
                    <div class="form-floating mt-3">
                      <input formControlName="plan_mas_40_60_ad" placeholder="valor" type="text" class="form-control" />
                      <label for="floatingNombre">40 a 60 $</label>
                    </div>
                    <div class="form-floating mt-3">
                      <input formControlName="plan_mas_60_70_ad" placeholder="valor" type="text" class="form-control" />
                      <label for="floatingNombre">60 a 70 $</label>
                    </div>                                         
                  </div>
                  <div class="col-3">
                    <div class="form-floating mt-3">
                      <input formControlName="plan_total" placeholder="valor" type="text" class="form-control" />
                      <label for="floatingNombre">Plan Total $</label>
                    </div>  
                    <div class="form-floating mt-3">
                      <input formControlName="plan_total_menor_40_ad" placeholder="valor" type="text" class="form-control" />
                      <label for="floatingNombre">menor a 40 $</label>
                    </div>
                    <div class="form-floating mt-3">
                      <input formControlName="plan_total_40_60_ad" placeholder="valor" type="text" class="form-control" />
                      <label for="floatingNombre">40 a 60 $</label>
                    </div>
                    <div class="form-floating mt-3">
                      <input formControlName="plan_total_60_70_ad" placeholder="valor" type="text" class="form-control" />
                      <label for="floatingNombre">60 a 70 $</label>
                    </div>                                         
                  </div>
                </div>

              </div>
                       

                <div class="form-floating mt-3">
                  <input formControlName="mascota_adicional" placeholder="valor" type="text" class="form-control" />
                  <label for="floatingNombre">Mascota Adicional $</label>
                </div>
<!-- 
                <div class="form-floating mt-3">
                  <input formControlName="persona_adicional" placeholder="valor" type="text" class="form-control" />
                  <label for="floatingNombre">Personal Adicional $</label>
                </div> -->


              <app-button [type]="'submit'" [block]="true" [loading]="isAuthLoading" class="mt-4">Actualizar</app-button>
            </div>

          </form>

        </div>

      </div>
    </div>
  </div>
</div>