import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '@services/app.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-seguimiento',
  templateUrl: './seguimiento.component.html',
  styleUrls: ['./seguimiento.component.scss']
})
export class SeguimientoComponent implements OnInit {
  findInput: any;
  obj: any = [];
  objCliente: any = [];
  objCursos: any = [];
  sigla:any;
  msg:any;
  cargando: false;

  constructor(public router: Router,private toastr: ToastrService, private appService: AppService,private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.sigla = this.route.snapshot.paramMap.get('sigla');
    if(this.sigla){
      this.getEmpresa();
    }else{
      console.log('no hay');      
    }    
  }

  findGuia(){
    this.msg = "";
    let params = {
      buscar_tracking: true,
      et: this.obj.token,
      buscador_input: this.findInput
    }
    this.appService.postCertificado(params)
    .subscribe( (data) => {
      if (data["objCliente"] == false){
        this.msg = data['msg'];
        this.toastr.error(data["msg"]);
      }else{
        this.objCliente = data['objCliente'];
        this.objCursos = data['obj'];
      }
      this.cargando = false;      
    });
      
  }

  getEmpresa(){
    let params = {
      get:true,
      id: this.sigla,
    };
    this.appService.postEmpresa(params)
    .subscribe( (data) => {
        if (data["logger"] == true){
            this.obj = data['obj'];            
        }else{
            this.toastr.error(data["msg"]);
        }
    }); 
  }

}
