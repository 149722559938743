<app-dropdown class="user-menu">
    <ng-container dropdown-button>
        <!-- <img
            [src]="user.fotografia || 'assets/img/default-profile.png'"
            class="user-image img-circle elevation-2"
            alt="User Image"
        /> -->
        <div class="user-image img-circle elevation-2 img-fluid"             
            [ngStyle]="{'background-image':' url(' + user.fotografia || 'assets/img/default-profile.png' + ')','height':'40px','width':'40px','background-size':'cover','background-position':'center','cursor':'pointer','margin-left':'5%'}">
        </div>        
    </ng-container>
    <ng-container dropdown-menu>
        <li class="user-header">
            <!-- <img
                [src]="user.fotografia || 'assets/img/default-profile.png'"
                class="img-circle elevation-2"
                alt="User Image"
            /> -->

            <div class="user-image img-circle elevation-2 "             
                [ngStyle]="{'background-image':' url(' + user.fotografia || 'assets/img/default-profile.png' + ')','height':'110px','width':'110px','background-size':'cover','background-position':'center','cursor':'pointer','margin-left':'auto','margin-right':'auto','margin-top':'5%'}">
            </div>  
            <p>
                <small>{{ user.nombre }}</small>
                <span>{{ user.email }}</span>
                <!-- <small>
                    <span>Registrado desde </span>
                    <span>{{ formatDate(user.createdAt) }}</span>
                </small> -->
            </p>
        </li>
        <!-- Menu Body -->
        <!-- <li class="user-body"> -->
            <!-- <div class="row">
                <div class="col-4 text-center">
                    <a [routerLink]="['/']">Followers</a>
                </div>
                <div class="col-4 text-center">
                    <a [routerLink]="['/']">Sales</a>
                </div>
                <div class="col-4 text-center">
                    <a [routerLink]="['/']">Friends</a>
                </div>
            </div> -->
            <!-- /.row -->
        <!-- </li> -->
        <!-- Menu Footer-->
        <li class="user-footer">
            <a [routerLink]="['/profile']" class="btn btn-default btn-flat">
                Perfil
            </a>
            <a (click)="logout()" class="btn btn-default btn-flat float-right"
                >Cerrar Sesión</a
            >
        </li>
    </ng-container>
</app-dropdown>
