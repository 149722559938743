<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
      <div class="row mb-2">
          <div class="col-sm-6">
            <div class="row">
              <div class="col-5">
                <h1>
                  <div style="float: left;">Instructores </div>                                    
                  <div class="input-group mb-3 col-3">
                    <div class="input-group-prepend" id="button-addon3">
                      <button class="btn btn-sm btn-primary" type="button" data-bs-toggle="modal" data-bs-toggle="modal" data-bs-target="#nuevo"><i class="fa fa-plus"></i></button>
                      <button type="button" (click)="getList()" *ngIf="!cargando" class="btn btn-primary"><i class="fa fa-sync"></i> </button>
                      <button type="button" *ngIf="cargando" class="btn btn-primary"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> </button>
                    </div>
                  </div>
                </h1>
              </div>
              <div class="col-7">
                <div class="input-group mb-0">
                  <input type="text" class="form-control" [(ngModel)]="findInput" placeholder="Buscador">
                  <button class="btn btn-secondary" type="button"  id="button-addon2" (click)="findCliente()"><i class="fa fa-search"></i></button>
                </div>                  
              </div>
            </div>

          </div>
          <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">

                  <li class="breadcrumb-item"><a href="#">Inicio</a></li>
                  <li class="breadcrumb-item active">Instructores</li>
              </ol>
          </div>
      </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content m-2">
  <div class="card">
    <div class="card-body table-responsive p-0">
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">Nombre</th>
            <th scope="col">Documento</th>
            <th scope="col">Licencia</th>
            <th scope="col">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of obj.items">
            <td style="cursor: pointer;" (click)="ver(item)">{{item.nombre | titlecase}}</td>
            <td>{{item.tipo_documento | uppercase}} {{item.documento}}</td>
            <td>{{item.licencia}}</td>
            <td>
              
              <div class="dropdown">
                <button class="btn btn-block btn-light dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"></button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li><span class="dropdown-item"  style="cursor: pointer;" (click)="ver(item)">Ver Instructor</span></li>
                  <li *ngIf="user.perfil == '2fk6i2o3143'"><span class="dropdown-item text-danger" style="cursor: pointer;" (click)="eliminarUsuario(item)">Eliminar</span></li>
                </ul>
              </div>           

            </td>
          </tr>
        </tbody>
      </table>      
    </div>
  </div>

  <!-- paginador -->
  <div class="card-footer clearfix">
    <ul class="pagination pagination-sm m-0 float-right">
      <li class="page-item" *ngIf="obj.current > 1">
        <button type="button" class="page-link" (click)="changePage(obj.current - 1)">
          <span>Atras</span>
        </button>
      </li>                  

      <li class="page-item" *ngFor="let page of pagesNumber" [ngClass]="{'active': obj.current == page}">
        <button type="button" class="page-link" (click)="changePage(page)">
          {{ page }}
        </button>
      </li>                  

      <li class="page-item" *ngIf="obj.current < obj.total_page">
        <button type="button" class="page-link" (click)="changePage(obj.next)">
          <span>Siguiente</span>
        </button>
      </li>                  
    </ul>
    <div class="pagination" style="height: auto !important; margin: 10px;margin-top:0px;">
      <p>Total registros encontrados: <b>{{obj.size}}</b></p>
    </div>
  </div>              
  <!-- Fin paginador -->  

</section>




  <!-- Modal Ver  -->
  <div class="modal fade" id="ver" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel"><h3>Instructor</h3> </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body mt-0">
            <form [formGroup]="editForm" (ngSubmit)="edit()">
              <div class="row">
                <div class="col-8 mt-0">
                    <div class="form-floating">
                      <input formControlName="nombre" id="floatingNombre" placeholder="Nombre" type="text" class="form-control"/>
                      <label for="floatingNombre">Nombre</label>
                    </div>
          
                    <div class="form-floating mt-3">
                      <select formControlName="tipo_documento" class="form-select"  placeholder="Tipo Documento">
                        <option value="c.c">C.C</option>
                        <option value="c.e">C.E</option>
                        <option value="n.d">N.D</option>
                        <option value="p.e.p">P.E.P</option>
                        <option value="p.p.t">P.P.T</option>
                        <option value="pas">PAS</option>
                        <option value="t.i">T.I</option>
                      </select>                 
                      <label for="">Tipo Documento</label>
                    </div>
          
                    <div class="form-floating mt-3">
                      <input formControlName="documento" placeholder="Documento" type="text" class="form-control"/>
                      <label for="">Documento</label>
                    </div>              
                        
                                  
                    <div class="form-floating mt-3 mb-3">
                      <textarea class="form-control" formControlName="licencia" placeholder="Licencia" style="height: 200px"></textarea>
                      <label for="">Licencia</label>
                    </div>   

                           
                    <app-button [type]="'submit'" [block]="true" [loading]="isAuthLoading">Actualizar</app-button>                       
                </div> 
                <div class="col-4 mt-0 text-center">
                  <div *ngIf="loadingImg" class="spinner-border text-secondary" style="width: 3rem; height: 3rem;position: absolute;left: 40%;top: 10%;" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  <img *ngIf="!editForm.value.firma_url" src="assets/img/firma-default.png" (click)="fileEditar.click()" class="img-fluid img-thumbnail" style="cursor: pointer;"/>
                  <img *ngIf="editForm.value.firma_url" [src]="editForm.value.firma_url" (click)="fileEditar.click()" class="img-fluid img-thumbnail" style="cursor: pointer;"/>
                  <input #fileEditar type="file" accept='image/*' (change)="uploadImg(fileEditar.files,'editar')" style="display: none"/>                          
                  <small class="text-muted">Cargar firma instructor</small>
                </div>
              </div>

            </form>                            
        </div>
      </div>
    </div>
  </div>



  <!-- Modal Nueva  -->
  <div class="modal fade" id="nuevo" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel"><h3>Nuevo Instructor</h3> </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body mt-0">
            <form class="row" [formGroup]="nuevoForm" (ngSubmit)="new()">
              <div class="row">
                <div class="col-8 mt-0">
                    <div class="form-floating">
                      <input formControlName="nombre" id="floatingNombre" placeholder="Nombre" type="text" class="form-control"/>
                      <label for="floatingNombre">Nombre <span class="text-danger">*</span></label>
                    </div>
          
                    <div class="form-floating mt-3">
                      <select formControlName="tipo_documento" class="form-select"  placeholder="Tipo Documento">
                        <option value="c.c">C.C</option>
                        <option value="c.e">C.E</option>
                        <option value="n.d">N.D</option>
                        <option value="p.e.p">P.E.P</option>
                        <option value="p.p.t">P.P.T</option>
                        <option value="pas">PAS</option>
                        <option value="t.i">T.I</option>
                      </select>                 
                      <label for="">Tipo Documento <span class="text-danger">*</span></label>
                    </div>
          
                    <div class="form-floating mt-3">
                      <input formControlName="documento" placeholder="Documento" type="text" class="form-control"/>
                      <label for="">Documento <span class="text-danger">*</span></label>
                    </div>              
                        
                                  
                    <div class="form-floating mt-3 mb-3">
                      <textarea class="form-control" formControlName="licencia" placeholder="Licencia" style="height: 200px"></textarea>
                      <label for="">Licencia <span class="text-danger">*</span></label>
                    </div>   

                           
                    <app-button [type]="'submit'" [block]="true" [loading]="isAuthLoading">Actualizar</app-button>                       
                </div> 
                <div class="col-4 mt-0 text-center">
                  <div *ngIf="loadingImg" class="spinner-border text-secondary" style="width: 3rem; height: 3rem;position: absolute;left: 40%;top: 10%;" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  <img *ngIf="!nuevoForm.value.firma_url" src="assets/img/firma-default.png" (click)="fileNuevo.click()" class="img-fluid img-thumbnail" style="cursor: pointer;"/>
                  <img *ngIf="nuevoForm.value.firma_url" [src]="nuevoForm.value.firma_url" (click)="fileEditar.click()" class="img-fluid img-thumbnail" style="cursor: pointer;"/>
                  <input #fileNuevo type="file" accept='image/*' (change)="uploadImg(fileNuevo.files,'nuevo')" style="display: none"/>                          
                  <small class="text-muted">Cargar firma instructor</small>
                </div>
              </div>

            </form>                            
        </div>
      </div>
    </div>
  </div>

