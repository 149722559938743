import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import { AppService } from '@services/app.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';

declare var $: any;


@Component({
  selector: 'app-pendientes',
  templateUrl: './pendientes.component.html',
  styleUrls: ['./pendientes.component.scss']
})
export class PendientesComponent implements OnInit {
  public editForm: FormGroup;
  public isAuthLoading = false;

  public user: any = null;
  reporteExport:any;
  obj: any = [];
  itemVer: any = [];
  pagesNumber: any;
  offset= 3;
  page= 1;  
  public cargando = false;


  constructor( public router: Router,private toastr: ToastrService, private appService: AppService, private http: HttpClient) { }

  ngOnInit(): void {
    this.user = this.appService.user;
    // console.log(this.user);
    this.getList();  
    
    
    this.editForm = new FormGroup({
      id: new FormControl(null, Validators.required),
      nombre: new FormControl({value: '', disabled: true}, Validators.nullValidator),
      tipo_documento: new FormControl(null, Validators.nullValidator),
      documento: new FormControl(null, Validators.nullValidator),
      genero: new FormControl(null, Validators.nullValidator),
      pais_nacimiento: new FormControl(null, Validators.nullValidator),
      fecha_nacimiento: new FormControl(null, Validators.nullValidator),
      contacto: new FormControl(null, Validators.nullValidator),
      email: new FormControl(null, Validators.nullValidator),
      nivel_educativo: new FormControl(null, Validators.nullValidator),
      area_trabajo: new FormControl(null, Validators.nullValidator),
      cargo_actual: new FormControl(null, Validators.nullValidator),      
      sector: new FormControl(null, Validators.required),      
    });       

  }

  getList(){   
    this.cargando = true;
    this.appService.getCertificadoPendiente(this.user.et+'_'+this.page).subscribe( (data) => {
    this.obj = data['obj']; 
    // this.ForpagesNumber();
    this.cargando = false;
    });
  }

  changePage(page) {
    this.page = page; //para el filtro
    this.obj.current = page;
    this.getList();
  }
  
  ForpagesNumber() {
    let from = this.obj.current - this.offset;
    if(from < 1){
      from = 1;
    }

    let to = from + (this.offset * 2); 
    if(to >= this.obj.total_page){
      to = this.obj.total_page;
    }

    let pagesArray = [];
    while(from <= to){
      pagesArray.push(from);
      from++;
    }
    this.pagesNumber = pagesArray;
    // return pagesArray;
  }


  ver(item){
    this.itemVer = item;
    $('#ver').modal('show');
  }

  async aprobarCertificado(item){
    Swal.fire({
      title: 'Desea aprobar el certificado?',
      text: `al señor(a) `+item.primer_nombre+' '+item.primer_apellido,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, estoy seguro!',
    }).then((result) => {
      if (result.value) {        
        let params = {
          aprobar:true,
          id: item.id,
          estado: 1,
          et: this.user.et,
        };
        this.appService.postCertificadoPendiente(params)
        .subscribe( (data) => {
            if (data["logger"] == true){
              this.toastr.success(data["msg"]);
              // this.itemVer = null;
              $('#ver').modal('hide');
              this.getList();
            }else{
              this.toastr.error(data["msg"]);
            }
        });         

      }
    });  


  }


  async eliminarCertificadoCliente(item) {
    Swal.fire({
      title: '¿Está seguro?',
      text: 'Desea Eliminar el certificado de ' + item.primer_nombre + ' ' + item.primer_apellido + '?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, estoy seguro!',
    }).then((result) => {
      if (result.value) {
        // console.log('dentro true');

        let params = {
          eliminar: true,
          id: item.id,
          et: this.user.et,
          token: this.user.token,
        }
        this.appService.postCertificado(params)
          .subscribe((data) => {
            if (data["logger"] == true) {
              $('#ver').modal('hide');
              this.getList();
              this.toastr.success(data["msg"]);
            } else {
              this.toastr.error(data["msg"]);
            }
          });

      }
    });

  }




}
