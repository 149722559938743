<section class="content m-2">
  <div class="row">
    <!-- CALENDARIO -->
    <div class="col-md-4 p-4">
        <div class="">

          <div class="row text-center">
            <div class="col-12">
              <h4>{{ viewDate | calendarDate:(view + 'ViewTitle'):locale:weekStartsOn | titlecase }}</h4>
          </div>
            <div class="col-12">
                <div class="btn-group btn-block">
                    <div class="btn btn-sm btn-primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
                        (viewDateChange)="closeOpenMonthViewDay()">
                        Anterior
                    </div>
                    <div class="btn btn-sm btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
                        Actual
                    </div>
                    <div class="btn btn-sm btn-primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
                        (viewDateChange)="closeOpenMonthViewDay()">
                        Siguiente
                    </div>
                </div>
            </div>

        </div>
        <br />

          <div [ngSwitch]="view">
            <mwl-calendar-month-view  *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events"
                [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)"
                [locale]="locale">
            </mwl-calendar-month-view>
        </div>

        </div>
    </div>
    <!-- LISTADO CURSOS -->
    <div class="col-md-8 card">
      <div class="card-body" style="padding-left: 0px;padding-right:0px;">
        <div class="text-center"><h4 *ngIf="dataSelectFormat">{{dataSelectFormat}} <button class="btn btn-sm btn-primary" [routerLink]="['/cursos/nuevo-curso/']+fecha"><i class="fa fa-plus"></i></button></h4></div>
        <div class="list-group">
          <div *ngFor="let item of obj; let i = index" class="list-group-item list-group-item-action" aria-current="true">
            <span style="cursor: pointer;" [routerLink]="['/cursos/ver-curso/']+item.id">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">{{item.tipo_curso_nombre | titlecase}}</h5>
              <small>
                <i class="fa fa-circle" style="font-size: 2.4em;" [ngClass]="{'text-success': item.estado == 1,'text-warning': item.estado == 3}"></i>                
              </small>            
            </div>
            <p class="mb-1"><span class="text-muted">Id curso: </span> {{item.id}}</p>
            <p class="mb-1"><span class="text-muted">Categoria: </span> {{item.tipo_curso_categoria | titlecase}}</p>
            <small><span class="text-muted">Duracion: </span> {{item.tipo_curso_duracion | titlecase}}</small>
            <p><span class="text-muted">Instructor: </span> {{item.instructor_nombre | titlecase}}</p>
          </span>
            <div class="d-flex w-100 justify-content-between">
              <div>
              <button *ngIf="item.sum_usuarios ==0" (click)="eliminarCurso(item.id,i)" class="btn btn-sm btn-danger" type="button"><i class="fa fa-trash"></i> </button>
              </div>
              <p class="mb-1"><span class="text-muted">Usuarios Certificados: </span> <b>{{item.sum_usuarios}}</b></p>
            </div>

          </div>
        </div>
        <div *ngIf="!dataSelectFormat" class="mt-4">
          <div class="text-center text-muted mt-5">
            <div style="margin-top: 25% !important;"><i class="fa fa-calendar-alt" style="font-size: 16em;color: #ddd;"></i></div>
            <small>Seleccione fecha en el calendario.</small>
          </div>
        </div>
        <div *ngIf="obj.length ===0 && dataSelectFormat" class="mt-4">
          <div class="text-center text-muted mt-5">
            <div style="margin-top: 25% !important;"><i class="fa fa-folder-plus" style="font-size: 16em;color: #ddd;"></i></div>
            <small>Crea un nuevo curso con esta fecha.</small>
          </div>
        </div>
      </div>
    </div>
  </div>

</section>


