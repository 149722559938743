import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import { AppService } from '@services/app.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';

declare var $: any;
@Component({
  selector: 'app-productos',
  templateUrl: './productos.component.html',
  styleUrls: ['./productos.component.scss']
})
export class ProductosComponent implements OnInit {
  public nuevoForm: FormGroup;
  public editForm: FormGroup;
  public isAuthLoading = false;


  public user: any = null;
  productos: any = [];
  pagesNumber: any;
  offset= 3;
  page= 1;  
  public cargando = false;
  findInputInstituto: any;
  objInstituto: any = [];


  constructor( public router: Router,private toastr: ToastrService, private appService: AppService, private http: HttpClient) { }

  ngOnInit(): void {
    this.user = this.appService.user;    
    
    this.getList();  
    
    this.nuevoForm = new FormGroup({
      nombre: new FormControl(null, Validators.required),
      detalle: new FormControl(null, Validators.required),
      valor: new FormControl(null, Validators.required),
      plan_esencial: new FormControl(null, Validators.required),
      plan_plus: new FormControl(null, Validators.required),
      plan_mas: new FormControl(null, Validators.required),
      plan_total: new FormControl(null, Validators.required),
      persona_adicional: new FormControl(null, Validators.required),
    });   
    
    this.editForm = new FormGroup({
      id: new FormControl(null, Validators.required),
      fotografia: new FormControl(null, Validators.nullValidator),
      fotografia_url: new FormControl(null, Validators.nullValidator),      
      nombre: new FormControl(null, Validators.required),
      detalle: new FormControl(null, Validators.required),
      valor: new FormControl(null, Validators.nullValidator),
      plan_esencial: new FormControl(null, Validators.nullValidator),
      plan_plus: new FormControl(null, Validators.nullValidator),
      plan_mas: new FormControl(null, Validators.nullValidator),
      plan_total: new FormControl(null, Validators.nullValidator),
      persona_adicional: new FormControl(null, Validators.nullValidator),
      mascota_adicional: new FormControl(null, Validators.nullValidator),
      estado: new FormControl(null, Validators.required),
      
      plan_esencial_menor_40_ad: new FormControl(null, Validators.nullValidator),
      plan_esencial_40_60_ad: new FormControl(null, Validators.nullValidator),
      plan_esencial_60_70_ad: new FormControl(null, Validators.nullValidator),

      plan_plus_menor_40_ad: new FormControl(null, Validators.nullValidator),
      plan_plus_40_60_ad: new FormControl(null, Validators.nullValidator),
      plan_plus_60_70_ad: new FormControl(null, Validators.nullValidator),

      plan_mas_menor_40_ad: new FormControl(null, Validators.nullValidator),
      plan_mas_40_60_ad: new FormControl(null, Validators.nullValidator),
      plan_mas_60_70_ad: new FormControl(null, Validators.nullValidator),

      plan_total_menor_40_ad: new FormControl(null, Validators.nullValidator),
      plan_total_40_60_ad: new FormControl(null, Validators.nullValidator),
      plan_total_60_70_ad: new FormControl(null, Validators.nullValidator),

    });       

  }

  getList(){    
    this.cargando = true;
    this.appService.getProductos(this.user.perfil+'-'+this.user.et+'-'+this.page).subscribe( (data) => {
    this.productos = data['obj']; 
    this.ForpagesNumber();
    this.cargando = false;
    });
  }


 

  changePage(page) {
    this.page = page; //para el filtro
    this.productos.current = page;
    this.getList();
  }
  
  ForpagesNumber() {
    let from = this.productos.current - this.offset;
    if(from < 1){
      from = 1;
    }

    let to = from + (this.offset * 2); 
    if(to >= this.productos.total_page){
      to = this.productos.total_page;
    }

    let pagesArray = [];
    while(from <= to){
      pagesArray.push(from);
      from++;
    }
    this.pagesNumber = pagesArray;
    // return pagesArray;
  }


  async new() {     
    if (this.nuevoForm.valid) {
        this.isAuthLoading = true; 
          let params = {
              nuevo:true,
              campos: this.nuevoForm.value,
              et: this.user.et
            };
            // console.log(params);
            this.appService.postProductos(params)
          .subscribe( (data) => {
              if (data["logger"] == true){
                  this.nuevoForm.reset()
                  $('#nuevaModal').modal('hide');
                  this.getList();
                  this.toastr.success(data["msg"]);
              }else{
                  this.toastr.error(data["msg"]);
              }
          }); 
        this.isAuthLoading = false;
    } else {
        this.toastr.error('El formulario no es válido!');
    }
  }

  ver(item){
    this.editForm.get('id').setValue(item.id);
    this.editForm.get('fotografia').setValue(item.fotografia);
    this.editForm.get('fotografia_url').setValue(item.fotografia_url);
    this.editForm.get('nombre').setValue(item.nombre);
    this.editForm.get('detalle').setValue(item.detalle);
    this.editForm.get('plan_esencial').setValue(item.plan_esencial);
    this.editForm.get('plan_plus').setValue(item.plan_plus);
    this.editForm.get('plan_mas').setValue(item.plan_mas);
    this.editForm.get('plan_total').setValue(item.plan_total);
    this.editForm.get('persona_adicional').setValue(item.persona_adicional);
    this.editForm.get('valor').setValue(item.valor);
    this.editForm.get('estado').setValue(item.estado);

    this.editForm.get('mascota_adicional').setValue(item.mascota_adicional);
    this.editForm.get('plan_esencial_menor_40_ad').setValue(item.plan_esencial_menor_40_ad);
    this.editForm.get('plan_esencial_40_60_ad').setValue(item.plan_esencial_40_60_ad);
    this.editForm.get('plan_esencial_60_70_ad').setValue(item.plan_esencial_60_70_ad);

    this.editForm.get('plan_plus_menor_40_ad').setValue(item.plan_plus_menor_40_ad);
    this.editForm.get('plan_plus_40_60_ad').setValue(item.plan_plus_40_60_ad);
    this.editForm.get('plan_plus_60_70_ad').setValue(item.plan_plus_60_70_ad);

    this.editForm.get('plan_mas_menor_40_ad').setValue(item.plan_mas_menor_40_ad);
    this.editForm.get('plan_mas_40_60_ad').setValue(item.plan_mas_40_60_ad);
    this.editForm.get('plan_mas_60_70_ad').setValue(item.plan_mas_60_70_ad);
    
    this.editForm.get('plan_total_menor_40_ad').setValue(item.plan_total_menor_40_ad);
    this.editForm.get('plan_total_40_60_ad').setValue(item.plan_total_40_60_ad);
    this.editForm.get('plan_total_60_70_ad').setValue(item.plan_total_60_70_ad);

  }

  async edit(){    
    if (this.editForm.valid) {
      this.isAuthLoading = true; 
        let params = {
            editar:true,
            id: this.editForm.value.id,
            campos: this.editForm.value,
            estado: 1,
            et: this.user.et
          };
          // console.log(params);
          this.appService.postProductos(params)
        .subscribe( (data) => {
            if (data["logger"] == true){
                this.editForm.reset()
                $('#ver').modal('hide');
                this.getList();
                this.toastr.success(data["msg"]);
            }else{
                this.toastr.error(data["msg"]);
            }
        }); 
      this.isAuthLoading = false;
  } else {
      this.toastr.error('El formulario no es válido!');
  }
  }

  async eliminar(item){
    Swal.fire({
      title: '¿Está seguro?',
      text: `Desea eliminar el registro?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, estoy seguro!',
    }).then((result) => {
      if (result.value) {        
        let params = {
          eliminar : true,
          id: item.id,
          empresa_id: this.user.eid
        }
        this.appService.postProductos(params)
        .subscribe( (data) => {
            if (data["logger"] == true){
                this.getList();
                this.toastr.success(data["msg"]);
            }else{
                this.toastr.error(data["msg"]);
            }
        });         

      }
    });  

  }

  selectEmpresa(item,tipo){    
    if(tipo =='nuevo'){
      this.nuevoForm.get('instituto_id').setValue(item.id);
      this.nuevoForm.get('instituto_nombre').setValue(item.nombre);
      this.nuevoForm.get('instituto_representante').setValue(item.representante);
      this.nuevoForm.get('instituto_nit').setValue(item.nit);
      $('#nuevaModal').modal('show');
      $('#institutoModal').modal('hide');
    }
    if(tipo =='editar'){
      this.editForm.get('instituto_id').setValue(item.id);
      this.editForm.get('instituto_nombre').setValue(item.nombre);
      this.editForm.get('instituto_representante').setValue(item.representante);
      this.editForm.get('instituto_nit').setValue(item.nit);
      $('#ver').modal('show');
      $('#institutoEditModal').modal('hide');
    }
  }


}
