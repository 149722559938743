import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-listado-informe',
  templateUrl: './listado-informe.component.html',
  styleUrls: ['./listado-informe.component.scss']
})
export class ListadoInformeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
