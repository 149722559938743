import { Component, OnInit } from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '@services/app.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
declare var $: any;

@Component({
  selector: 'app-nuevo-curso',
  templateUrl: './nuevo-curso.component.html',
  styleUrls: ['./nuevo-curso.component.scss']
})
export class NuevoCursoComponent implements OnInit {
  public nuevoForm: FormGroup;
  public user: any = null;
  public isAuthLoading = false;
  fecha: any;
  objTipoCurso: any = [];
  objInstructor: any = [];

  constructor(public router: Router,private toastr: ToastrService, private appService: AppService,private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.user = this.appService.user;
    this.fecha = this.route.snapshot.paramMap.get('fecha');

    this.nuevoForm = new FormGroup({
      instructor_id: new FormControl(null, Validators.required),
      instructor_nombre: new FormControl(null, Validators.nullValidator),
      licencia: new FormControl(null, Validators.nullValidator),

      tipo_curso_id: new FormControl(null, Validators.required),
      tipo_curso_nombre: new FormControl(null, Validators.nullValidator),
      categoria_nombre: new FormControl(null, Validators.nullValidator),
      tiempo: new FormControl(null, Validators.nullValidator),
      duracion: new FormControl(null, Validators.nullValidator),
      vigencia: new FormControl(null, Validators.nullValidator),

      fecha_inicio: new FormControl(null, Validators.required),
      fecha_fin: new FormControl(null, Validators.required),
      fecha_certificacion: new FormControl(null, Validators.required),
      ciudad: new FormControl(null, Validators.required),
    });  
    
    this.nuevoForm.get('fecha_inicio').setValue(this.fecha);
    this.nuevoForm.get('fecha_fin').setValue(this.fecha);
 
    
    this.getListTipoCuros();
    this.getListInstructores();
  }


  async new(){
    if (this.nuevoForm.valid) {
      Swal.fire({
        title: '¿Está seguro?',
        text: `Que desea crear este curso?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Sí, estoy seguro!',
      }).then((result) => {
        if (result.value) {
          this.isAuthLoading = true; 
          let params = {
              nuevo:true,
              et: this.user.et,
              token: this.user.token,
              campos: this.nuevoForm.value,
              estado: 1,
            };
            // console.log(params);
            this.appService.postCurso(params)
          .subscribe( (data) => {
            // console.log(data);
              if (data["logger"] == true){
                this.nuevoForm.reset()              
                let idData = data["data"].id;            
                this.router.navigate(['/cursos/ver-curso/'+idData]);
  
                this.toastr.success(data["msg"]);
              }else{
                  this.toastr.error(data["msg"]);
              }
              this.isAuthLoading = false;
          }); 
  
        }
      })

      this.isAuthLoading = false;
  } else {
      this.toastr.error('El formulario no es válido!');
  }
  }

  getListTipoCuros(){
    let params = {
        get:true,
        et: this.user.et
      };
      // console.log(params);
      this.appService.postTipoCurso(params)
    .subscribe( (data) => {
        if (data["logger"] == true){
          this.objTipoCurso = data['obj'];
          // console.log(this.objTipoCurso);          
        }else{
          this.toastr.error(data["msg"]);
        }
    });    
  }

  getListInstructores(){
    let params = {
        get:true,
        et: this.user.et
      };
      // console.log(params);
      this.appService.postInstructor(params)
    .subscribe( (data) => {
        if (data["logger"] == true){
          this.objInstructor = data['obj'];
          // console.log(this.objInstructor);          
        }else{
          this.toastr.error(data["msg"]);
        }
    });    
  }

  selectTipoCurso(item){
    this.nuevoForm.get('tipo_curso_id').setValue(item.id);
    this.nuevoForm.get('tipo_curso_nombre').setValue(item.nombre);
    this.nuevoForm.get('categoria_nombre').setValue(item.categoria_nombre);
    this.nuevoForm.get('tiempo').setValue(item.tiempo);
    this.nuevoForm.get('duracion').setValue(item.duracion);
    this.nuevoForm.get('vigencia').setValue(item.vigencia);
    $('#tipocursoModal').modal('hide');    
  }

  selectInstructor(item){
    this.nuevoForm.get('instructor_id').setValue(item.id);
    this.nuevoForm.get('instructor_nombre').setValue(item.nombre);
    this.nuevoForm.get('licencia').setValue(item.licencia);
    $('#instructorModal').modal('hide');    
  }


}
