import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { AppService } from '@services/app.service';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CalendarView, DAYS_OF_WEEK } from 'angular-calendar';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cursos',
  templateUrl: './cursos.component.html',
  styleUrls: ['./cursos.component.scss']
})
export class CursosComponent implements OnInit {
  locale: string = 'es-CO';
  weekStartsOn: number = DAYS_OF_WEEK.SUNDAY;
  weekendDays: number[] = [DAYS_OF_WEEK.SATURDAY, DAYS_OF_WEEK.SUNDAY];

  public user: any = null;
  public cargando = false;
  public events: any = [];

  fecha: any;
  dataSelect: any;
  dataSelectFormat: any;
  objCalendar: any = [];
  obj: any = [];


  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();

  activeDayIsOpen: boolean = false;
  refresh: Subject<any> = new Subject();

  constructor(public router: Router, private toastr: ToastrService, private appService: AppService) { }


  ngOnInit() {
    this.user = this.appService.user;
    // console.log(this.user);
    this.cargar();
  }

  dayClicked({ date, events }: { date: Date; events: any[] }): void {
    this.fecha = moment(date).format("YYYY-MM-DD");
    // console.log(fecha);
    this.dataSelectFormat = moment(this.fecha).format("DD/MM/YYYY");

    this.cargando = true;
    // this.appService.getCurso(this.user.et + '_' + this.fecha + '_' + this.user.perfil + '_' + this.user.inst).subscribe((data) => {
    this.appService.getCurso(this.user.et + '_' + this.fecha).subscribe((data) => {
      this.obj = data['obj'];
      // console.log(this.obj);
      this.cargando = false;
    });
  }

  cargar(fecha = null): void {
    if (fecha == null) {
      fecha = moment().format("YYYY-MM");
    } else {
      fecha = moment(fecha).format("YYYY-MM");
    }

    // this.appService.getCurso(this.user.et + '_' + fecha + '_' + this.user.perfil + '_' + this.user.inst).subscribe(data => {
    this.appService.getCurso(this.user.et + '_' + fecha).subscribe(data => {
      let prospectosd = data['obj'];
      // console.log(prospectosd);

      let temparray = [];
      // if(prospectosd == 'null') {
      for (let item of prospectosd) {
        let start = new Date(item['fecha_certificacion'].replace(/-/g, "/"));
        temparray.push({
          start: start,
          end: start,
          usuario_name: 'usuario',
          allDay: true,
        });
      }
      this.events = temparray;
      this.refresh.next();
      // }
    });
  }


  handleEvent(action: string, event: any[]): void {
    // this.modalData = { event, action };
    // this.modal.open(this.modalContent, { size: 'md' });
  }


  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.cargar(this.viewDate);
    this.activeDayIsOpen = false;
  }

  eliminarCurso(id,index) {
    Swal.fire({
      title: '¿Está seguro?',
      text: 'Desea eliminar este curso ?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, estoy seguro!',
    }).then((result) => {
      if (result.value) {
        // console.log('dentro true');
        let params = {
          eliminar: true,
          id: id,
          empresa_id: this.user.eid
        }
        this.appService.postCurso(params)
          .subscribe((data) => {
            if (data["logger"] == true) {
              this.cargar();
              this.obj.splice(index, 1);
              this.toastr.success(data["msg"]);
            } else {
              this.toastr.error(data["msg"]);
            }
          });

      }
    });

  }



}
