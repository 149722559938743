<h2 class=" text-center mb-3 font-weight-light text-white"><b>LOB& SEGUROS</b></h2>

<div class="card">
    <div class="card-body">
        <p class="login-box-msg"></p>

        <form [formGroup]="loginForm" (ngSubmit)="loginByAuth()">
            <div class="input-group mb-3">
                <input
                    formControlName="email"
                    type="email"
                    class="form-control"
                    placeholder="Email"
                />
                <div class="input-group-append">
                    <div class="input-group-text">
                        <span class="fas fa-envelope"></span>
                    </div>
                </div>
            </div>
            <div class="input-group mb-3">
                <input
                    formControlName="password"
                    type="password"
                    class="form-control"
                    placeholder="Contraseña"
                />
                <div class="input-group-append">
                    <div class="input-group-text">
                        <span class="fas fa-lock"></span>
                    </div>
                </div>
            </div>
            <div class="row">
                <!-- <div class="col-7">
                    <div class="icheck-primary">
                        <input type="checkbox" id="remember" />
                        <label for="remember"> Remember Me </label>
                    </div>
                </div> -->
                <!-- /.col -->
                <div class="col-12 text-center">
                    <app-button [type]="'submit'" [block]="true" [loading]="isAuthLoading"> Iniciar sesión </app-button>
                </div>
                <!-- /.col -->
            </div>
        </form>

    </div>
    <!-- /.login-card-body -->
</div>

<h6 class=" text-center mb-3 font-weight-light mt-5" style="color: #ffffff61 !important;"><span>Copyright &copy; 2023</span></h6>

