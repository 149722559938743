import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AppService } from '@services/app.service';
import { differenceInYears, isBefore } from 'date-fns';
declare var $: any;

@Component({
  selector: 'app-cotizador',
  templateUrl: './cotizador.component.html',
  styleUrls: ['./cotizador.component.scss']
})
export class CotizadorComponent implements OnInit {

  // ciudades: string[] = [
  //   // Listado de ciudades
  //   'Medellín', 'Bello', 'Itagüí', 'Envigado', 'Apartadó',
  //   'Barranquilla', 'Soledad', 'Malambo', 'Puerto Colombia',
  //   'Cartagena', 'Magangué', 'Turbaco', 'Santa Rosa del Sur',
  //   'Tunja', 'Duitama', 'Sogamoso', 'Sáchica',
  //   'Manizales', 'Villamaría', 'Chinchiná', 'Neira',
  //   'Florencia', 'San Vicente del Caguán', 'La Montañita', 'El Doncello',
  //   'Yopal', 'Aguazul', 'Villanueva', 'Tauramena',
  //   'Valledupar', 'La Jagua de Ibirico', 'Bosconia', 'El Copey',
  //   'Quibdó', 'Istmina', 'Tadó', 'Condoto',
  //   'Montería', 'Lorica', 'Sahagún', 'Cereté',
  //   'Bogotá D.C.', 'Soacha', 'Chocontá', 'Facatativá',
  //   'Riohacha', 'Maicao', 'Uribia', 'Fonseca',
  //   'Neiva', 'Pitalito', 'La Plata', 'Garzón',
  //   'Santa Marta', 'Ciénaga', 'El Banco', 'Fundación',
  //   'Villavicencio', 'Acacías', 'Granada', 'Cumaral',
  //   'Pasto', 'Tumaco', 'Ipiales', 'Sandoná',
  //   'Cúcuta', 'Pamplona', 'Ocaña', 'Villa del Rosario',
  //   'Armenia', 'Calarcá', 'Montenegro', 'La Tebaida',
  //   'Pereira', 'Dosquebradas', 'Santa Rosa de Cabal', 'La Virginia',
  //   'Bucaramanga', 'Barrancabermeja', 'Floridablanca', 'Girón',
  //   'Sincelejo', 'Corozal', 'Sincé', 'Tolú',
  //   'Ibagué', 'Espinal', 'Melgar', 'Honda',
  //   'Cali', 'Palmira', 'Buenaventura', 'Tuluá',
  //   'Mitú', 'Puerto Carreño'
  // ];

  ciudades: string[] = ['Acacías', 'Aguazul', 'Apartadó', 'Armenia', 'Barrancabermeja', 'Barranquilla', 'Bello', 'Bogotá D.C.', 'Bucaramanga', 'Cali', 'Cáqueza', 'Cartagena', 'Cereté', 'Chinchiná', 'Cúcuta', 'Dosquebradas', 'Duitama', 'Envigado', 'Espinal', 'Facatativá', 'Florencia', 'Floridablanca', 'Fusagasugá', 'Garzón', 'Girón', 'Granada', 'Honda', 'Ibagué', 'Ipiales', 'Itagüí', 'La Jagua de Ibirico', 'La Montañita', 'La Plata', 'La Tebaida', 'Malambo', 'Manizales', 'Medellín', 'Melgar', 'Montería', 'Neiva', 'Nelson Mandela', 'Pasto', 'Pereira', 'Palmira', 'Pamplona', 'Pitalito', 'Popayán', 'Puerto Carreño', 'Puerto Colombia', 'Quibdó', 'Riohacha', 'Rionegro', 'Sahagún', 'Santa Marta', 'Santa Rosa de Cabal', 'Santiago de Cali', 'Sincelejo', 'Soacha', 'Soledad', 'Sogamoso', 'Tuluá', 'Tunja', 'Turismo', 'Valledupar', 'Villavicencio', 'Villanueva', 'Yopal', 'Zipaquirá'];


  loaded = false;
  text = '¡Hola mundo!';
  textToShow = '';
  mensaje = "adfadfadfadsfas  fasdfadsf "
  pasoLocal: any;
  paso: any;
  accion: any;
  nombre: any;
  genero: any;
  fecha_nacimiento: any;
  estado_civil: any;
  documento: any;
  edad: any;
  ciudad: any;
  email: any;
  contacto: any;
  productos: any = [];
  productoSelect: any;
  producto: any;

  plan_producto_id: any;
  plan_precio: any;
  plan_personal_adicional: any;
  plan_mascota_adicional: any;
  plan_nombre: any;
  solicitud: any = [];

  be_nombre: any;
  be_genero: any;
  be_fecha_nacimiento: any;
  be_edad: any;
  be_menor_edad: any;
  be_parentezco: any;
  be_raza: any;
  be_tipo_mascota: any;
  be_documento: any
  be_beneficios_hijos_list: any = [];

  be_beneficios_hermanos_list: any = [];
  be_beneficios_padres_list: any = [];
  be_beneficios_conyugue_list: any = [];
  sumTotal: any = 0;
  be_totalItems: any = 0;

  be_beneficios_adicional_list: any = [];
  be_beneficios_adicional_mascotas_list: any = [];
  parentesco: any;
  valorTotal: any = 0;

  selectPlanItem: any;
  selectProductItem: any = [];
  valorBeneficiarioAdicional: any

  msg: any;
  msgBe: any;
  listaRestrictivaTomador: any;




  constructor(private appService: AppService, private http: HttpClient) {
    // if(this.fecha_nacimiento){
    // 
  }

  ngOnInit(): void {

    // Verifica si el usuario ya ha aceptado la política
    const policyAccepted = sessionStorage.getItem('policyAccepted');
    if (!policyAccepted) {
      // Mostrar el modal si no ha aceptado
      this.showModal();
    }

    this.getListProductos();
    this.paso = sessionStorage.getItem('pasoLocal');
    if (!this.paso) {
      this.paso = 1;
    }
    this.nombre = sessionStorage.getItem('nombre');
    this.genero = sessionStorage.getItem('genero');
    this.documento = sessionStorage.getItem('documento');
    this.fecha_nacimiento = sessionStorage.getItem('fecha_nacimiento');

    this.edad = sessionStorage.getItem('edad');
    this.estado_civil = sessionStorage.getItem('estado_civil');
    if (this.estado_civil == 'soltero') {
      $('#producto1').hide();
      $('#producto2').show();
      console.log('dentro soltero');

    }
    if (this.estado_civil == 'casado') {
      console.log('dentro casado');
      $('#producto1').show();
      $('#producto2').hide();
    }
    this.ciudad = sessionStorage.getItem('ciudad');
    this.productoSelect = sessionStorage.getItem('productoSelect');
    this.producto = JSON.parse(sessionStorage.getItem('producto'));
    this.plan_precio = sessionStorage.getItem('plan_precio');

    // this.typeText();
    // this.mensaje;

    if (this.fecha_nacimiento) {
      this.calcularEdad();
      console.log(this.fecha_nacimiento);
    }

  }



  showModal() {
    // Seleccionar el modal y mostrarlo
    console.log('dentro modal');
    $('#exampleModal').modal('show');
  }

  acceptPolicy() {
    // Guarda el valor en sessionStorage cuando el usuario acepta las políticas
    sessionStorage.setItem('policyAccepted', 'true');
    // Lógica adicional si necesitas cerrar el modal o actualizar el estado
  }


  doSmth(paso) {
    // console.log(paso);

    if (paso == 1) {
      this.accion = 1;
      // console.log(this.accion);
    }
    if (paso == 2) {
      this.accion = 2;
      // console.log(this.accion);
    }
    if (paso == 3) {
      sessionStorage.setItem('nombre', this.nombre);
      sessionStorage.setItem('documento', this.documento);
      this.accion = 3;
      // console.log(this.accion);
    }
    if (paso == 4) {
      sessionStorage.setItem('fecha_nacimiento', this.fecha_nacimiento);
      this.accion = 4;
      // console.log(this.accion);
    }
    if (paso == 5) {
      sessionStorage.setItem('ciudad', this.ciudad);
      this.accion = 5;
      // console.log(this.accion);
    }
    if (paso == 6) {
      console.log(this.estado_civil);
      if (this.estado_civil == 'soltero') {
        $('#producto1').hide();
        $('#producto2').show();
        console.log('dentro soltero');

      }
      if (this.estado_civil == 'casado') {
        console.log('dentro casado');
        $('#producto1').show();
        $('#producto2').hide();
      }

      console.log(this.ciudad);
      sessionStorage.setItem('estado_civil', this.estado_civil);
      this.accion = 6;
      console.log(this.accion);
    }

    sessionStorage.setItem('pasoLocal', this.accion);
  }

  homePaso() {
    // this.paso=1;
    sessionStorage.removeItem('nombre');
    sessionStorage.removeItem('fecha_nacimiento');
    sessionStorage.removeItem('documento');
    sessionStorage.removeItem('plan_precio');
    sessionStorage.removeItem('estado_civil');
    sessionStorage.removeItem('ciudad');
    sessionStorage.removeItem('producto');
  }

  backPaso() {
    this.paso = this.paso - 1;
    this.accion = this.accion - 1;
    console.log(this.accion);
    sessionStorage.setItem('pasoLocal', this.accion);
  }

  setDocumento() {
    this.loaded = true;

    this.appService.postRegistraduria('verificar?nuip=' + this.documento)
      .subscribe({
        next: (data) => {

          if (data["success"] == true) {
            console.log(data['data']);
            let fechaDefuncion = data['data'].fechaDefuncion;

            // this.nombre = "";
            // this.fecha_nacimiento = "";

            if (fechaDefuncion.length == 0) {
              const nombreTomador = data['data'].primerNombre + ' ' + data['data'].segundoNombre + ' ' + data['data'].primerApellido + ' ' + data['data'].segundoApellido;
              let fecha = data['data'].fechaNacimiento.split('/');
              // Crear un nuevo objeto Date con los componentes de la fecha
              const nuevaFecha = new Date(Number(fecha[2]), Number(fecha[1]) - 1, Number(fecha[0]));
              // Obtener la fecha formateada en el formato "YYYY-MM-DD"
              this.fecha_nacimiento = nuevaFecha.toISOString().split('T')[0];
              this.calcularEdad();
              this.msg = '';

              this.appService.postListasRestrictivas('verificar?nombre=' + nombreTomador)
                .subscribe((data) => {
                  console.log("consultando lista");
                  if (data["success"] == true) {
                    const items = data['data'].items; // Extrae el array 'items' desde 'data'

                    if (items && items.length > 0) {
                      const item0 = items[0]; // Accede al primer elemento del array 'items'
                      const numeroTipoLista = item0['Número tipo lista']; // Extrae 'Número tipo lista'

                      // Define los valores restringidos
                      const valoresRestringidos = [49, 4, 8, 158, 159, 160];

                      // Verifica si 'Número tipo lista' está en los valores restringidos
                      if (valoresRestringidos.includes(parseInt(numeroTipoLista, 10))) {
                        console.log('Número tipo lista restringido:', numeroTipoLista);
                        this.listaRestrictivaTomador = true;
                      } else {
                        console.log('Número tipo lista no restringido:', numeroTipoLista);
                        this.nombre = nombreTomador;
                        this.listaRestrictivaTomador = false;
                      }

                      const prioridad = item0['Prioridad'];
                      const nombre = item0['Nombre'];
                      const tipoDocumento = item0['Tipo documento'];
                      const numeroDocumento = item0['Numero documento'];

                      console.log('Prioridad:', prioridad);
                      console.log('Nombre:', nombre);
                      console.log('Tipo documento:', tipoDocumento);
                      console.log('Número documento:', numeroDocumento);
                      this.loaded = false;
                    } else {
                      console.log('No hay elementos en el array items.');
                      this.nombre = nombreTomador;
                      this.listaRestrictivaTomador = false;
                      this.loaded = false;
                    }

                  }
                });
            } else {
              this.nombre = "";
              this.fecha_nacimiento = "";
              this.msg = 'Documento no valido para este producto';
              this.loaded = false;
            }
          }
        },
        error: (error) => {
          // Manejo de errores para la llamada a postRegistraduria
          console.error('Error al verificar en registraduría:', error);
          this.loaded = false;
          this.msg = 'Error al verificar el documento. Por favor, inténtalo de nuevo más tarde.';
        }
      });

  }

  setDocumentoBenficiario(be_documento) {
    this.loaded = true;

    this.appService.postRegistraduria('verificar?nuip=' + be_documento)
      .subscribe({
        next: (data) => {
          if (data["success"] == true) {
            console.log(data['data']);
            let fechaDefuncionBeneficiario = data['data'].fechaDefuncion;

            if (fechaDefuncionBeneficiario.length == 0) {
              const nombreTomador = data['data'].primerNombre + ' ' + data['data'].segundoNombre + ' ' + data['data'].primerApellido + ' ' + data['data'].segundoApellido;

              this.be_genero = data['data'].genero;
              let fecha = data['data'].fechaNacimiento.split('/');
              // Crear un nuevo objeto Date con los componentes de la fecha
              const nuevaFecha = new Date(Number(fecha[2]), Number(fecha[1]) - 1, Number(fecha[0]));
              // Obtener la fecha formateada en el formato "YYYY-MM-DD"
              this.be_fecha_nacimiento = nuevaFecha.toISOString().split('T')[0];
              this.calcularEdadBeneficiarios();
              this.msg = '';

              this.appService.postListasRestrictivas('verificar?nombre=' + nombreTomador)
                .subscribe({
                  next: (data) => {
                    console.log("consultando lista");
                    if (data["success"] == true) {
                      const items = data['data'].items; // Extrae el array 'items' desde 'data'

                      if (items && items.length > 0) {
                        const item0 = items[0]; // Accede al primer elemento del array 'items'
                        const numeroTipoLista = item0['Número tipo lista']; // Extrae 'Número tipo lista'

                        // Define los valores restringidos
                        const valoresRestringidos = [49, 4, 8, 158, 159, 160];

                        // Verifica si 'Número tipo lista' está en los valores restringidos
                        if (valoresRestringidos.includes(parseInt(numeroTipoLista, 10))) {
                          console.log('Número tipo lista restringido:', numeroTipoLista);
                          this.listaRestrictivaTomador = true;
                        } else {
                          console.log('Número tipo lista no restringido:', numeroTipoLista);
                          this.be_nombre = nombreTomador;
                          this.listaRestrictivaTomador = false;
                        }

                        const prioridad = item0['Prioridad'];
                        const nombre = item0['Nombre'];
                        const tipoDocumento = item0['Tipo documento'];
                        const numeroDocumento = item0['Numero documento'];

                        console.log('Prioridad:', prioridad);
                        console.log('Nombre:', nombre);
                        console.log('Tipo documento:', tipoDocumento);
                        console.log('Número documento:', numeroDocumento);
                        this.loaded = false;
                      } else {
                        console.log('No hay elementos en el array items.');
                        this.be_nombre = nombreTomador;
                        this.listaRestrictivaTomador = false;
                        this.loaded = false;
                      }
                    } else {
                      this.loaded = false;
                      this.msg = 'Documento no valido';
                    }
                  },
                  error: (error) => {
                    // Aquí manejas el error de red o HTTP
                    console.error('Error al verificar en listas restrictivas:', error);
                    this.loaded = false;
                    this.msg = 'Error al consultar listas restrictivas. Por favor, inténtalo de nuevo más tarde.';
                  }
                });
            } else {
              this.nombre = "";
              this.fecha_nacimiento = "";
              this.msg = 'Documento no valido para este producto';
              this.loaded = false;
            }
          } else {
            // Manejar el caso cuando la verificación inicial falla
            this.loaded = false;
            this.msg = 'Error en la verificación del documento.';
          }
        },
        error: (error) => {
          // Manejo de errores para la llamada a postRegistraduria
          console.error('Error al verificar en registraduría:', error);
          this.loaded = false;
          this.msg = 'Error al verificar el documento. Por favor, inténtalo de nuevo más tarde.';
        }
      });

  }



  getListProductos() {
    let params = {
      getList: true,
    };
    this.appService.postProductos(params)
      .subscribe((data) => {
        if (data["logger"] == true) {
          console.log(data['obj']);

          this.productos = data['obj'];
        } else { }
      });
  }

  selectProducto(item) {
    this.paso = 7
    this.accion = 7
    console.log(item);
    sessionStorage.setItem('pasoLocal', this.accion);
    sessionStorage.setItem('producto', JSON.stringify(item));
    this.producto = item;
  }

  selectPlan(productoItem, precio, producto_id, nombre, personal_adicional, plan_item) {
    console.log(productoItem);
    this.paso = 8;
    this.accion = 8;
    this.plan_producto_id = producto_id;
    this.plan_precio = precio;
    this.plan_mascota_adicional = productoItem.mascota_adicional;
    this.plan_personal_adicional = personal_adicional;
    sessionStorage.setItem('plan_precio', precio);
    this.plan_nombre = nombre;
    console.log(precio);
    this.valorTotalPlan();
    this.selectPlanItem = plan_item;
    this.selectProductItem = productoItem;
    console.log(this.selectPlanItem);
    this.valorBeneficiarioAdicional = '';
  }


  addBeneficiario(parentesco,) {
    this.parentesco = parentesco;
    this.be_nombre = "";
    this.be_genero = "";
    this.be_documento = "";
    this.be_fecha_nacimiento = "";
    this.be_edad = "";
    this.be_menor_edad = "";
    this.msgBe = "";
    $('#addBeneficiariosPrincipales').modal('show');
  }

  agregarBeneficiarioPrincipal(parentesco) {
    console.log('dentro');
    let params = {
      nombre: this.be_nombre,
      genero: this.be_genero,
      documento: this.be_documento,
      fecha_nacimiento: this.be_fecha_nacimiento,
      edad: this.be_edad,
      tipo: 1,
      parentesco: parentesco
    }

    if (this.be_edad >= 18) {
      this.loaded = true;

      this.appService.postRegistraduria('verificar?nuip=' + this.be_documento)
        .subscribe((data) => {
          if (data["success"] == true) {
            console.log(data['data']);
            let fechaDefuncion = data['data'].fechaDefuncion;

            if (fechaDefuncion.length == 0) {
              this.msgBe = '';

              if (this.parentesco == 'hijos') {
                this.be_beneficios_hijos_list.push(params);
              }
              if (this.parentesco == 'conyugue') {
                this.be_beneficios_conyugue_list.push(params);
              }
              if (this.parentesco == 'hermanos') {
                this.be_beneficios_hermanos_list.push(params);
              }
              if (this.parentesco == 'padres') {
                this.be_beneficios_padres_list.push(params);
              }

              this.sumarBeneficiarios();

              this.be_nombre = "";
              this.be_genero = "";
              this.be_documento = "";
              this.be_fecha_nacimiento = "";
              this.be_edad = "";
              this.be_menor_edad = "";
              this.parentesco = "";

              $('#addBeneficiariosPrincipales').modal('hide');


            } else {
              // this.nombre = "";
              // this.fecha_nacimiento = "";
              this.msgBe = 'Persona no valida para este producto';
            }
          }
        });
      this.loaded = false;

    } else {
      if (this.parentesco == 'hijos') {
        this.be_beneficios_hijos_list.push(params);
      }
      if (this.parentesco == 'conyugue') {
        this.be_beneficios_conyugue_list.push(params);
      }
      if (this.parentesco == 'hermanos') {
        this.be_beneficios_hermanos_list.push(params);
      }
      if (this.parentesco == 'padres') {
        this.be_beneficios_padres_list.push(params);
      }

      this.sumarBeneficiarios();

      this.be_nombre = "";
      this.be_genero = "";
      this.be_documento = "";
      this.be_fecha_nacimiento = "";
      this.be_edad = "";
      this.be_menor_edad = "";
      this.parentesco = "";

      $('#addBeneficiariosPrincipales').modal('hide');
    }


  }

  sumarBeneficiarios() {
    const be_arrays = [this.be_beneficios_hijos_list, this.be_beneficios_conyugue_list, this.be_beneficios_hermanos_list, this.be_beneficios_padres_list];
    // this.sumTotal = arrays.reduce((total, array) => total + array.reduce((acc, val) => acc + val, 0), 0);
    this.be_totalItems = be_arrays.reduce((total, array) => total + array.length, 0);
  }

  addBeneficiarioAdicional() {
    this.be_nombre = "";
    this.be_genero = "";
    this.be_documento = "";
    this.be_fecha_nacimiento = "";
    this.be_edad = "";
    this.be_menor_edad = "";
    this.be_parentezco = "";
    this.parentesco = "";
    $('#addBeneficiariosAdicional').modal('show');
  }

  agregarBeneficiarioAdicional() {
    // console.log(this.valorBeneficiarioAdicional)
    let params = {
      nombre: this.be_nombre,
      genero: this.be_genero,
      documento: this.be_documento,
      fecha_nacimiento: this.be_fecha_nacimiento,
      edad: this.be_edad,
      parentesco: this.be_parentezco,
      mascota_raza: this.be_raza,
      tipo_mascota: this.be_tipo_mascota,
      tipo: 2,
      valor: this.plan_personal_adicional,
      // valor: this.plan_personal_adicional,
    }

    this.be_beneficios_adicional_list.push(params);
    this.valorTotalPlan();

    this.be_nombre = "";
    this.be_genero = "";
    this.be_documento = "";
    this.be_fecha_nacimiento = "";
    this.be_edad = "";
    this.be_menor_edad = "";
    this.parentesco = "";
    this.be_parentezco = "";
    this.be_raza = "";
    this.be_tipo_mascota = "";
    this.plan_mascota_adicional = 0;
    this.valorBeneficiarioAdicional = 0;
    $('#addBeneficiariosAdicional').modal('hide');
  }

  addBeneficiarioAdicionalMascotas() {
    this.be_nombre = "";
    this.be_genero = "";
    this.be_documento = "";
    this.be_fecha_nacimiento = "";
    this.be_edad = "";
    this.be_menor_edad = "";
    this.parentesco = "";
    this.be_parentezco = "mascota";
    this.be_raza = "";
    this.be_tipo_mascota = "";
    this.plan_mascota_adicional = this.plan_mascota_adicional;
    this.valorBeneficiarioAdicional = this.plan_mascota_adicional
    $('#addBeneficiariosAdicionalMascotas').modal('show');
  }

  agregarBeneficiarioAdicionalMascotas() {
    let params = {
      nombre: this.be_nombre,
      genero: this.be_genero,
      documento: this.be_documento,
      fecha_nacimiento: this.be_fecha_nacimiento,
      edad: this.be_edad,
      parentesco: this.be_parentezco,
      mascota_raza: this.be_raza,
      tipo_mascota: this.be_tipo_mascota,
      tipo: 2,
      valor: this.valorBeneficiarioAdicional,
    }

    this.be_beneficios_adicional_mascotas_list.push(params);
    this.valorTotalPlan();

    this.be_nombre = "";
    this.be_genero = "";
    this.be_documento = "";
    this.be_fecha_nacimiento = "";
    this.be_edad = "";
    this.be_menor_edad = "";
    this.parentesco = "";
    this.be_parentezco = "";
    this.be_raza = "";
    this.be_tipo_mascota = "";
    // this.plan_mascota_adicional = 0;
    this.valorBeneficiarioAdicional = 0;
    $('#addBeneficiariosAdicionalMascotas').modal('hide');
  }

  // valorTotalPlan_old() {
  //   // (A * B) + C
  //   let totalItem = this.be_beneficios_adicional_list.length;
  //   console.log(totalItem);
  //   if (totalItem >= 1) {
  //     totalItem = parseInt(this.plan_personal_adicional) * parseInt(totalItem) + parseInt(this.plan_precio);
  //   } else {
  //     totalItem = this.plan_precio;
  //   }
  //   this.valorTotal = totalItem;
  // }

  valorTotalPlanOld() {
    // (B) + C
    let totalItem = this.be_beneficios_adicional_list.length;
    console.log(totalItem);

    if (totalItem >= 1) {
      // Utilizamos reduce para sumar los valores del campo 'valor' de cada elemento
      const sumaValores = this.be_beneficios_adicional_list.reduce((acumulador, elemento) => {
        // Aseguramos que el campo 'valor' sea un número antes de sumarlo
        const valorItem = parseInt(elemento.valor) || 0;
        return acumulador + valorItem;
      }, 0);

      // Calculamos el resultado según la fórmula y sumamos la suma de los valores
      totalItem = sumaValores + parseInt(this.plan_precio);
    } else {
      totalItem = this.plan_precio;
    }

    this.valorTotal = totalItem;
  }

  valorTotalPlan() {
    // Combinamos los dos arrays
    const combinedList = [...this.be_beneficios_adicional_list, ...this.be_beneficios_adicional_mascotas_list];

    // Contamos el total de elementos combinados
    let totalItem = combinedList.length;
    console.log(totalItem);

    if (totalItem >= 1) {
      // Utilizamos reduce para sumar los valores del campo 'valor' de cada elemento
      const sumaValores = combinedList.reduce((acumulador, elemento) => {
        // Aseguramos que el campo 'valor' sea un número antes de sumarlo
        const valorItem = parseInt(elemento.valor) || 0;
        return acumulador + valorItem;
      }, 0);

      // Calculamos el resultado según la fórmula y sumamos la suma de los valores
      totalItem = sumaValores + parseInt(this.plan_precio);
    } else {
      totalItem = this.plan_precio;
    }

    this.valorTotal = totalItem;
  }


  deleteBeneficiarioHijo(index) {
    this.be_beneficios_hijos_list.splice(index, 1);
    this.sumarBeneficiarios();
  }
  deleteBeneficiarioPadre(index) {
    this.be_beneficios_padres_list.splice(index, 1);
    this.sumarBeneficiarios();
  }
  deleteBeneficiarioHermano(index) {
    this.be_beneficios_hermanos_list.splice(index, 1);
    this.sumarBeneficiarios();
  }
  deleteBeneficiarioConyugue(index) {
    this.be_beneficios_conyugue_list.splice(index, 1);
    this.sumarBeneficiarios();
  }
  deleteBeneficiarioAdicional(index) {
    this.be_beneficios_adicional_list.splice(index, 1);
    this.sumarBeneficiarios();
    this.valorTotalPlan();
    this.be_parentezco = '';
    this.be_raza = '';
  }
  deleteBeneficiarioAdicionalMascotas(index) {
    this.be_beneficios_adicional_mascotas_list.splice(index, 1);
    this.sumarBeneficiarios();
    this.valorTotalPlan();
    this.be_parentezco = '';
    this.be_raza = '';
  }



  enviarSolicitud() {
    console.log("solicitud");
    let beneficiarios;
    const arrayUnido = this.be_beneficios_adicional_list.concat(this.be_beneficios_hijos_list, this.be_beneficios_conyugue_list, this.be_beneficios_hermanos_list, this.be_beneficios_padres_list, this.be_beneficios_adicional_mascotas_list);

    let params = {
      nuevo: true,
      nombre: this.nombre,
      genero: this.genero,
      documento: this.documento,
      fecha_nacimiento: this.fecha_nacimiento,
      ciudad: this.ciudad,
      email: this.email,
      contacto: this.contacto,
      producto_id: this.producto.id,
      valor: this.valorTotal,
      be_adicionales: arrayUnido,
    };
    this.appService.postSolicitudes(params)
      .subscribe((data) => {
        if (data["logger"] == true) {
          console.log(data['obj']);
          this.solicitud = data['obj'];
          this.paso = 9;

          sessionStorage.removeItem('nombre');
          sessionStorage.removeItem('fecha_nacimiento');
          sessionStorage.removeItem('documento');
          sessionStorage.removeItem('plan_precio');
          sessionStorage.removeItem('estado_civil');
          sessionStorage.removeItem('ciudad');
          sessionStorage.removeItem('pasoLocal');
          // this.paso = 1;
        } else {
          console.log('error');
        }
      });
  }

  calcularEdad() {
    const fechaActual = new Date(); // Obtiene la fecha actual
    const fechaNacimiento = new Date(this.fecha_nacimiento); // Obtiene la fecha de nacimiento del input

    this.edad = differenceInYears(fechaActual, fechaNacimiento);

    // Ajusta la edad si aún no ha pasado el cumpleaños
    if (!isBefore(fechaNacimiento, new Date(fechaActual.getFullYear(), fechaNacimiento.getMonth(), fechaNacimiento.getDate()))) {
      this.edad--;
    }
  }

  calcularEdadBeneficiarios() {
    const fechaActualBe = new Date(); // Obtiene la fecha actual
    const fechaNacimientoBe = new Date(this.be_fecha_nacimiento); // Obtiene la fecha de nacimiento del input

    this.be_edad = differenceInYears(fechaActualBe, fechaNacimientoBe);

    // Ajusta la edad si aún no ha pasado el cumpleaños
    if (!isBefore(fechaNacimientoBe, new Date(fechaActualBe.getFullYear(), fechaNacimientoBe.getMonth(), fechaNacimientoBe.getDate()))) {
      this.be_edad--;
    }
  }

  calcularEdadBeneficiariosAdicional() {
    this.valorBeneficiarioAdicional = 0;
    const fechaActualBe = new Date(); // Obtiene la fecha actual
    const fechaNacimientoBe = new Date(this.be_fecha_nacimiento); // Obtiene la fecha de nacimiento del input

    this.be_edad = differenceInYears(fechaActualBe, fechaNacimientoBe);

    // Ajusta la edad si aún no ha pasado el cumpleaños
    if (!isBefore(fechaNacimientoBe, new Date(fechaActualBe.getFullYear(), fechaNacimientoBe.getMonth(), fechaNacimientoBe.getDate()))) {
      this.be_edad--;
    }
    // console.log(this.selectProductItem);
    // console.log(this.selectProductItem.plan_esencial_menor_40_ad);
    if (this.selectPlanItem == 1) {
      if (this.be_edad > 1 && this.be_edad <= 40) {
        this.valorBeneficiarioAdicional = this.selectProductItem.plan_esencial_menor_40_ad;
        console.log('menor_40_ad');
      } else if (this.be_edad >= 40 && this.be_edad <= 60) {
        this.valorBeneficiarioAdicional = this.selectProductItem.plan_esencial_40_60_ad;
        console.log('40 60')

      } else if (this.be_edad >= 60 && this.be_edad <= 70) {
        this.valorBeneficiarioAdicional = this.selectProductItem.plan_esencial_60_70_ad;
        console.log('60 70')

      } else {
        this.valorBeneficiarioAdicional = 0;
      }

    }
    if (this.selectPlanItem == 2) {

      if (this.be_edad > 1 && this.be_edad <= 40) {
        this.valorBeneficiarioAdicional = this.selectProductItem.plan_plus_menor_40_ad;
        console.log('menor_40_ad');
      } else if (this.be_edad >= 40 && this.be_edad <= 60) {
        this.valorBeneficiarioAdicional = this.selectProductItem.plan_plus_40_60_ad;
        console.log('40 60')

      } else if (this.be_edad >= 60 && this.be_edad <= 70) {
        this.valorBeneficiarioAdicional = this.selectProductItem.plan_plus_60_70_ad;
        console.log('60 70')

      } else {
        this.valorBeneficiarioAdicional = 0;
      }

    }
    if (this.selectPlanItem == 3) {

      if (this.be_edad > 1 && this.be_edad <= 40) {
        this.valorBeneficiarioAdicional = this.selectProductItem.plan_mas_menor_40_ad;
        console.log('menor_40_ad');
      } else if (this.be_edad >= 40 && this.be_edad <= 60) {
        this.valorBeneficiarioAdicional = this.selectProductItem.plan_mas_40_60_ad;
        console.log('40 60')

      } else if (this.be_edad >= 60 && this.be_edad <= 70) {
        this.valorBeneficiarioAdicional = this.selectProductItem.plan_mas_60_70_ad;
        console.log('60 70')

      } else {
        this.valorBeneficiarioAdicional = 0;
      }

    }
    if (this.selectPlanItem == 4) {

      if (this.be_edad > 1 && this.be_edad <= 40) {
        this.valorBeneficiarioAdicional = this.selectProductItem.plan_total_menor_40_ad;
        console.log('menor_40_ad');
      } else if (this.be_edad >= 40 && this.be_edad <= 60) {
        this.valorBeneficiarioAdicional = this.selectProductItem.plan_total_40_60_ad;
        console.log('40 60')

      } else if (this.be_edad >= 60 && this.be_edad <= 70) {
        this.valorBeneficiarioAdicional = this.selectProductItem.plan_total_60_70_ad;
        console.log('60 70')

      } else {
        this.valorBeneficiarioAdicional = 0;
      }

    }
  }

  clearFields() {
    this.be_menor_edad = '';
    this.be_edad = '';
    this.be_nombre = '';
    this.be_genero = '';
    this.be_documento = '';
    this.be_fecha_nacimiento = '';
  }


}
