<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Perfil</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Inicio</a></li>
                    <li class="breadcrumb-item active">Perfil</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <!-- Profile Image -->
                <div class="card card-primary card-outline">
                    <div class="card-body box-profile">
                        <div class="text-center">
                            <div class="user-image img-circle elevation-2 img-fluid" 
                                (click)="fileEditar.click()"
                                [ngStyle]="{'background-image':' url(' + editForm.value.fotografia_url || 'assets/img/default-profile.png' + ')','height':'250px','width':'250px','background-size':'cover','background-position':'center','cursor':'pointer','margin-left':'auto','margin-right': 'auto'}">
                            </div>
                          
          
                            <div *ngIf="loadingImg" class="spinner-border text-secondary" style="width: 3rem; height: 3rem;position: absolute;left: 48%;top: 35%;" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
          
                            <input #fileEditar type="file" accept='image/*' (change)="preview(fileEditar.files,'editar')" style="display: none"/>                          
          
                        </div>

                        <h3 class="profile-username text-center">
                            {{user.nombre | titlecase}}
                        </h3>
                        <div class="text-center">
                            <p class="text-muted text-center" *ngIf="user.perfil == '15kwqgk3123'" class="text-body mb-0">SuperAdministrador</p>
                            <p class="text-muted text-center" *ngIf="user.perfil == '2fk6i2o3143'" class="text-body mb-0">Administrador</p>
                            <p class="text-muted text-center" *ngIf="user.perfil == '3af3s12f153'" class="text-body mb-0">Asesor</p>                
                        </div>


                        <div class="row col-md-6 m-auto" style="padding-top: 1%;">  
                            <div class="col-sm-12">
                                <div class="text-center"><h3>{{user.email}}</h3></div>
                                <div class="input-group mb-2 mr-sm-2">
                                    <input class="form-control form-control-lg" [(ngModel)]="pword" type="password" placeholder="Contraseña" aria-label="contraseña">
                                </div> 
                            </div> 
                        
                            <div class="col-12 text-center mt-3">
                                <button type="submit" class="btn btn-primary btn-lg" (click)="actualizar()">Actualizar Contraseña</button>
                            </div>                                   
                        </div>                        

                        <!-- <ul class="list-group list-group-unbordered mb-3">
                            <li class="list-group-item">
                                <b>Followers</b>
                                <a class="float-right">1,322</a>
                            </li>
                            <li class="list-group-item">
                                <b>Following</b> <a class="float-right">543</a>
                            </li>
                            <li class="list-group-item">
                                <b>Friends</b> <a class="float-right">13,287</a>
                            </li>
                        </ul> -->

                        <!-- <a href="#" class="btn btn-primary btn-block"><b>Follow</b></a> -->
                    </div>
                    <!-- /.card-body -->
                </div>
                <!-- /.card -->
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</section>
