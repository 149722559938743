import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppService } from '@services/app.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

declare var $: any;
@Component({
  selector: 'app-solicitudes',
  templateUrl: './solicitudes.component.html',
  styleUrls: ['./solicitudes.component.scss']
})
export class SolicitudesComponent implements OnInit {
  public nuevoForm: FormGroup;
  public editForm: FormGroup;
  public isAuthLoading = false;

  public user: any = null;
  reporteExport: any;
  findInput: any;
  obj: any = [];
  offset = 3;
  page = 1;
  pay_status: any;
  referencia_pago: any;
  metodo_pago: any;
  producto_nombre: any;

  public cargando = false;
  public loadingImg = false;
  beneficiariosAdicionales: any = [];
  beneficiariosPrincipales: any = [];
  valorTotal: number = 0;
  fechaSolicitud: any;
  pagesNumber: any;


  constructor(public router: Router, private toastr: ToastrService, private appService: AppService, private http: HttpClient) { }

  ngOnInit(): void {
    this.user = this.appService.user;
    // console.log(this.user);
    this.getList();

    this.nuevoForm = new FormGroup({
      nombre: new FormControl(null, Validators.required),
      fecha_nacimiento: new FormControl(null, Validators.required),
      genero: new FormControl(null, Validators.required),
      ciudad: new FormControl(null, Validators.required),
      contacto: new FormControl(null, Validators.nullValidator),
      email: new FormControl(null, Validators.nullValidator),
      documento: new FormControl(null, Validators.nullValidator),
      producto_id: new FormControl(null, Validators.nullValidator),
      producto_nombre: new FormControl(null, Validators.nullValidator),
      estado: new FormControl(null, Validators.nullValidator),
    });

    this.editForm = new FormGroup({
      id: new FormControl(null, Validators.required),
      nombre: new FormControl(null, Validators.required),
      fecha_nacimiento: new FormControl(null, Validators.required),
      genero: new FormControl(null, Validators.required),
      ciudad: new FormControl(null, Validators.required),
      documento: new FormControl(null, Validators.nullValidator),
      contacto: new FormControl(null, Validators.nullValidator),
      email: new FormControl(null, Validators.nullValidator),
      producto_id: new FormControl(null, Validators.nullValidator),
      producto_nombre: new FormControl(null, Validators.nullValidator),
      estado: new FormControl(null, Validators.nullValidator),
    });

  }

  getList() {
    this.cargando = true;
    this.appService.getSolicitudes(this.user.et + '-' + this.page).subscribe((data) => {
      this.obj = data['obj'];
      console.log(this.obj);

      // this.ForpagesNumber();
      this.cargando = false;
    });
  }

  changePage(page) {
    this.page = page; //para el filtro
    this.obj.current = page;
    this.getList();
  }

  ForpagesNumber() {
    let from = this.obj.current - this.offset;
    if (from < 1) {
      from = 1;
    }

    let to = from + (this.offset * 2);
    if (to >= this.obj.total_page) {
      to = this.obj.total_page;
    }

    let pagesArray = [];
    while (from <= to) {
      pagesArray.push(from);
      from++;
    }
    this.pagesNumber = pagesArray;
    // return pagesArray;
  }

  async new() {
    if (this.nuevoForm.valid) {
      this.isAuthLoading = true;
      let params = {
        nuevo: true,
        et: this.user.et,
        nombre: this.nuevoForm.value.nombre,
        // tipo_documento: this.nuevoForm.value.tipo_documento,
        // documento: this.nuevoForm.value.documento,
        // licencia: this.nuevoForm.value.licencia,
        // firma: this.nuevoForm.value.firma,
        estado: 1,
      };
      // console.log(params);
      this.appService.postInstructor(params)
        .subscribe((data) => {
          // console.log(data);
          if (data["logger"] == true) {
            this.nuevoForm.reset()
            $('#nuevo').modal('hide');
            // $('#offcanvasNuevaUsuario').offcanvas('hide');
            this.toastr.success(data["msg"]);
            this.getList();
          } else {
            this.toastr.error(data["msg"]);
          }
        });
      this.isAuthLoading = false;
    } else {
      this.toastr.error('El formulario no es válido!');
    }
  }

  ver(item) {
    console.log(item);
    this.editForm.get('id').setValue(item.id);
    this.editForm.get('nombre').setValue(item.nombre);
    this.editForm.get('fecha_nacimiento').setValue(item.fecha_nacimiento);
    this.editForm.get('documento').setValue(item.documento);
    this.editForm.get('genero').setValue(item.genero);
    this.editForm.get('ciudad').setValue(item.ciudad);
    this.editForm.get('contacto').setValue(item.contacto);
    this.editForm.get('email').setValue(item.email);
    this.editForm.get('producto_id').setValue(item.producto_id);
    this.editForm.get('producto_nombre').setValue(item.producto_nombre);
    this.editForm.get('estado').setValue(item.estado);
    this.producto_nombre = item.producto_nombre;
    this.beneficiariosPrincipales = item.beneficiarios_principales;
    this.beneficiariosAdicionales = item.beneficiarios_adicionales;
    this.valorTotal = item.valor;
    this.fechaSolicitud = item.solicitud_at;
    this.pay_status = item.pay_status;
    this.metodo_pago = item.metodo_pago;
    this.referencia_pago = item.referencia_pago;
    $('#ver').modal('show');
  }

  async edit() {
    if (this.editForm.valid) {
      this.isAuthLoading = true;
      let params = {
        editar: true,
        id: this.editForm.value.id,
        nombre: this.editForm.value.nombre,
        // tipo_documento: this.editForm.value.tipo_documento,
        // documento: this.editForm.value.documento,
        // licencia: this.editForm.value.licencia,
        // firma: this.editForm.value.firma,
      };
      // console.log(params);
      this.appService.postInstructor(params)
        .subscribe((data) => {
          if (data["logger"] == true) {
            this.toastr.success(data["msg"]);
            this.editForm.reset()
            $('#ver').modal('hide');
            this.getList();
          } else {
            this.toastr.error(data["msg"]);
          }
        });
      this.isAuthLoading = false;
    } else {
      this.toastr.error('El formulario no es válido!');
    }
  }

  async eliminarUsuario(item) {
    Swal.fire({
      title: '¿Está seguro?',
      text: `Desea eliminar al Instructor?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, estoy seguro!',
    }).then((result) => {
      if (result.value) {
        // console.log('dentro true');

        let params = {
          eliminar: true,
          id: item.id,
          empresa_id: this.user.eid
        }
        this.appService.postInstructor(params)
          .subscribe((data) => {
            if (data["logger"] == true) {
              this.getList();
              this.toastr.success(data["msg"]);
            } else {
              this.toastr.error(data["msg"]);
            }
          });

      }
    });

  }

  async findCliente() {
    let params = {
      buscar: true,
      buscar_input: this.findInput,
      page: this.page,
      et: this.user.et,
    };
    this.appService.postInstructor(params)
      .subscribe((data) => {
        if (data["logger"] == true) {
          this.obj = data['obj'];
        } else {
          this.toastr.error(data["msg"]);
        }
      });
  }



  exportCliente() {
    let nombreArchivo = 'solicitud #' + this.editForm.value.id;
    const ws_data = [];

    // Agregar encabezados para el cliente
    const clienteHeaders = ['Nombre Tomador', 'Documento', 'Fecha de Nacimiento', 'Ciudad', 'Contacto', 'Email', 'Producto'];
    ws_data.push(clienteHeaders);

    // Agregar datos del cliente
    const clienteData = [
      this.editForm.value.nombre,
      this.editForm.value.documento,
      this.editForm.value.fecha_nacimiento,
      this.editForm.value.ciudad,
      this.editForm.value.contacto,
      this.editForm.value.email,
      this.editForm.value.producto_nombre,
    ];
    ws_data.push(clienteData);

    // Agregar una fila vacía
    ws_data.push([]);

    // Agregar el encabezado "Beneficiarios Principales" en negrita
    const boldStyle = { font: { bold: true } };
    const beneficiariosHeader = ['Beneficiarios Principales'];
    ws_data.push(beneficiariosHeader);

    // Agregar encabezados para los beneficiarios
    const beneficiariosHeaders = ['Nombre Beneficiario', 'Documento', 'Fecha de nacimiento', 'Edad', 'Genero', 'Parentesco'];
    ws_data.push(beneficiariosHeaders);

    // Agregar datos de los beneficiarios principales
    this.beneficiariosPrincipales.forEach(beneficiario => {
      const beneficiarioData = [
        beneficiario.nombre,
        beneficiario.documento,
        beneficiario.fecha_nacimiento,
        beneficiario.edad,
        beneficiario.genero,
        beneficiario.parentesco
      ];
      ws_data.push(beneficiarioData);
    });

    // Agregar una fila vacía antes de los beneficiarios adicionales
    ws_data.push([]);

    // Agregar el encabezado "Beneficiarios Adicionales" en negrita
    const beneficiariosAdicionalesHeader = ['Beneficiarios Adicionales'];
    ws_data.push(beneficiariosAdicionalesHeader);

    // Agregar encabezados para los beneficiarios adicionales
    const beneficiariosAdicionalesHeaders = ['Nombre Beneficiario', 'Documento', 'Fecha de nacimiento', 'Edad', 'Genero', 'Parentesco'];
    ws_data.push(beneficiariosAdicionalesHeaders);

    // Agregar datos de los beneficiarios adicionales
    this.beneficiariosAdicionales.forEach(beneficiario => {
      const beneficiarioData = [
        beneficiario.nombre,
        beneficiario.documento,
        beneficiario.fecha_nacimiento,
        beneficiario.edad,
        beneficiario.genero,
        beneficiario.parentesco
      ];
      ws_data.push(beneficiarioData);
    });

    // Crear una hoja de trabajo
    const ws = XLSX.utils.aoa_to_sheet(ws_data, { cellStyles: true });

    // Aplicar formato de celda en negrita a los encabezados
    // ws['A' + (clienteHeaders.length + 3)].s = boldStyle;
    // ws['A' + (clienteHeaders.length + beneficiariosHeaders.length + 5)].s = boldStyle;

    // Aplicar bordes a las celdas
    const borderStyle = { border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } } };
    for (let i = 0; i < ws_data.length; i++) {
      for (let j = 0; j < ws_data[i].length; j++) {
        if (ws_data[i][j]) {
          ws[XLSX.utils.encode_cell({ r: i, c: j })].s = borderStyle;
        }
      }
    }

    // Ajustar el ancho de las celdas A y B
    // XLSX.utils.sheet_set_column_width(ws, 'A', 15); // Ancho de la columna A
    // XLSX.utils.sheet_set_column_width(ws, 'B', 15); // Ancho de la columna B
    ws['!cols'] = [{ width: 40 }, { width: 30 }, { width: 35 }, { width: 35 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }];


    // Crear un libro de Excel
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Datos');

    // Guardar el archivo
    XLSX.writeFile(wb, `${nombreArchivo}.xlsx`);

  }


  async exportCliente1() {
    // let params = {
    //     export:true,
    //     et: this.user.et,
    //   };
    //     this.appService.postClientes(params)
    //     .subscribe( (data) => {
    //       // console.log(data['clientes']);          
    //         if (data["logger"] == true){

    console.log(this.editForm.value);
    this.reporteExport = this.editForm.value;

    const fileName = 'solicitud.xlsx';
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.reporteExport);

    // ANCHO COLUMNAS
    ws['!cols'] = [{ width: 2 }, { width: 8 }, { width: 35 }, { width: 35 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }];
    // ELIMINA COLUMNA1
    ws['!cols'][0] = { hidden: true };
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    // CAMBIA CABECERA
    XLSX.utils.sheet_add_aoa(ws, [["log", "ID", "NOMBRE", "EMAIL", "CELULAR", "CIUDAD", "NOMBRE"]]);
    XLSX.utils.sheet_add_json(ws, this.reporteExport, { origin: 'A2', skipHeader: true });

    XLSX.utils.book_append_sheet(wb, ws, 'reporte');
    XLSX.writeFile(wb, fileName);



    //     }else{
    //       this.toastr.error(data["msg"]);
    //     }
    // });
  }

  uploadImg(files, componente) {
    if (files.length === 0)
      return;
    this.loadingImg = true;
    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      const imgURL = reader.result;
      //   console.log("esta es la imagen"+imgURL);

      if (!imgURL) {
        console.log('error');
        return;
      } else {
        let params = {
          folder: 'firmas',
          archivo: imgURL
        };
        // this.cargandoImg = true;
        this.appService.uploadImg(params)
          .subscribe((data) => {
            if (data['logger'] == true) {
              if (componente == 'nuevo') {
                // console.log('nuevo');

                this.nuevoForm.get('firma').setValue(data['filename']);
                this.nuevoForm.get('firma_url').setValue(data['fileUrl']);
              }
              if (componente == 'editar') {
                // console.log('edit');

                this.editForm.get('firma').setValue(data['filename']);
                this.editForm.get('firma_url').setValue(data['fileUrl']);
              }

            }
            this.loadingImg = false;
          });
      }
    }

  }


  async actualizarProceso(id, estado) {
    let params = {
      actualizar: true,
      id: id,
      estado: estado
    };
    this.appService.postSolicitudes(params)
      .subscribe((data) => {
        if (data["logger"] == true) {
          this.getList();
          // this.obj = data['obj']; 
        } else {
          this.toastr.error(data["msg"]);
        }
      });
  }


}
