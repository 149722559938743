import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '@services/app.service';
import Swal from 'sweetalert2';
declare var $: any;

@Component({
  selector: 'app-wompi',
  templateUrl: './wompi.component.html',
  styleUrls: ['./wompi.component.scss']
})
export class WompiComponent implements OnInit {

  cargando = false;
  listProductos: any = [];
  producto:any = [];
  slug:any;
  matriz_id:any;
  id:any;
  productoId:any;
  productoValor:any;

  status:any;
  statusText:any;
  transferenciaId:any;
  referenciaPago:any;
  payment_method_type:any;
  env:any;
  estado:any;

  constructor(private route: ActivatedRoute,public servicios: AppService, public router: Router) { }

  ngOnInit(): void {
  this.transferenciaId = this.route.snapshot.queryParamMap.get('id');
  this.env = this.route.snapshot.queryParamMap.get('env');
  this.referenciaPago = this.route.snapshot.paramMap.get('referencia');

  console.log(this.env);
  console.log(this.transferenciaId);
  console.log(this.referenciaPago);
    
  this.getStatusTransactions();
  }

  getStatusTransactions(){
    this.servicios.getTransactionsSandbox(this.transferenciaId).subscribe( (data) => {
      console.log(data['data']);      
      this.payment_method_type = data['data'].payment_method_type;

      // APPROVED
      // DECLINED
      // PENDING
      this.status = data['data'].status;    
      console.log(this.status);
      if(this.status == 'APPROVED'){ this.estado = 1; this.statusText ="Aprobado";}
      if(this.status == 'DECLINED'){ this.estado = 2; this.statusText ="Declinado";}
      if(this.status == 'PENDING'){ this.estado = -1; this.statusText ="Pendiente";}

      this.setOrden();
    });    
  }

  setOrden(){
    this.cargando = true;
    let params = {
      editarPay: true,
      token: this.referenciaPago,
      metodo_pago: this.payment_method_type,
      referencia_pago: this.transferenciaId,
      pay_status: this.status,
      estado: this.estado,
    }
    // console.log(params);
    this.servicios.postSolicitudes(params)
    .subscribe( (data) => {
      // console.log(data);
      setTimeout(() => {   
      if (data["logger"] == true){   
        sessionStorage.removeItem('pasoLocal');
        // this.router.navigate(['cotizador/']);

        if(this.status =="DECLINED"){
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: "Operación rechazada",
            showConfirmButton: false,
            timer: 1500,
            showClass: {
              popup: 'animated fadeInDown faster'
            },
            hideClass: {
              popup: 'animated fadeOutUp faster'
            }
          });  
        }
      } else {
        Swal.fire({
          position: 'top-end',
          icon: 'warning',
          title: data['msg'],
          showConfirmButton: false,
          timer: 1500,
          showClass: {
            popup: 'animated fadeInDown faster'
          },
          hideClass: {
            popup: 'animated fadeOutUp faster'
          }
        });          
        this.cargando = false;
        // this.router.navigate(['/cotizador']);
      }

    }, 5000); // 5000 milisegundos = 5 segundos

    });    
  }

}
