import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'fechaMoment1'
})
export class FechaMoment1Pipe implements PipeTransform {

  transform(value: string):string {
    return moment(value).format('DD-MM-YYYY');
  }

}
