import { Pipe, PipeTransform } from '@angular/core';
import { Observable, interval } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Pipe({
  name: 'letterByLetter'
})
export class LetterByLetterPipe implements PipeTransform {
  // transform(value: string): string {
  //   let result = '';
  //   for (let i = 0; i < value.length; i++) {
  //     setTimeout(() => {
  //       result += value[i];
  //     }, i * 50);
  //   }
  //   return result;
  // }

  // transform(value: any, ...args: any[]): string {
  //   let num = value; 
  //   console.log(value);
        
  //     for (let i = 0; i < value.length; i++) {
  //       setTimeout(() => {
  //         num += value[i];
  //       }, i * 100);
  //     }
  //     return num;
  // }  

  // transform(value: string): string {
  //   let result = value;
  //   console.log(result);
    
  //   let i = 0;
  //   const interval = setInterval(() => {
  //     result += value.charAt(i);
  //     i++;
  //     if (i === value.length) {
  //       clearInterval(interval);
  //     }
  //     console.log('d');
      
  //   }, 200);
  //   return result;
  // }
  
  
  // transform(value: string): string {
  //   let result = value;
  //   let index = 0;
  //   console.log(result);
    
  //   setInterval(() => {
  //     if (index < value.length) {
  //       result += value.charAt(index);
  //       index++;
  //       console.log('aa');
        
  //     }
  //   }, 100);
  //   return result;
  // } 
  
  // transform(value: string): string {
  //   let result = '';
  //   for (let i = 0; i < value.length; i++) {
  //     setTimeout(() => {
        
  //       result += value.charAt(i);
  //       console.log(result);
  //     }, 100 * i); // Espera 50ms por cada letra
  //   }
  //   return result;
  // }

  transform(value: string, delay: number = 100): Observable<string> {
    let i = 0;
    return interval(delay).pipe(
      take(value.length),
      map(() => {
        const char = value.charAt(i);
        i++;
        return char;
      })
    );
  }
    
}
